import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { BASE_URL, BASE_URL_PREFIX } from '../../utils/config';
import formatResponse from './format-response';

const generalMiddleware = axios.create({
  baseURL: BASE_URL,
});

generalMiddleware.interceptors.request.use(
  (requestConfig: AxiosRequestConfig<AxiosInstance>) => {
    const token = localStorage.getItem('token') || '';
    requestConfig.headers = {
      'Access-Control-Allow-Origin': '*',
      Authorization: `Bearer ${token}`,
    };
    return requestConfig;
  }
);

const axiosInstance = axios.create({
  baseURL: BASE_URL_PREFIX,
});

axiosInstance.interceptors.request.use(
  (requestConfig: AxiosRequestConfig<AxiosInstance>) => {
    const token = localStorage.getItem('token') || '';
    requestConfig.headers = {
      'Access-Control-Allow-Origin': '*',
      Authorization: `Bearer ${token}`,
    };
    return requestConfig;
  }
);

export const axiosInstanceWithBase = formatResponse(axiosInstance);

export default formatResponse(generalMiddleware);
