import { Button, Theme, Typography } from "@mui/material";
import { Box, Container, Stack } from "@mui/system";
import React, { useEffect, useRef } from "react";
import useInterviewContext from "../../../Context/mainContext";
import { ReactComponent as Handshake } from "../../../assets/svg/Handshake.svg";
import { makeStyles } from "@material-ui/core";
import BorderLinearProgressBar from "../../../components/BorderLinearProgressBar";

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
}));
interface InterviewFinishedProps {
  timeState: any;
}
const FinishedInterview = ({ timeState }: InterviewFinishedProps) => {
  const classes = useStyles();
  let interviewState: any = useInterviewContext();

  useEffect(() => {
    if (!!interviewState.stream) {
      interviewState.stream.getTracks().forEach((track: any) => {
        track.stop();
      });
      interviewState.setStream();
    }
  }, []);

  return (
    <>
      <Box
        sx={{
          width: "970px",
          position: "absolute",
          top: "42%",
          left: "50%",
          transform: "translate(-50%,-50%)",
          "@media (max-width: 768px)": {
            width: "98%",
          },
        }}
      >
        <Stack direction="column">
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Box
              sx={{
                // width: displayVideo ? "876px" : "954px",
                // height: displayVideo ? "360px" : "410px",
                width: "954px",
                height: "410px",
                objectFit: "cover",
                borderRadius: "15px",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  height: "100%",
                  position: "relative",
                  background: "#ffffff",
                  borderRadius: "0.7rem",
                  overflow: "hidden",
                  boxSizing: "border-box",
                  boxShadow: "4px 4px 20px rgba(0, 0, 0, 0.05)",
                }}
              >
                <>
                  <Stack>
                    <Stack direction="row" sx={{ position: "relative" }}>
                      <Box sx={{ width: "25%", padding: "22px" }}>
                        <Typography
                          // ref={interviewState.clockRef}
                          sx={{
                            fontSize: {
                              xs: "14px",
                              sm: "14px",
                              md: "16px",
                              lg: "16px",
                            },
                          }}
                        >
                          {timeState}
                        </Typography>

                        <Typography
                          sx={{
                            fontSize: {
                              xs: "14px",
                              sm: "14px",
                              md: "16px",
                              lg: "16px",
                            },
                            fontWeight: "500",
                          }}
                        >
                          {interviewState.interviewData.candidate
                            ? `${interviewState.interviewData.candidate?.CampaignLine_CandidateFirstName} ${interviewState.interviewData.candidate?.CampaignLine_CandidateLastName}`
                            : "Candidate Name"}
                        </Typography>
                        <Typography
                          sx={{ fontSize: "12px", fontWeight: "400" }}
                        >
                          {interviewState.interviewData.job
                            .Campaign_Job_Title ?? "Job Title"}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          width: "50%",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <Box sx={{ marginY: "28px" }}>
                          <Handshake />
                        </Box>
                        <Typography
                          sx={{
                            fontSize: "20px",
                            fontWeight: "600",
                            color: "#4285F4",
                          }}
                        >
                          Congratulations! Your interview is complete.
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          position: "absolute",
                          right: 0,
                          padding: {
                            xs: "18px",
                            sm: "18px",
                            md: "22px",
                            lg: "22px",
                          },
                          textAlign: "end",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: {
                              xs: "14px",
                              sm: "14px",
                              md: "16px",
                              lg: "16px",
                            },
                            fontWeight: "500",
                          }}
                        >
                          {interviewState.recruiterName ?? "Recruiter Name"}
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: {
                              xs: "10px",
                              sm: "10px",
                              md: "12px",
                              lg: "12px",
                            },
                            fontWeight: "400",
                          }}
                        >
                          {interviewState.interviewData.enterprise
                            .Enterprise_Name ?? "Company Name"}
                        </Typography>
                      </Box>
                    </Stack>
                    <Stack
                      sx={{ paddingX: "56px", marginTop: "70px" }}
                      spacing={2}
                    >
                      <Typography sx={{ color: "#8A8A8A", fontSize: "14px" }}>
                        Don't close the browser window until the upload is
                        complete.
                      </Typography>
                      <BorderLinearProgressBar
                        progress={interviewState.uploadProgress}
                        type="determinate"
                      />
                      <Typography
                        align="center"
                        sx={{
                          fontWeight: 500,
                          fontSize: "14px",
                          color: "#CCCCCC",
                        }}
                      >
                        {" "}
                        Uploading interview....
                      </Typography>
                    </Stack>
                  </Stack>
                </>
              </Box>
            </Box>
          </Box>
        </Stack>
      </Box>
    </>
  );
};

export default FinishedInterview;
