import generalMiddleware, {
  axiosInstanceWithBase,
} from './middleware/middleware';
import { Response } from './middleware/format-response';

export const createInterviewVideoBlob = (
  data: any
): Promise<Response<string>> =>
  generalMiddleware.request({
    url: '/InterviewVideo',
    method: 'POST',
    data: data,
  });
export const storeTimeStampApi = (data: any): Promise<Response<string>> =>
  generalMiddleware.request({
    url: '/QuestionResponse/UpdateQuestionTimeStamp',
    method: 'PUT',
    data: data,
  });

export const saveEachQuestionAnswer = (data: any): Promise<Response<string>> =>
  generalMiddleware.request({
    url: '/QuestionResponse/CreateQuestionResponse',
    method: 'POST',
    data: data,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
export const submitInterview = (id: any): Promise<Response<string>> =>
  generalMiddleware.request({
    url: `/QuestionResponse/RateQuestionResponse?fk_CampaignLine_Id=${id}`,
    method: 'POST',
  });
