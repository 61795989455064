import React, { useEffect } from 'react';
import {
  Box,
  Container,
  MobileStepper,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import Button from '@mui/material/Button';
import { useState } from 'react';
import Paper from '@mui/material/Paper';
import { useNavigate } from 'react-router-dom';
import StepperMui from '../../../components/StepperMui';
import CompanyInfo from './CompanyInfo';
import JobDetails from './JobDetails';
import { logout } from '../../../utils/ProtectedRoutes';
import InterviewInstruction from './InterviewInstruction';
import InterviewSetting from './InterviewSetting';
import { ReactComponent as HeaderLogo } from '../../../assets/svg/MainHeaerLogo.svg';
import useInterviewContext from '../../../Context/mainContext';
import Header from '../../../components/Header';
import {
  InterviewInstruciton,
  companyInformation,
  getCandidateApi,
  getCandidateDetails,
  getEmployerDetails,
  getEnterpriseData,
  getJobDetails,
  getSynthesiaVideos,
} from '../../../api/questionAudio';
import useToaster from '../../../utils/Toaster';

import UploadResume from './UploadResume';
import AddUserProfile from './AddUserProfile';
import CommenceScreen from './CommenceScreen';
import { updateCandidateLine } from '../../../api/onboarding';
import axios from 'axios';
import { BASE_URL } from '../../../utils/config';
import LoadingAnimation from '../../../components/LoadingAnimation';
import Tironem_LOGO from '../../../assets/FooterLogo.png';
import EngagementIntroPage from './EngagementIntroPage';
import { Colors } from '../../../theme/colors';

type Props = {};

let stepAdded = false;
const Index = (props: Props) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isLoadingHeader, setIsLoadingHeader] = useState<boolean>(true);
  const [cameraNotFound, setCameraNotFound] = React.useState(false);
  const [companyInfo, setCompanyInfo] = useState('');
  const [interviewInstrucionNote, setInterviewInstructionNote] = useState();
  const [interviewInstrucion, setInterviewInstruction] = useState([]);
  const [resumeFile, setResumeFile] = useState<any>(null);
  const [uploadProgressResume, setUploadProgressResume] = useState<number>(0);
  const [uploadSuccesfull, setUploadSuccesfull] = useState<boolean>(false);
  const [isImageChanged, setIsImageChanged] = useState<boolean>(false);
  const [isUserInitial, setIsUserInitial] = useState<boolean>(false);
  const [imgOBJ, setImgOBJ] = useState<any>(null);
  const [TextColor, setTextColor] = useState<string>('');
  const [Backgroundcolor, setBackgroundcolor] = useState<string>('');
  const [userInitial, setUserInitial] = useState<string>('');
  const [msg, sendNotification] = useToaster();

  const interviewState = useInterviewContext();
  const maxWidth1366 = useMediaQuery('(max-width: 1366px)');
  const maxWidth1536 = useMediaQuery('(max-width: 1536px)');
  const loginExpired = () => {
    let message = 'Session Expired.Please login again.';
    sendNotification({ msg: message, variant: 'success' });
    localStorage.clear();
    navigate('/login');
  };

  useEffect(() => {
    getCandidateDetails().then((responseCandidate: any) => {
      if (responseCandidate.status === 401) {
        loginExpired();
      }
      if (responseCandidate?.status === 200 || responseCandidate?.successful) {
        if (responseCandidate.data === null) {
          loginExpired();
        }

        console.log('responseCandidate.data', responseCandidate.data);

        interviewState.setCandidateData(responseCandidate.data);
        const synthesiadata = {
          campaign_id: responseCandidate.data.fk_Campaign_ID,
          table_name: 'Campaign',
          field_name: 'Interview Introduction Dialog',
          enterprise_id: responseCandidate.data.fk_Enterprise_ID,
        };
        getSynthesiaVideos(synthesiadata).then((videoresponse: any) => {
          videoresponse.data.forEach((video: any) => {
            if (
              video.SynthesiaVideoIntegration_FieldName === 'Engagement Dialog'
            ) {
              interviewState.setSynthesiaVideos((prevState: any) => ({
                ...prevState,
                engageVideo: video,
              }));
            } else if (
              video.SynthesiaVideoIntegration_FieldName ===
              'Interview Introduction Dialog'
            ) {
              interviewState.setSynthesiaVideos((prevState: any) => ({
                ...prevState,
                introductionVideo: video,
              }));
            } else if (
              video.SynthesiaVideoIntegration_FieldName ===
              'Interview Closing Dialog'
            ) {
              interviewState.setSynthesiaVideos((prevState: any) => ({
                ...prevState,
                closingVideo: video,
              }));
            }
          });
        });
        getCandidateApi(responseCandidate?.data.fk_Campaign_ID)
          .then((response: any) => {
            if (response.status === 401) {
              loginExpired();
            }
            if (response.status === 200 || response.successful === true) {
              getEmployerDetails(response?.data?.fk_Enterprise_ID)
                .then((responseEnterprise: any) => {
                  if (
                    responseEnterprise.status === 200 ||
                    responseEnterprise.successful === true
                  ) {
                    if (
                      responseEnterprise?.data?.Enterprise_ImageLink === null ||
                      responseEnterprise?.data?.Enterprise_ImageLink === ''
                    ) {
                    } else {
                      if (
                        !interviewState?.enterpriseData &&
                        interviewState?.enterpriseData?.pk_Enterprise_ID ===
                          undefined
                      ) {
                        if (response?.data?.Campaign_Tolerance !== 0) {
                          const getSpecificCompany =
                            responseEnterprise.data.filter(
                              (ei) =>
                                ei.pk_Employer_ID ===
                                response?.data?.Campaign_Tolerance
                            );

                          const body = {
                            Enterprise_AccessLevel:
                              getSpecificCompany[0]?.Employer_AccessLevel,
                            Enterprise_AlternateNumber:
                              getSpecificCompany[0]?.Employer_AlternateNumber,
                            Enterprise_AlternateNumber_AreaCode:
                              getSpecificCompany[0]
                                ?.Employer_AlternateNumber_AreaCode,
                            Enterprise_AlternateNumber_CountryCode:
                              getSpecificCompany[0]
                                ?.Employer_AlternateNumber_CountryCode,
                            Enterprise_AlternateNumber_Extention:
                              getSpecificCompany[0]
                                ?.Employer_AlternateNumber_Extention,
                            Enterprise_AvatarEnable:
                              getSpecificCompany[0]?.Employer_BusinessNumber,
                            Enterprise_BackgroundColour:
                              getSpecificCompany[0]
                                ?.Employer_BackgroundColour ??
                              Colors.Maximum_blue_purple,
                            Enterprise_BussinessNumber:
                              getSpecificCompany[0]?.Employer_AccessLevel,
                            Enterprise_City:
                              getSpecificCompany[0]?.Employer_City,
                            Enterprise_Colour:
                              getSpecificCompany[0]?.Employer_Colour ?? '#000',
                            Enterprise_CompanyNumber:
                              getSpecificCompany[0]?.Employer_CompanyNumber,
                            Enterprise_Country:
                              getSpecificCompany[0]?.Employer_Country,
                            Enterprise_CountryCoded:
                              getSpecificCompany[0]?.Employer_CountryCoded,
                            Enterprise_DUNs:
                              getSpecificCompany[0]?.Employer_DUNs,
                            Enterprise_DateOfCreation:
                              getSpecificCompany[0]?.Employer_DateOfCreation,
                            Enterprise_DateOfLastUpdate:
                              getSpecificCompany[0]?.Employer_DateOfLastUpdate,
                            Enterprise_Deleted:
                              getSpecificCompany[0]?.Employer_Deleted,
                            Enterprise_Email:
                              getSpecificCompany[0]?.Employer_Email,
                            Enterprise_ImageLink:
                              getSpecificCompany[0]?.Employer_ImageLink,
                            Enterprise_Initials:
                              getSpecificCompany[0]?.Employer_Initials,
                            Enterprise_Name:
                              getSpecificCompany[0]?.Employer_Name,
                            Enterprise_Name2:
                              getSpecificCompany[0]?.Employer_Name2,
                            Enterprise_Notes:
                              getSpecificCompany[0]?.Employer_Notes,
                            Enterprise_PhoneNumber:
                              getSpecificCompany[0]?.Employer_PhoneNumber,
                            Enterprise_PhoneNumber_AreaCode:
                              getSpecificCompany[0]
                                ?.Employer_PhoneNumber_AreaCode,
                            Enterprise_PhoneNumber_CountryCode:
                              getSpecificCompany[0]
                                ?.Employer_PhoneNumber_CountryCode,
                            Enterprise_PhoneNumber_Extention:
                              getSpecificCompany[0]
                                ?.Employer_PhoneNumber_Extention,
                            Enterprise_Postcode:
                              getSpecificCompany[0]?.Employer_Postcode,
                            Enterprise_Record_GUID:
                              getSpecificCompany[0]?.Employer_Record_GUID,
                            Enterprise_RefernceCode:
                              getSpecificCompany[0]?.Employer_RefernceCode,
                            Enterprise_Region:
                              getSpecificCompany[0]?.Employer_Region,
                            Enterprise_Status:
                              getSpecificCompany[0]?.Employer_Status,
                            Enterprise_StreetAddress:
                              getSpecificCompany[0]?.Employer_StreetAddress,
                            Enterprise_StreetAddress2:
                              getSpecificCompany[0]?.Employer_StreetAddress2,
                            Enterprise_StreetAddress3:
                              getSpecificCompany[0]?.Employer_StreetAddress3,
                            Enterprise_TaxRegistrationNumber:
                              getSpecificCompany[0]
                                ?.Employer_TaxRegistrationNumber,
                            fk_Enterprise_ID:
                              getSpecificCompany[0]?.fk_Enterprise_ID,
                            fk_UserID_MainContact:
                              getSpecificCompany[0]?.fk_UserID_MainContact,
                            fk_User_ID: getSpecificCompany[0]?.fk_User_ID,
                            isAgencies: false,
                            pk_Enterprise_ID:
                              getSpecificCompany[0]?.pk_Employer_ID,
                            sSession: getSpecificCompany[0]?.sSession,
                          };

                          interviewState.setEnterpriseData(body);
                        }
                      }
                    }
                    // setIsLoadingHeader(false);
                  } else {
                    // setIsLoadingHeader(false);
                  }
                })
                .catch((error) => {
                  console.log(error);
                  // setIsLoadingHeader(false);

                  // setIsLoading(false);
                });
              interviewState.setInterviewData((prev: any) => ({
                ...prev,
                job: response?.data,
              }));
              interviewState.setJobData((prev: any) => ({
                ...prev,
                jobTitle: response.data?.Campaign_Job_Title,
                jobDescription: response.data?.Campaign_Job_DescriptionHtml,
              }));
            }
          })
          .catch((error) => {
            console.log(error);
            setIsLoading(false);
          });
        getEnterpriseData(responseCandidate?.data.fk_Enterprise_ID)
          .then((responseEnterprise: any) => {
            if (
              responseEnterprise.status === 200 ||
              responseEnterprise.successful === true
            ) {
              if (
                responseEnterprise?.data?.Enterprise_ImageLink === null ||
                responseEnterprise?.data?.Enterprise_ImageLink === ''
              ) {
              } else {
                if (!interviewState.enterpriseData) {
                  interviewState.setEnterpriseData(responseEnterprise.data);
                }
              }
              setTimeout(() => {
                setIsLoadingHeader(false);
              }, 2000);
            } else {
              setIsLoadingHeader(false);
            }
          })
          .catch((error) => {
            console.log(error);
            setIsLoadingHeader(false);

            // setIsLoading(false);
          });
        // getJobDetails(responseCandidate?.data.fk_Job_ID)
        //   .then((response: any) => {
        //     if (response.status === 401) {
        //       loginExpired();
        //     }
        //     if (response.status === 200 || response.successful === true) {
        //       interviewState.setInterviewData((prev: any) => ({
        //         ...prev,
        //         job: response?.data,
        //       }));
        //       interviewState.setJobData((prev: any) => ({
        //         ...prev,
        //         jobTitle: response.data?.Job_Title,
        //         jobDescription: response.data?.Job_Description,
        //       }));
        //       interviewState.setJobData((prev: any) => ({
        //         ...prev,
        //         jobTitle: response.data.Job_Title,
        //         jobDescription: response.data.Job_Description,
        //       }));
        //     }
        //   })
        //   .catch((error) => {
        //     console.log(error);
        //     setIsLoading(false);
        //   });
      }
    });
  }, []);

  const updateUserData = async () => {
    const formDataImage = new FormData();
    formDataImage.append('', imgOBJ);
    const campaigndata = {
      ...interviewState?.candidateData,
      CampaignLine_Initials: userInitial.toUpperCase(),
      CampaignLine_BackgroundColour: Backgroundcolor,
      CampaignLine_Colour: TextColor,
    };

    const updateCampaign = await updateCandidateLine(campaigndata)
      .then((campaignResponse) => {
        getCandidateDetails()
          .then((responseCandidate: any) => {
            interviewState.setCandidateData(responseCandidate.data);
          })
          .catch(() => {});
      })
      .catch((campaignError) => {});
    if (isImageChanged) {
      const accessToken = localStorage.getItem('token') || '';
      const headers = {
        'Access-Control-Allow-Origin': '*',
        Authorization: `Bearer ${accessToken}`,
      };
      axios
        .post(
          `${BASE_URL}/CampaignLine/SaveImage?campaignLine_CandidateLink=${interviewState?.candidateData?.CampaignLine_CandidateLink}`,
          formDataImage,
          {
            headers: headers,
          }
        )
        .then(() => {
          setIsImageChanged(false);
        });
    }
  };
  useEffect(() => {
    let storeActiveStep = localStorage.getItem('step_active');
    if (Number(storeActiveStep) !== null) {
      setActiveStep(Number(storeActiveStep));
    }
  }, []);

  useEffect(() => {
    companyInformation()
      .then((response: any) => {
        if (response.status === 401) {
          loginExpired();
        }
        setCompanyInfo(response?.data?.Snippet_Text);

        setIsLoading(false);
      })
      .catch((e) => {
        console.error('ERROR: ', e);
        setIsLoading(false);
      });

    InterviewInstruciton()
      .then((response: any) => {
        if (response.status === 401) {
          loginExpired();
        }
        const instruction =
          response.data.Snippet_Text.toString().split('\r\n\r\n');
        const instructionNote = instruction[0]
          .toString()
          .split('Wizard Page Number 1 :: ')[1]
          .toString()
          .split(':')[0];

        const points = instruction[1]?.toString().split('\r');

        const Converted = points.map((itm: any, index: number) => {
          return itm.replace(`${index + 1}.`, '').trim();
        });
        setInterviewInstructionNote(instructionNote);
        setInterviewInstruction(Converted);
      })
      .catch((error) => {
        console.log('ERROR', error);
      });
  }, []);

  useEffect(() => {
    if (interviewState.noiseArray.length > 0) {
      interviewState.setNoiseArray([]);
    }
  }, []);

  const handleNext = () => {
    if (interviewState.synthesiaVideos.introductionVideo !== null) {
      if (activeStep === steps.length - 2) {
        if (!cameraNotFound) {
          // navigate('/candidate');
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
          let a: number = activeStep + Number(1);
          localStorage.setItem('step_active', a.toString());
          const audio_video = {
            audio: interviewState.currentAudio,
            video: interviewState.currentVideo,
          };
          localStorage.setItem('Video_Audio', JSON.stringify(audio_video));
        } else {
          alert('Please allow camera and microphone first !!');
        }
      } else if (activeStep === steps.length - 1) {
        if (!cameraNotFound) {
          // navigate('/candidate');
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
          let a: number = activeStep + Number(1);
          localStorage.setItem('step_active', a.toString());
          const audio_video = {
            audio: interviewState.currentAudio,
            video: interviewState.currentVideo,
          };
          localStorage.setItem('Video_Audio', JSON.stringify(audio_video));
          navigate('/candidate');
        } else {
          alert('Please allow camera and microphone first !!');
          return;
        }
      } else {
        // if (activeStep === 4) {
        //   updateUserData();
        // }
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        let a: number = activeStep + Number(1);
        localStorage.setItem('step_active', a.toString());
      }
    } else {
      if (activeStep === steps.length - 1) {
        if (!cameraNotFound) {
          navigate('/candidate');
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
          let a: number = activeStep + Number(1);
          localStorage.setItem('step_active', a.toString());
          const audio_video = {
            audio: interviewState.currentAudio,
            video: interviewState.currentVideo,
          };
          localStorage.setItem('Video_Audio', JSON.stringify(audio_video));
        } else {
          alert('Please allow camera and microphone first !!');
        }
      } else {
        if (activeStep === 4) {
          updateUserData();
        }
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        let a: number = activeStep + Number(1);
        localStorage.setItem('step_active', a.toString());
      }
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    let a: number = activeStep - Number(1);
    localStorage.setItem('step_active', a.toString());
  };

  const handleStepChange = (step: number) => {
    setActiveStep(step);
  };

  const [steps, setSteps] = useState([
    {
      label: 'Company Information Page',
      description: undefined,
    },
    // {
    //   label: "Instruction for Interview",
    //   description: undefined,
    // },
    {
      label: 'Upload resume',
      description: undefined,
    },
    // {
    //   label: "add profile",
    //   description: undefined,
    // },
    // {
    //   label: "commence screen",
    //   description: <CommenceScreen handleNext={handleNext} />,
    // },
    {
      label: 'Interview setting page',
      description: (
        <InterviewSetting
          cameraNotFound={cameraNotFound}
          setCameraNotFound={setCameraNotFound}
        />
      ),
    },
  ]);

  // useEffect(() => {
  //   console.log("steps", steps, interviewState?.synthesiaVideos);
  //   if (
  //     steps.length >= 7 &&
  //     interviewState?.synthesiaVideos?.introductionVideo
  //       ?.SynthesiaVideo_BlobUrl &&
  //     !stepAdded
  //   ) {
  //     let updateSteps = Array.from(steps);

  //     updateSteps.push({
  //       label: "Introduction Engagement Video",
  //       description: <EngagementIntroPage />,
  //     });
  //     setSteps(updateSteps);
  //     setIsLoading(false);
  //     stepAdded = true;
  //   }
  // }, [interviewState?.synthesiaVideos]);

  return (
    <>
      {isLoading ? (
        <>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '100%',
              width: '100%',
              py: '100px',
            }}
          >
            <LoadingAnimation />
          </Box>
        </>
      ) : (
        <>
          <Container
            disableGutters
            maxWidth={false}
            sx={{
              width: '100%',
              height: '100vh',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'space-between',
              background: 'white',
              // background: 'white',
            }}
          >
            <Box
              sx={{
                background: 'rgba(255, 255, 255, 0.3)',
                position: 'relative',
                height: '90%',
                width: '92%',
                borderRadius: '20px',
                border: '3px solid #ffffff',
                backdropFilter: 'blur(40px)',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'start',
                '@media (max-width: 768px)': {
                  height: 'auto',
                },
              }}
            >
              <Header
                image={interviewState?.enterpriseData?.Enterprise_ImageLink}
                isLoadingHeader={isLoadingHeader}
                employer={interviewState?.enterpriseData}
              />
              <Box sx={{ mt: maxWidth1536 ? '1.5vh' : '2.5vh' }}>
                <StepperMui activeStep={activeStep} steps={steps} />
              </Box>
              <Box
                sx={{
                  // background: "rgba(255, 255, 255, 0.3)",
                  // backdropFilter: "blur(40px)",
                  width: '846px',
                  minHeight: maxWidth1536 ? '440px' : '488px',
                  borderRadius: '20px',
                  // px: '20px',
                  // boxShadow: "4px 4px 20px rgba(0, 0, 0, 0.06)",
                  marginTop: maxWidth1536 ? '1vh' : '3vh',
                  '@media (max-width: 768px)': {
                    width: '100%',
                  },
                }}
              >
                {activeStep === 0 ? (
                  <JobDetails isLoading={isLoading} companyInfo={companyInfo} />
                ) : (
                  // <>
                  //   {activeStep === 1 ? (
                  //     <>
                  //       {" "}
                  //       <InterviewInstruction
                  //         isLoading={isLoading}
                  //         interviewInstrucion={interviewInstrucion}
                  //         interviewInstructionNote={interviewInstrucionNote}
                  //       />
                  //     </>
                  //   ) : (
                  <>
                    {activeStep === 1 ? (
                      <>
                        <UploadResume
                          resumeFile={resumeFile}
                          setResumeFile={setResumeFile}
                          uploadProgressResume={uploadProgressResume}
                          setUploadProgressResume={setUploadProgressResume}
                          uploadSuccesfull={uploadSuccesfull}
                          setUploadSuccesfull={setUploadSuccesfull}
                        />
                      </>
                    ) : (
                      <>{steps[activeStep]?.description}</>
                    )}

                    {/* : (
                      <>{steps[activeStep]?.description}</>
                      // <>
                      //   {activeStep === 2 ? (
                      //     <>
                      //       <AddUserProfile
                      //         isImageChanged={isImageChanged}
                      //         setIsImageChanged={setIsImageChanged}
                      //         isUserInitial={isUserInitial}
                      //         setIsUserInitial={setIsUserInitial}
                      //         imgOBJ={imgOBJ}
                      //         setImgOBJ={setImgOBJ}
                      //         Backgroundcolor={Backgroundcolor}
                      //         setBackgroundcolor={setBackgroundcolor}
                      //         TextColor={TextColor}
                      //         setTextColor={setTextColor}
                      //         userInitial={userInitial}
                      //         setUserInitial={setUserInitial}
                      //       />
                      //     </>
                      //   ) : (
                      //     <>{steps[activeStep]?.description}</>
                      //   )}
                      // </>
                    )
                  </> */}
                  </>
                )}
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: '806px',
                  marginTop: maxWidth1536 ? '2vh' : '4vh',
                  '@media (max-width: 768px)': {
                    width: '100%',
                    marginTop: activeStep == 2 ? '0px' : '10px',
                    marginBottom: 5,
                  },
                }}
              >
                {activeStep > 0 && activeStep == 2 && (
                  <Button
                    variant='outlined'
                    sx={{
                      color: '#2B7AFC',
                    }}
                    onClick={() => {
                      logout();
                      navigate('/login');
                    }}
                  >
                    Come back later
                  </Button>
                )}
                {activeStep > 0 && activeStep !== 2 && (
                  <Button
                    color='inherit'
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    sx={{
                      mr: 1,
                      backgroundColor: '#D7E5FC',
                      width: '140px',
                      color: '#2B7AFC ',
                      '&:hover': { backgroundColor: '#D7E5FC' },
                      border: '1px solid #FFFFFF',
                      // backdropFilter: 'blur(40px)',
                    }}
                  >
                    Previous
                  </Button>
                )}
                <Box
                  sx={{
                    flex: '1 1 auto',
                    display: 'flex',
                    justifyContent: 'flex-end',
                    paddingRight: '20px',
                  }}
                >
                  {!resumeFile && activeStep === 1 && (
                    <Button
                      sx={{
                        '&:hover': { backgroundColor: 'transparent' },
                      }}
                      onClick={handleNext}
                    >
                      Skip
                    </Button>
                  )}
                </Box>
                {activeStep !== 3 && (
                  <Button
                    onClick={handleNext}
                    variant='contained'
                    sx={{
                      backgroundColor:
                        activeStep === 1 && !resumeFile
                          ? ' #0000001f'
                          : '#2B7AFC',
                      width: '140px',
                      color: '#FFFFFF',
                      // mt: activeStep == 2 ? 1 : 0,
                      '&:hover': {
                        backgroundColor:
                          activeStep === 1 && !resumeFile
                            ? ' #0000001f'
                            : '#2B7AFC',
                      },
                    }}
                    disabled={activeStep === 1 && !resumeFile}
                  >
                    {/* {console.log(
                      "activeStep === 1 && !resumeFile",
                      activeStep === 1 && !resumeFile
                    )} */}
                    {activeStep === steps.length - 1 ? "Let's Start" : 'Next'}
                  </Button>
                )}
              </Box>
            </Box>
            <Box
              sx={{
                fontSize: '14px',
                fontWeight: 400,
                width: '100%',
                textAlign: 'center',
                paddingY: '10px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '15px',
              }}
            >
              <Typography sx={{ paddingTop: '0px !important' }}>
                © Copyright Tironem 2023 | Powered by{' '}
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <img src={Tironem_LOGO} style={{ width: '' }} />
              </Box>
            </Box>
          </Container>
        </>
      )}
    </>
  );
};

export default Index;
