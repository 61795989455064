import {
  Alert,
  AlertTitle,
  Button,
  FormControl,
  Grid,
  MenuItem,
  Modal,
  Select,
  Stack,
  TextField,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Box } from "@mui/system";
import { makeStyles } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import MicNoneOutlinedIcon from "@mui/icons-material/MicNoneOutlined";
import { SelectChangeEvent } from "@mui/material/Select";
import { ReactMic } from "react-mic";
import StopCircleOutlinedIcon from "@mui/icons-material/StopCircleOutlined";
import useInterviewContext from "../../../Context/mainContext";
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  border: `3px solid #3F7DD8`,
  boxShadow: 24,
  borderRadius: "20px",
  p: 8,
};

const useStyles: any = makeStyles((theme: Theme) => ({
  root: {},

  videoContainer: {
    width: "550px",
    height: "321px",
    position: "relative",
    background: "#ffffff",
    border: `3px solid #3F7DD8`,
    borderRadius: "0.7rem",
    overflow: "hidden",
    boxSizing: "border-box",
    "&:-webkit-transform": "translateZ(0)",
    "&:-webkit-mask-image":
      "-webkit-radial-gradient(circle, white 100%, black 100%)",
  },

  video: {
    width: "380px",
    height: "230px",
    objectFit: "cover",
    borderRadius: "14px",
    "@media (max-width: 768px)": {
      width: "100%",
    },
  },
  warningText: {
    width: "380px",
    height: "250px",
    objectFit: "cover",
    borderRadius: "14px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "20px",
    padding: "0 30px",
    border: "2px solid #3F7DD8",
  },
  recruiter: {
    height: "100%",
    width: "100%",
  },

  image: {
    height: "100%",
    width: "100%",
  },

  text: {
    color: "black",
  },

  volumeIcon: {
    position: "absolute",
    bottom: 0,
    right: 0,
  },
  settingIcon: {
    position: "absolute",
    bottom: 0,
    left: 0,
  },
  Testmic: {
    width: "100%",
  },
}));

interface InterviewSettingProps {
  cameraNotFound: boolean;
  setCameraNotFound: any;
}

const InterviewSetting = ({
  cameraNotFound,
  setCameraNotFound,
}: InterviewSettingProps) => {
  const interviewState = useInterviewContext();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const maxWidth1536 = useMediaQuery("(max-width: 1536px)");

  // const [stream, setStream] = useState<any | null>();
  const [testAudio, setTestAudio] = useState<any>(false);
  // const myVideo = interviewState.myVideo;
  const classes = useStyles();

  //  check camera and display dialog to enable mic and camera
  useEffect(() => {
    if (
      interviewState.videoDevices.length === 0 &&
      interviewState.audioDevices.length === 0
    ) {
      navigator.mediaDevices
        .getUserMedia({ audio: true, video: true })
        .then((stream) => {
          if (
            stream?.getVideoTracks()?.length > 0 &&
            stream?.getAudioTracks()?.length > 0
          ) {
            // setCameraNotFound(false)
            interviewState.setStream(stream);
            interviewState.myVideo.current.srcObject = stream;
          } else {
            alert(" Please give permission to camera and mic");
          }
          return navigator.mediaDevices.enumerateDevices();
        })
        .then((devices) => {
          devices.forEach((device) => {
            if (device.kind == "audioinput" && device.label)
              interviewState.setAudioDevices((prev: any) => {
                return [...prev, device];
              });

            if (device.kind == "videoinput" && device.label)
              interviewState.setVideoDevices((prev: any) => {
                return [...prev, device];
              });
          });
        })
        .catch((err: any) => {
          setOpen(true);
          setCameraNotFound(true);
          console.error("!", err.message);
        });

      return () => {
        navigator.mediaDevices
          .getUserMedia({ audio: true, video: true })
          .then((stream) =>
            stream.getTracks().forEach((track: any) => {
              track.stop();
            })
          );
      };
    } else {
      interviewState.onCameraAndAudioSettingSave("assigned");
    }
  }, []);

  let newVideoObject: any = null;
  let newAudioObject: any = null;

  const handleChangeVideo = (event: SelectChangeEvent) => {
    const id = event.target.value;
    const obj = interviewState.videoDevices.find(
      (vd: any) => vd.deviceId === id
    );
    console.log("obj", obj);
    newVideoObject = obj;

    interviewState.setCurrentVideo(obj);
    interviewState.onCameraAndAudioSettingSave(obj);
  };

  const handleChangeAudio = (event: SelectChangeEvent) => {
    if (testAudio) {
      setTestAudio(false);
    }
    const id = event.target.value;
    const obj = interviewState.audioDevices.find(
      (vd: any) => vd.deviceId === id
    );

    newAudioObject = obj;

    interviewState.setCurrentAudio(obj);
    interviewState.onCameraAndAudioSettingSave(obj);
  };
  useEffect(() => {
    if (
      interviewState.videoDevices.length !== 0 ||
      interviewState.audioDevices.length !== 0
    ) {
      if (!interviewState.currentVideo) {
        interviewState.setCurrentVideo(interviewState.videoDevices[0]);
      }
      if (!interviewState.currentAudio) {
        interviewState.setCurrentAudio(interviewState.audioDevices[0]);
      }
    }
  }, [interviewState.videoDevices.length, interviewState.audioDevices.length]);

  useEffect(() => {
    setTimeout(() => {
      //     console.log(interviewState.videoDevices);
      if (!interviewState.currentAudio && !interviewState.currentVideo) {
        if (interviewState?.videoDevices.length > 0) {
          interviewState.setTempCurrentVideo(interviewState.videoDevices[0]);
          interviewState.setCurrentVideo(interviewState.videoDevices[0]);
        }

        if (interviewState?.audioDevices.length > 0) {
          interviewState.setTempCurrentAudio(interviewState.audioDevices[0]);
          interviewState.setCurrentAudio(interviewState.audioDevices[0]);
        }
      }
    }, 1000);
  }, []);

  const startRecordingTest = () => {
    setTestAudio(true);
  };

  const stopRecordingTest = () => {
    setTestAudio(false);
  };

  return (
    <Stack
      direction="column"
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        px: "0px",
        mx: "5px",
      }}
    >
      <Typography
        sx={{
          fontSize: "22px",
          fontWeight: "500",
          mt: maxWidth1536 ? "0px" : "10px",
          alignSelf: "flex-start",
        }}
      >
        Your interview is about to commence.
      </Typography>

      <Box style={{ width: "100%", marginTop: "5px" }}>
        <Alert icon={false} severity="info">
          <AlertTitle
            sx={{
              fontSize: "16px",
            }}
          >
            Get ready for using the webcam!
          </AlertTitle>
          <Typography
            sx={{
              fontSize: "14px",
            }}
          >
            Enable your webcam for face-to-face experience. Click 'Allow'
            <br /> when prompted and make sure your camera is not blocked
          </Typography>
        </Alert>
      </Box>

      <Grid
        container
        spacing={1}
        sx={{
          mt: "10px",
        }}
      >
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Stack direction={"column"} spacing={2}>
            <Box
              sx={{
                position: "relative",
                width: "380px",
                height: "200px",
                boxSizing: "border-box",
              }}
            >
              {cameraNotFound && !interviewState.stream && (
                <>
                  <Typography className={classes.warningText}>
                    Please add camera and Microphone to start a interview.
                  </Typography>
                </>
              )}
              {!!interviewState.stream && (
                <>
                  <video
                    playsInline
                    muted
                    ref={interviewState.myVideo}
                    autoPlay
                    className={classes.video}
                  />
                </>
              )}
              {!!interviewState.stream && (
                <Box
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: "120px",
                    height: "165px",
                    borderRadius: "100%",
                    backgroundColor: "#ffffff0",
                    border: "5.09px solid #22be31",
                    // outline: '500px solid #4a4a4a77'
                  }}
                />
              )}
            </Box>
            <Stack>
              {/* <Typography color={'primary'}>Welcome to lorem ipsum</Typography> */}
              {/* <Typography>
                Align your face to the camera and click the blue start button to
                begin the interview
              </Typography> */}
              <Typography
                sx={{ fontSize: "16px", fontWeight: 400, mt: "30px" }}
              >
                Once you start the interview you can't sit it again.
              </Typography>
              <Typography
                sx={{ fontSize: "16px", fontWeight: 400, mt: "10px" }}
              >
                Do you wish to start your interview?
              </Typography>
            </Stack>
          </Stack>
        </Grid>
        <Grid item sm={8} xs={10} md={6}>
          <Stack
            spacing={"10px"}
            sx={{
              width: "100%",
              mt: "12px",
            }}
          >
            <Stack spacing={1}>
              <Typography
                color={"primary"}
                sx={{ fontWeight: "400", fontSize: "15px" }}
              >
                Camera
              </Typography>
              {interviewState.videoDevices.length > 0 ? (
                <FormControl fullWidth>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    displayEmpty
                    size="small"
                    value={
                      interviewState.currentVideo?.deviceId ??
                      interviewState.videoDevices[0]?.deviceId
                    }
                    onChange={handleChangeVideo}
                  >
                    {interviewState.videoDevices.map(
                      (videoDevice: any, index: any) => {
                        return (
                          <MenuItem value={videoDevice?.deviceId} key={index}>
                            {videoDevice.label}
                          </MenuItem>
                        );
                      }
                    )}
                  </Select>
                </FormControl>
              ) : (
                <TextField
                  id="demo-simple-select"
                  // displayEmpty
                  size="small"
                  value="No Camera Found"
                  disabled
                  inputProps={{
                    style: {
                      padding: 0,
                    },
                  }}
                  // onChange={handleChangeVideo}
                ></TextField>
              )}
            </Stack>
            <Stack spacing={1}>
              <Typography
                color={"primary"}
                sx={{ fontWeight: "400", fontSize: "15px" }}
              >
                Microphone
              </Typography>
              {interviewState.audioDevices.length > 0 ? (
                <FormControl fullWidth>
                  <Select
                    labelId="demo-simple-select-label2"
                    id="demo-simple-select2"
                    displayEmpty
                    size="small"
                    value={
                      interviewState.currentAudio?.deviceId ??
                      interviewState.audioDevices[0]?.deviceId
                    }
                    onChange={handleChangeAudio}
                  >
                    {interviewState.audioDevices.map(
                      (audioDevice: any, index: any) => {
                        return (
                          <MenuItem
                            value={audioDevice?.deviceId}
                            key={index}
                            sx={{
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              maxWidth: "375px",
                            }}
                          >
                            {audioDevice.label}
                          </MenuItem>
                        );
                      }
                    )}
                  </Select>
                </FormControl>
              ) : (
                <>
                  <TextField
                    id="demo-simple-select"
                    // displayEmpty
                    size="small"
                    value="No Microphone Found"
                    disabled
                    inputProps={{
                      style: {
                        padding: 0,
                      },
                    }}
                    // onChange={handleChangeVideo}
                  ></TextField>
                </>
              )}
            </Stack>

            <Stack spacing={2} direction="column">
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Button
                  sx={{
                    fontSize: "12px !important",
                    fontWeight: "400",
                    padding: "4px 12px !important",
                  }}
                  variant="outlined"
                  size="small"
                  disabled={
                    testAudio || interviewState.audioDevices.length <= 0
                  }
                  startIcon={<MicNoneOutlinedIcon />}
                  onClick={() => {
                    startRecordingTest();
                  }}
                >
                  Test your mic
                </Button>
                {testAudio ? (
                  <Button
                    variant="outlined"
                    startIcon={<StopCircleOutlinedIcon />}
                    onClick={stopRecordingTest}
                    size="small"
                  >
                    Stop Test
                  </Button>
                ) : (
                  <div />
                )}
              </Box>
              <Box
                sx={{
                  opacity: testAudio ? 1 : 0,
                  mt: { xs: "6px", sm: "8px", md: "20px" },
                }}
              >
                <ReactMic
                  strokeColor={"#1976d2"}
                  record={testAudio}
                  // onData={(e) => onData(e)}
                  onStop={(recordedBlob: any) => {
                    setTestAudio(false);
                  }}
                  echoCancellation={true} // defaults -> false
                  // autoGainControl={true} // defaults -> false
                  noiseSuppression={true} // defaults -> false
                  className={classes.Testmic}
                  channelCount={2}
                />
              </Box>

              {/* {!!trialBlob && <ReactPlayer url={trialBlob} playing />} */}
            </Stack>
          </Stack>
        </Grid>
      </Grid>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-description" sx={{ fontSize: "22px" }}>
            Device not Found! Please add camera and Microphone to start a
            interview.
          </Typography>
        </Box>
      </Modal>
    </Stack>
  );
};

export default InterviewSetting;
