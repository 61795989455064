import React, { useEffect, useState } from 'react';
import { IconButton } from '@mui/material';
import { useSnackbar } from 'notistack';
import CloseIcon from '@mui/icons-material/Close';

// ? how to use toaster
// * const [msg, sendNotification] = useToaster();

const useToaster = () => {
  const [conf, setConf] = useState<any>({});
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const action = (key: any) => (
    <>
      <IconButton
        onClick={() => {
          closeSnackbar(key);
        }}
      >
        <CloseIcon
          sx={{
            color: '#ffffff',
          }}
        />
      </IconButton>
    </>
  );
  useEffect(() => {
    if (conf?.msg) {
      let variant: any = 'default';
      if (conf.variant) {
        variant = conf.variant;
      }
      enqueueSnackbar(conf.msg, {
        variant: variant,
        autoHideDuration: 5000,
        action,
      });
    }
  }, [conf]);
  return [conf, setConf];
};

export default useToaster;
