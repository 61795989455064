import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Colors } from "../theme/colors";
import { ReactComponent as RightImg } from "../assets/svg/right.svg";

interface Props {
  open: boolean;
  title?: any;
  content?: any;
  action?: any;
}

const PopUpDialog = ({ open, title, content, action }: Props) => {
  //   const handleClickOpen = () => {
  //     setOpen(true);
  //   };

  // const handleClose = () => {
  //   setOpen(false);
  // };

  return (
    <div>
      <Dialog
        open={open}
        // onClose={handleClose}
        maxWidth="sm"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          border: "2px solid transparent",
          // background:
          //   "linear-gradient(#ffffff0, #ffffff0) padding-box, linear-gradient(#2e88dd, #AD19D5) border-box",
          borderRadius: "4px",
          background: "rgba(0, 0, 0, 0.5)",
          backdropFilter: "blur(10px)",
          boxShadow: "5px 5px 30px rgba(255, 255, 255, 0.5) !important",
        }}
        PaperProps={{ sx: { borderRadius: "12px" } }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            paddingBottom: "10px",
            paddingTop: "20px",
          }}
        >
          <RightImg />
        </div>

        <DialogTitle
          id="alert-dialog-title"
          color={Colors.black}
          sx={{
            fontWeight: "500",
            fontSize: "18px",
            textAlign: "center",
          }}
        >
          {title || ""}
        </DialogTitle>
        <DialogContent sx={{ textAlign: "-webkit-center" }}>
          <DialogContentText
            sx={{
              width: "75%",
              fontWeight: "400",
              fontSize: "14px",
              textAlign: "center",
              py: "10px",
            }}
            id="alert-dialog-description"
            color={Colors.lightGrey}
          >
            {content !== undefined && content !== null ? content : ""}
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            width: "100%",
            pb: "20px",
            px: "20px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {action}
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default PopUpDialog;
