export const Colors = {
  black: '#000000',
  Phlox: '#E728F8',
  grey: '#848484',
  dark_grey: '#445B6B',
  light_grey: '#F5F5F5',
  gray_d4: '#D4D4D4',
  gray_A9: '#A9A9A9',
  gray_F2: '#F2F2F2',
  gray_97: '#979797',
  gray_9C: '#9C9C9C',
  gray_E5: '#E5E5E5',
  white: '#ffffff',
  red: '#D32F2F',
  default: '#E7E7E7',
  primary: '#2e88dd',
  secondary: '#4F0B4E',
  success: '#1EB648',
  warning: '#FFCA28',
  gradient_second: '#090979',
  lawnGreen: '#75F321',
  Gray6b: '#6b6b6b',
  SuvaGrey: '#8F8F8F',
  lightGrey: '#303030',
  silver: '#C0C0C0',
  purple: '#AD19D5',
  VividViolet: '#AC18D5',
  JoustBlue: '#5668D8',
  lavender_blue: '#CED4FF',
  Maximum_blue_purple: '#9CA7F8',
  slate_blue: '#6559D7',
  vivid_mulberry: '#AA19D5',
  spanish_gray: '#9D9D9D',
  bg_white: "rgba(255, 255, 255, 0.6)",
  tx_color: "#7648D6"
};

export const TextColors = {
  lightGrey: Colors.light_grey,
  white: Colors.white,
  black: Colors.black,
  default: Colors.default,
};

export const BackgroundColors = {
  white: Colors.white,
  lightGrey: Colors.light_grey,
  darkGrey: Colors.dark_grey,
  defaultGrey: Colors.gray_F2,
};
