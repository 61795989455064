import { styled } from "@material-ui/styles";
import { Typography, useMediaQuery } from "@mui/material";
import { Box, Stack } from "@mui/system";
import React, { useEffect, useRef, useState } from "react";
import { getJobDetails } from "../../../api/questionAudio";
import useInterviewContext from "../../../Context/mainContext";
import Skeleton from "@mui/material/Skeleton";
import LoadingAnimation from "../../../components/LoadingAnimation";
import CompanyInfo from "./CompanyInfo";
type Props = {};

const Bold = styled("b")({
  fontWeight: 500,
});
const Li = styled("li")({
  marginBottom: "20px",
});
interface JobProps {
  isLoading: any;
  companyInfo: any;
}

const JobDetails = ({ isLoading, companyInfo }: JobProps) => {
  let interviewState: any = useInterviewContext();
  const maxWidth1536 = useMediaQuery("(max-width: 1536px)");

  return (
    <div
      // direction="column"
      style={{ padding: "22px" }}
    >
      {isLoading ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
            width: "100%",
            py: "100px",
          }}
        >
          <LoadingAnimation />
        </Box>
      ) : (
        <Box
          sx={{
            mt: maxWidth1536 ? "10px" : "20px",
            width: "100%",
            overflowY: "auto",
            overflowX: "hidden",
            height: "362px",
            // "@media (max-width: 768px)": {
            //   height: "480px",
            // },
            transition: "width 3s ease-in-out",
            "&::-webkit-scrollbar": {
              width: "3px",
            },
            "&::-webkit-scrollbar-track": {
              borderRadius: "12px",
              background: "#ECE8FF",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#A71DD4",
              borderRadius: "3px",
              height: "36px",
              cursor: "pointer",
            },
            "&::-webkit-scrollbar-thumb:horizontal": {
              borderRadius: "12px",
            },
            "&:hover::-webkit-scrollbar": {
              width: "5px",
            },
            "&:hover::-webkit-scrollbar-thumb": {
              backgroundColor: "#A71DD4",
              cursor: "pointer",
              borderRadius: "5px",
            },
          }}
        >
          <Box
            sx={{
              // ml: "20px",
              width: "100%",
            }}
          >
            <Typography
              sx={{
                fontSize: "20px",
                fontWeight: "500",
                mt: maxWidth1536 ? "35px" : "40px",
                mb: 2,
                // display: "flex",
                // justifyContent: "center",
              }}
            >
              Job Description
            </Typography>
            <Box
            // sx={{
            //   mt: maxWidth1536 ? "20px" : "30px",
            //   width: "100%",
            //   overflowY: "auto",
            //   overflowX: "hidden",
            //   height: "312px",
            //   transition: "width 3s ease-in-out",
            //   "&::-webkit-scrollbar": {
            //     width: "3px",
            //   },
            //   "&::-webkit-scrollbar-track": {
            //     borderRadius: "12px",
            //     background: "#ECE8FF",
            //   },
            //   "&::-webkit-scrollbar-thumb": {
            //     backgroundColor: "#A71DD4",
            //     borderRadius: "3px",
            //     height: "36px",
            //     cursor: "pointer",
            //   },
            //   "&::-webkit-scrollbar-thumb:horizontal": {
            //     borderRadius: "12px",
            //   },
            //   "&:hover::-webkit-scrollbar": {
            //     width: "5px",
            //   },
            //   "&:hover::-webkit-scrollbar-thumb": {
            //     backgroundColor: "#A71DD4",
            //     cursor: "pointer",
            //     borderRadius: "5px",
            //   },
            // }}
            >
              {!interviewState?.interviewData?.job?.Campaign_Job_DescriptionHtml?.toLowerCase().includes(
                "job title"
              ) && (
                <>
                  <Box sx={{ fontSize: "14px", fontWeight: "500" }}>
                    <Typography>
                      Job Title :{" "}
                      {interviewState.interviewData.job.Campaign_Job_Title ===
                        "" ||
                      interviewState.interviewData.job.Campaign_Job_Title ===
                        null
                        ? "not available"
                        : interviewState.interviewData.job.Campaign_Job_Title}
                    </Typography>
                  </Box>{" "}
                  <br></br>
                </>
              )}
              {interviewState?.interviewData?.job
                ?.Campaign_Job_DescriptionHtml ? (
                <Box
                  style={{ width: "92%" }}
                  dangerouslySetInnerHTML={{
                    __html:
                      interviewState?.interviewData?.job
                        ?.Campaign_Job_DescriptionHtml,
                  }}
                ></Box>
              ) : (
                <Box style={{ width: "92%" }}>
                  Job description : No description available.
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      )}
    </div>
  );
};

export default JobDetails;
