import { makeStyles } from "@material-ui/core";
import { Camera } from "@mediapipe/camera_utils";
import { SelfieSegmentation } from "@mediapipe/selfie_segmentation";
import {
  Button,
  Modal,
  Pagination,
  PaginationItem,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Box, Container, Stack } from "@mui/system";
import classNames from "classnames";
import React, { useEffect, useRef, useState } from "react";
import {
  RecruiterData,
  getCandidateApi,
  getCandidateDetails,
  getEmployerDetails,
  getEnterpriseData,
  getEnterpriseDetails,
  getJobDetails,
  getSynthesiaVideoById,
  getSynthesiaVideos,
  questionAudio,
} from "../../api/questionAudio";
import ProgressBar from "../../components/ProgressBar";
import TextToSpeech from "../../components/TextToSpeech";
import Video from "../../components/Video";
import useInterviewContext from "../../Context/mainContext";
import OfficeImage from "../../assets/office/tironem_background.png";
import { ReactComponent as CompletedIcon } from "../../assets/svg/CompletedTickMark.svg";
import { ReactComponent as Smiley } from "../../assets/svg/smiley.svg";
import PermissionDialog from "../../components/PermissionDialog";
import FinishedInterview from "./stages/FinishedInterview";
import { ReactMic } from "react-mic";
import PopUpDialog from "../../components/PopUpDialog";
import { logout } from "../../utils/ProtectedRoutes";
import { Colors } from "../../theme/colors";
import { useLocation, useNavigate } from "react-router-dom";
import useToaster from "../../utils/Toaster";
import Header from "../../components/Header";
import Tironem_LOGO from "../../assets/FooterLogo.png";

// import Modal from "@mui/material/Modal";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  // boxShadow: 24,
  gap: "15px",
  borderRadius: "12px",
  boxShadow: "5px 5px 30px rgba(255, 255, 255, 0.5)",
  p: 4,
};
let camera: Camera;

const useStyles: any = makeStyles((theme: Theme) => ({
  video: {
    width: "954px",
    height: "410px",
    objectFit: "cover",
    borderRadius: "14px",
  },
  mic: {
    position: "absolute",
    top: "-1000px ",
  },

  paginationItemStyle: {
    color: "#000000 !important",
    background: "#D6D6D6 !important",
    border: "none   ",

    "&.Mui-selected": {
      background: "#3F7DD8 !important",
      color: "#ffffff !important",
    },
    "&.Mui-selected span": {
      // borderBottom: '4px solid #3F7DD8',
    },
    "&.MuiPaginationItem-previousNext": {
      display: "none",
    },
    "&.MuiPaginationItem-previousNext span": {
      borderBottom: "none",
      background: "transparent !important",
    },
  },
  completedPaginationItemStyle: {
    color: "#000000 !important",
    background: "#34A853 !important",
    border: "none   ",

    "&.Mui-selected": {
      background: "#34A853!important",
      color: "#ffffff !important",
    },
    "&.Mui-selected span": {
      // borderBottom: '4px solid #3F7DD8',
    },
    "&.MuiPaginationItem-previousNext": {
      display: "none",
    },
    "&.MuiPaginationItem-previousNext span": {
      borderBottom: "none",
      background: "transparent !important",
    },
  },
  paginationItem: {
    width: "42px",
    height: "42px",
  },
}));
const CandidateInterviewPageNew = () => {
  //   const myVideo = useRef<any>();
  const classes = useStyles();
  const maxWidth1536 = useMediaQuery("(max-width: 1536px)");

  let interviewState: any = useInterviewContext();

  const navigate = useNavigate();

  const location = useLocation();
  const closingVideoRef = useRef<any>();
  const initialVideoRef = useRef<any>();

  const [isSilenceInQuestion, setIsSilenceInQuestion] = useState<any[]>([]);
  const [open, setOpen] = React.useState(true);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [msg, sendNotification] = useToaster();
  const [leavePage, setLeavePage] = useState(false);
  const [moveBack, setMoveBack] = useState(false);

  const backgroundImage = new Image();
  backgroundImage.src = OfficeImage;
  // To call APIS
  const [isApiResponseGet, setIsApiResponseGet] = useState({
    questionAudio: false,
    candidateDetails: false,
    enterpriseDetails: false,
    // jobDetails: false,
  });

  useEffect(() => {
    window.history.pushState(null, "", window.location.href);
    window.onpopstate = function (event) {
      setLeavePage(true);
      navigate("/candidate");
    };
  }, [navigate]);
  useEffect(() => {
    if (moveBack) {
      navigate("/login");
      const message = "Session Expired.";
      loginExpired(message);
    }
  }, [moveBack]);

  useEffect(() => {
    const handleTabClose = (event: any) => {
      event.preventDefault();

      console.log("beforeunload event triggered");

      return (event.returnValue = "Are you sure you want to exit?");
    };

    window.addEventListener("beforeunload", handleTabClose);

    return () => {
      window.removeEventListener("beforeunload", handleTabClose);
    };
  }, []);

  const loginExpired = (message: string) => {
    sendNotification({ msg: message, variant: "success" });
    localStorage.clear();
    navigate("/login");
  };
  useEffect(() => {
    if (
      isApiResponseGet.questionAudio &&
      isApiResponseGet.candidateDetails &&
      isApiResponseGet.enterpriseDetails
      // isApiResponseGet.jobDetails
    ) {
      interviewState.setIsLoading(false);
    }

    if (interviewState.timerOff) {
      interviewState.setIsTimerOn(true);

      // interviewState.startRecordingVideo();
    }
  }, [isApiResponseGet, interviewState.timerOff]);

  useEffect(() => {
    (async () => {
      // get all question texts and audio
      questionAudio()
        .then((response: any) => {
          if (response.status === 401) {
            const message = "Session Expired.Please login again.";
            loginExpired(message);
          }
          if (response?.status === 200) {
            const abc = response?.data;

            abc.sort((a: any, b: any) => a.Question_Order - b.Question_Order);

            const filteredArr: any = [];
            abc?.map((item: any, index: number) => {
              if (item?.Question_Deleted === 0) {
                return filteredArr?.push(item);
              }
            });
            if (filteredArr.length === 0) {
              const message = "No Interview scheduled for you";
              loginExpired(message);
            }

            const questionArray = filteredArr?.map(
              (item: any, index: number) => {
                return {
                  question_test: item?.Tironem_Question_Text,
                  question_id: item?.pk_Question_ID,
                  Question_AccessLevel: item?.Question_AccessLevel,
                  Question_Status: item?.Question_Status,
                };
              }
            );

            interviewState.setQuestionsText(questionArray);
            var arr = Array.from(
              { length: questionArray.length },
              (item, index) => true
            );
            setIsSilenceInQuestion(arr);
            const audioFileArray = filteredArr?.map(
              (item: any, index: number) => {
                return item.Question_AudioFileUrl;
              }
            );

            interviewState.setQuestionsAudio(audioFileArray);

            const synthesiaVideoArray = filteredArr?.map(
              (item: any, index: number) => {
                return item.SynthesiaVideo_BlobUrl;
              }
            );

            interviewState.setSynthesiaVideoLinkArray(synthesiaVideoArray);
            setIsApiResponseGet((prev) => ({
              ...prev,
              questionAudio: true,
            }));
          }
        })
        .catch((error) => {
          interviewState.setIsLoading(false);
        });

      //
      getCandidateDetails().then((response: any) => {
        if (response.status === 401) {
          const message = "Session Expired.Please login again.";
          loginExpired(message);
        }
        if (response?.status === 200 || response?.successful) {
          if (response.data === null) {
            const message = "No Interview scheduled for you";
            loginExpired(message);
          }
          interviewState.setInterviewData((prev: any) => ({
            ...prev,
            candidate: response?.data,
          }));

          if (interviewState.synthesiaVideos.closingVideo === null) {
            const synthesiadata = {
              campaign_id: response.data.fk_Campaign_ID,
              table_name: "Campaign",
              field_name: "Interview Introduction Dialog",
              enterprise_id: response.data.fk_Enterprise_ID,
            };
            getSynthesiaVideos(synthesiadata).then((videoresponse: any) => {
              videoresponse.data.forEach((video: any) => {
                if (
                  video.SynthesiaVideoIntegration_FieldName ===
                  "Engagement Dialog"
                ) {
                  interviewState.setSynthesiaVideos((prevState: any) => ({
                    ...prevState,
                    engageVideo: video,
                  }));
                } else if (
                  video.SynthesiaVideoIntegration_FieldName ===
                  "Interview Introduction Dialog"
                ) {
                  interviewState.setSynthesiaVideos((prevState: any) => ({
                    ...prevState,
                    introductionVideo: video,
                  }));
                } else if (
                  video.SynthesiaVideoIntegration_FieldName ===
                  "Interview Closing Dialog"
                ) {
                  interviewState.setSynthesiaVideos((prevState: any) => ({
                    ...prevState,
                    closingVideo: video,
                  }));
                }
              });
            });
          }

          RecruiterData(response.data.pk_CampaignLine_ID)
            .then((response: any) => {
              let name = response?.data?.RecruiterName;
              interviewState.setRecruiterName(name);
            })
            .catch((err) => {});

          if (Object.keys(interviewState.interviewData?.job).length === 0) {
            getCandidateApi(response?.data.fk_Campaign_ID)
              .then((responsecandidate: any) => {
                if (responsecandidate.status === 401) {
                  const message = "Session Expired.Please login again.";
                  loginExpired(message);
                }

                if (
                  responsecandidate.status === 200 ||
                  responsecandidate.successful === true
                ) {
                  getEmployerDetails(response?.data?.fk_Enterprise_ID)
                    .then((responseEnterprise: any) => {
                      if (
                        responseEnterprise.status === 200 ||
                        responseEnterprise.successful === true
                      ) {
                        if (
                          responseEnterprise?.data?.Enterprise_ImageLink ===
                            null ||
                          responseEnterprise?.data?.Enterprise_ImageLink === ""
                        ) {
                        } else {
                          const getSpecificCompany =
                            responseEnterprise.data.filter(
                              (ei) =>
                                ei.pk_Employer_ID ===
                                response?.data?.Campaign_Tolerance
                            );

                          const body = {
                            Enterprise_AccessLevel:
                              getSpecificCompany[0]?.Employer_AccessLevel,
                            Enterprise_AlternateNumber:
                              getSpecificCompany[0]?.Employer_AlternateNumber,
                            Enterprise_AlternateNumber_AreaCode:
                              getSpecificCompany[0]
                                ?.Employer_AlternateNumber_AreaCode,
                            Enterprise_AlternateNumber_CountryCode:
                              getSpecificCompany[0]
                                ?.Employer_AlternateNumber_CountryCode,
                            Enterprise_AlternateNumber_Extention:
                              getSpecificCompany[0]
                                ?.Employer_AlternateNumber_Extention,
                            Enterprise_AvatarEnable:
                              getSpecificCompany[0]?.Employer_BusinessNumber,
                            Enterprise_BackgroundColour:
                              getSpecificCompany[0]
                                ?.Employer_BackgroundColour ??
                              Colors.Maximum_blue_purple,
                            Enterprise_BussinessNumber:
                              getSpecificCompany[0]?.Employer_AccessLevel,
                            Enterprise_City:
                              getSpecificCompany[0]?.Employer_City,
                            Enterprise_Colour:
                              getSpecificCompany[0]?.Employer_Colour ?? "#000",
                            Enterprise_CompanyNumber:
                              getSpecificCompany[0]?.Employer_CompanyNumber,
                            Enterprise_Country:
                              getSpecificCompany[0]?.Employer_Country,
                            Enterprise_CountryCoded:
                              getSpecificCompany[0]?.Employer_CountryCoded,
                            Enterprise_DUNs:
                              getSpecificCompany[0]?.Employer_DUNs,
                            Enterprise_DateOfCreation:
                              getSpecificCompany[0]?.Employer_DateOfCreation,
                            Enterprise_DateOfLastUpdate:
                              getSpecificCompany[0]?.Employer_DateOfLastUpdate,
                            Enterprise_Deleted:
                              getSpecificCompany[0]?.Employer_Deleted,
                            Enterprise_Email:
                              getSpecificCompany[0]?.Employer_Email,
                            Enterprise_ImageLink:
                              getSpecificCompany[0]?.Employer_ImageLink,
                            Enterprise_Initials:
                              getSpecificCompany[0]?.Employer_Initials,
                            Enterprise_Name:
                              getSpecificCompany[0]?.Employer_Name,
                            Enterprise_Name2:
                              getSpecificCompany[0]?.Employer_Name2,
                            Enterprise_Notes:
                              getSpecificCompany[0]?.Employer_Notes,
                            Enterprise_PhoneNumber:
                              getSpecificCompany[0]?.Employer_PhoneNumber,
                            Enterprise_PhoneNumber_AreaCode:
                              getSpecificCompany[0]
                                ?.Employer_PhoneNumber_AreaCode,
                            Enterprise_PhoneNumber_CountryCode:
                              getSpecificCompany[0]
                                ?.Employer_PhoneNumber_CountryCode,
                            Enterprise_PhoneNumber_Extention:
                              getSpecificCompany[0]
                                ?.Employer_PhoneNumber_Extention,
                            Enterprise_Postcode:
                              getSpecificCompany[0]?.Employer_Postcode,
                            Enterprise_Record_GUID:
                              getSpecificCompany[0]?.Employer_Record_GUID,
                            Enterprise_RefernceCode:
                              getSpecificCompany[0]?.Employer_RefernceCode,
                            Enterprise_Region:
                              getSpecificCompany[0]?.Employer_Region,
                            Enterprise_Status:
                              getSpecificCompany[0]?.Employer_Status,
                            Enterprise_StreetAddress:
                              getSpecificCompany[0]?.Employer_StreetAddress,
                            Enterprise_StreetAddress2:
                              getSpecificCompany[0]?.Employer_StreetAddress2,
                            Enterprise_StreetAddress3:
                              getSpecificCompany[0]?.Employer_StreetAddress3,
                            Enterprise_TaxRegistrationNumber:
                              getSpecificCompany[0]
                                ?.Employer_TaxRegistrationNumber,
                            fk_Enterprise_ID:
                              getSpecificCompany[0]?.fk_Enterprise_ID,
                            fk_UserID_MainContact:
                              getSpecificCompany[0]?.fk_UserID_MainContact,
                            fk_User_ID: getSpecificCompany[0]?.fk_User_ID,
                            isAgencies: false,
                            pk_Enterprise_ID:
                              getSpecificCompany[0]?.pk_Employer_ID,
                            sSession: getSpecificCompany[0]?.sSession,
                          };

                          interviewState.setEnterpriseData(body);
                        }
                      }
                    })
                    .catch((error) => {
                      console.log(error);
                    });
                  interviewState.setInterviewData((prev: any) => ({
                    ...prev,
                    job: responsecandidate?.data,
                  }));
                  interviewState.setJobData((prev: any) => ({
                    ...prev,
                    jobTitle: responsecandidate.data.Campaign_Job_Title,
                    jobDescription:
                      responsecandidate.data?.Campaign_Job_DescriptionHtml,
                  }));
                  if (!interviewState.enterpriseData) {
                    getEnterpriseData(response?.data.fk_Enterprise_ID)
                      .then((responseEnterprise: any) => {
                        if (
                          responseEnterprise.status === 200 ||
                          responseEnterprise.successful === true
                        ) {
                          if (
                            responseEnterprise?.data?.Enterprise_ImageLink ===
                              null ||
                            responseEnterprise?.data?.Enterprise_ImageLink ===
                              ""
                          ) {
                          } else {
                            interviewState.setEnterpriseData(
                              responseEnterprise.data
                            );
                          }
                        }
                      })
                      .catch((error) => {
                        console.log(error);
                      });
                  }
                }
              })
              .catch((error) => {
                console.log(error);
              });
          }

          getEnterpriseDetails(response?.data?.fk_Enterprise_ID)
            .then((response: any) => {
              if (response.status === 401) {
                const message = "Session Expired.Please login again.";
                loginExpired(message);
              }

              interviewState.setInterviewData((prev: any) => ({
                ...prev,
                enterprise: response?.data,
              }));

              setIsApiResponseGet((prev) => ({
                ...prev,
                enterpriseDetails: true,
              }));
            })
            .catch((error) => {});
        }

        setIsApiResponseGet((prev) => ({
          ...prev,
          candidateDetails: true,
        }));
      });
    })();

    return () => {
      interviewState.setCurrentQuestionIndex(-1);
      interviewState.setIsInitState(true);
      interviewState.setIsRecordingStarted(false);
      interviewState.setTimer(3);
      interviewState.setTimerOff(false);
      interviewState.recorder && interviewState.recorder.stopRecording();
      interviewState.recorderForEachQuestionRecord &&
        interviewState.recorderForEachQuestionRecord?.stopRecording();
      // interviewState.testingFunc();
    };
  }, []);
  // useEffect(() => {
  //   if (!!interviewState.stream && !interviewState?.isRecordingStarted) {
  //     interviewState.recorder?.startRecording();
  //     interviewState.recorderForEachQuestionRecord?.startRecording();
  //     interviewState.setIsRecordingStarted(true);
  //   }
  // }, [interviewState.stream, interviewState.isRecordingStarted]);

  useEffect(() => {
    if (!!interviewState.stream && interviewState.isRecordingStarted) {
      let timer = setTimeout(() => {
        interviewState.setExtendedSilence(true);
      }, 5000);

      return () => {
        clearTimeout(timer);
        interviewState.setExtendedSilence(false);
      };
    }
  }, [interviewState.noiseArray]);

  // useEffect(() => {
  //   if (!!interviewState.stream) {
  //     let timer = setTimeout(() => {
  //       interviewState.setExtendedSilence(true);
  //     }, 5000);

  //     return () => {
  //       clearTimeout(timer);
  //       interviewState.setExtendedSilence(false);
  //     };
  //   }
  // }, [interviewState.noiseArray]);

  useEffect(() => {
    if (!!interviewState.extendedSilence && !!interviewState.stream) {
      // console.log('code to run when extended silence')

      interviewState.setExtendedSilenceArray((prevState: any) => [
        ...prevState,
        1,
      ]);
    }
  }, [interviewState.extendedSilence, interviewState.stream]);

  // useEffect(() => {
  //   interviewState.setIsTimerOn(true);
  //   if (interviewState.isInitState === true) {
  //     let constriants = interviewState.getAudioVideoConfigConstraints();

  //     navigator.mediaDevices
  //       .getUserMedia(constriants)
  //       .then((stream) => {
  //         if (
  //           stream?.getVideoTracks()?.length > 0 &&
  //           stream?.getAudioTracks()?.length > 0
  //         ) {
  //           interviewState.setStream(stream);
  //           interviewState.setVideoBlob(null);
  //           interviewState.myVideo.current.srcObject = stream;
  //           interviewState.setOpenPermissionDialog(false);
  //           // setIsLoading(false);
  //         } else {
  //           interviewState.setOpenPermissionDialog(true);
  //         }
  //         return navigator.mediaDevices.enumerateDevices();
  //       })
  //       .then((devices) => {
  //         interviewState.setAudioDevices([]);
  //         interviewState.setVideoDevices([]);
  //         devices.forEach((device) => {
  //           if (device.kind == 'audioinput' && device.label)
  //             interviewState.setAudioDevices((prev: any) => [...prev, device]);

  //           if (device.kind == 'videoinput' && device.label)
  //             interviewState.setVideoDevices((prev: any) => [...prev, device]);
  //         });
  //       })
  //       .catch((err: any) => {
  //         console.error('!', err.message);
  //       });
  //   }
  //   return () => {
  //     interviewState.stream?.getTracks().forEach((track: any) => {
  //       track.stop();
  //     });

  //     interviewState.finalStream?.getTracks().forEach((track: any) => {
  //       track.stop();
  //       track.enabled = false;
  //     });

  //     // interviewState.recorder && interviewState.recorder.stopRecording();
  //     // interviewState.recorderForEachQuestionRecord &&
  //     //   interviewState.recorderForEachQuestionRecord?.stopRecording();

  //     // interviewState.setStream(null);
  //     // interviewState.setVideoBlob(null);
  //     // interviewState.setIsInitState(true);
  //     // interviewState.setInterviewStatus({
  //     //   isInterviewStarted: false,
  //     //   isInterviewFinished: false,
  //     // });
  //   };
  // }, []);

  useEffect(() => {
    if (
      interviewState.videoDevices.length === 0 &&
      interviewState.audioDevices.length === 0
    ) {
      navigator.mediaDevices
        .getUserMedia({ audio: true, video: true })
        .then((stream) => {
          if (
            stream?.getVideoTracks()?.length > 0 &&
            stream?.getAudioTracks()?.length > 0
          ) {
            // setCameraNotFound(false)
            interviewState.setStream(stream);
            interviewState.myVideo.current.srcObject = stream;
          } else {
            alert(" Please give permission to camera and mic");
          }
          return navigator.mediaDevices.enumerateDevices();
        })
        .then((devices) => {
          devices.forEach((device) => {
            if (device.kind == "audioinput" && device.label)
              interviewState.setAudioDevices((prev: any) => {
                return [...prev, device];
              });

            if (device.kind == "videoinput" && device.label)
              interviewState.setVideoDevices((prev: any) => {
                return [...prev, device];
              });
          });
        })
        .catch((err: any) => {
          console.error("!", err?.message);
        });

      return () => {
        navigator.mediaDevices
          .getUserMedia({ audio: true, video: true })
          .then((stream) =>
            stream.getTracks().forEach((track: any) => {
              track.stop();
            })
          );
      };
    } else {
      interviewState.onCameraAndAudioSettingSave("assigned");
    }
  }, []);

  useEffect(() => {
    if (
      interviewState.questionText?.length > 0 &&
      interviewState.questionAudio[0] === undefined &&
      interviewState.currentQuestionIndex === 0
    ) {
      interviewState.changeQuestion(0);
    }
  }, [interviewState.questionAudio]);

  useEffect(() => {
    if (interviewState.interviewStatus.isInterviewFinished) {
      if (typeof interviewState.stream?.stop == "function") {
        interviewState.stream?.stop();
      } else if (typeof interviewState.stream?.stop == "function") {
        interviewState.stream?.getTracks().forEach(async (track: any) => {
          if (track.readyState == "live") {
            await track.stop();
          }
        });
      }
    }
  }, [interviewState.interviewStatus.isInterviewFinished]);

  useEffect(() => {
    const videoElement = interviewState.myVideo.current;
    const canvasElement = interviewState.canvasOutputRef.current;
    // Start rendering the frames
    const renderFrame = async () => {
      canvasElement.width =
        videoElement?.videoWidth > 640 ? 640 : videoElement.videoWidth;
      canvasElement.height =
        videoElement?.videoHeight > 480 ? 480 : videoElement.videoHeight;

      // Draw the current video frame on the canvas
      const context = interviewState.canvasCtx;
      await context?.drawImage(videoElement, 0, 0);

      // Call the renderFrame function recursively to keep updating the canvas
      requestAnimationFrame(renderFrame);
    };
    if (videoElement) {
      renderFrame();
    }

    return () => {
      // Cleanup: Stop rendering frames when the component unmounts
      // cancelAnimationFrame(requestAnimationFrame(renderFrame))
    };
  }, [interviewState.stream]);
  // useEffect(() => {
  //   if (interviewState.stream !== undefined && interviewState.stream !== null) {
  //     interviewState.recorder?.pauseRecording();
  //     interviewState.recorderForEachQuestionRecord?.pauseRecording();
  //   }
  //   {
  //     interviewState.recorder?.resumeRecording();
  //     interviewState.recorderForEachQuestionRecord?.resumeRecording();
  //   }
  //   // return () => {
  //   //   second;
  //   // };
  // }, [interviewState.stream]);

  // useEffect(() => {
  //   // if (
  //   //   interviewState.canvasOutputRef.current !== undefined &&
  //   //   interviewState.canvasOutputRef.current !== null
  //   // ) {

  //   function onResults(results: any) {
  //     interviewState.canvasCtx?.save();
  //     interviewState.canvasCtx?.clearRect(
  //       0,
  //       0,
  //       interviewState.canvasOutputRef.current?.width,
  //       interviewState.canvasOutputRef.current?.height
  //     );
  //     if (interviewState.isVideoBackgroundEnabled) {
  //       interviewState.canvasCtx?.drawImage(
  //         results.segmentationMask,
  //         0,
  //         0,
  //         interviewState.canvasOutputRef.current?.width,
  //         interviewState.canvasOutputRef.current?.height
  //       );

  //       // if (backgroundImage.src !== '') {
  //       if (interviewState.videoBackgroundConfig.isImgBackground) {
  //         // Only overwrite existing pixels.
  //         interviewState.canvasCtx.globalCompositeOperation = 'source-in';

  //         interviewState.canvasCtx?.drawImage(
  //           results.image,
  //           0,
  //           0,
  //           interviewState.canvasOutputRef.current?.width,
  //           interviewState.canvasOutputRef.current?.height
  //         );

  //         // Only overwrite missing pixels.
  //         interviewState.canvasCtx.globalCompositeOperation =
  //           'destination-atop';
  //         interviewState.canvasCtx?.drawImage(
  //           backgroundImage,
  //           0,
  //           0,
  //           interviewState.canvasOutputRef.current?.width,
  //           interviewState.canvasOutputRef.current?.height
  //         );
  //       } else {
  //         // Only overwrite existing pixels.
  //         interviewState.canvasCtx.globalCompositeOperation = 'source-out';
  //         //color of the background
  //         interviewState.canvasCtx.fillStyle =
  //           interviewState.videoBackgroundConfig.videoBackgroundColor;
  //         interviewState.canvasCtx.fillRect(
  //           0,
  //           0,
  //           interviewState.canvasOutputRef.current?.width,
  //           interviewState.canvasOutputRef.current?.height
  //         );

  //         // Only overwrite missing pixels.
  //         interviewState.canvasCtx.globalCompositeOperation =
  //           'destination-atop';
  //         interviewState.canvasCtx?.drawImage(
  //           results.image,
  //           0,
  //           0,
  //           interviewState.canvasOutputRef.current?.width,
  //           interviewState.canvasOutputRef.current?.height
  //         );
  //       }
  //     } else {
  //       // Only overwrite missing pixels.
  //       interviewState.canvasCtx.globalCompositeOperation = 'destination-atop';
  //       interviewState.canvasCtx?.drawImage(
  //         results.image,
  //         0,
  //         0,
  //         interviewState.canvasOutputRef.current?.width,
  //         interviewState.canvasOutputRef.current?.height
  //       );
  //     }

  //     interviewState.canvasCtx.restore();
  //   }

  //   let selfieSegmentation = new SelfieSegmentation({
  //     locateFile: (file) => {
  //       return `https://cdn.jsdelivr.net/npm/@mediapipe/selfie_segmentation/${file}`;
  //     },
  //   });

  //   selfieSegmentation.setOptions({
  //     modelSelection: 0,
  //   });

  //   selfieSegmentation.onResults(onResults);
  //   camera = new Camera(interviewState.finalVideo.current, {
  //     onFrame: async () => {
  //       await selfieSegmentation.send({
  //         image: interviewState.myVideo.current,
  //       });
  //     },
  //     width: interviewState.canvasOutputRef.current?.width,
  //     height: interviewState.canvasOutputRef.current?.height,
  //   });
  //   // }

  //   if (
  //     (!!interviewState.stream || interviewState.stream !== undefined) &&
  //     interviewState.interviewStatus.isInterviewFinished === false
  //   ) {
  //     !!interviewState.stream && camera.start();
  //   } else {
  //     camera.stop();
  //   }

  //   return () => {
  //     selfieSegmentation.reset();
  //     selfieSegmentation.close();
  //     camera.stop();
  //   };
  // }, [
  //   interviewState.stream,
  //   interviewState.videoBackgroundConfig.videoBackgroundColor,
  //   // interviewState.isVideoBackgroundEnabled,
  //   interviewState.canvasOutputRef.current,
  // ]);

  // useEffect(() => {
  //   if (interviewState.finalVideo.current?.srcObject !== undefined) {
  //     let canvasData = interviewState.canvasOutputRef?.current;
  //     let abStream;
  //     if (canvasData?.captureStream) {
  //       abStream = interviewState.canvasOutputRef?.current?.captureStream();
  //     } else if (canvasData?.mozCaptureStream) {
  //       abStream = interviewState.canvasOutputRef?.current?.mozCaptureStream();
  //     } else {
  //       console.error('Stream capture is not support');
  //       abStream = null;
  //     }

  //     interviewState.finalVideo.current.srcObject = abStream;
  //     interviewState.setFinalStream(abStream);
  //     return () => {
  //       // interviewState.finalVideo.current.remove();
  //       interviewState.stopMediaTracks(interviewState.finalStream);
  //     };
  //   }
  // }, []);

  // useEffect(() => {
  //   if (closingVideoRef?.current) {
  //     if (closingVideoRef?.current?.paused) {
  //       interviewState?.setClosingVideoPlay(true);
  //     } else {
  //       interviewState.setClosingVideoPlay(false);
  //     }
  //   }
  // }, [
  //   closingVideoRef?.current?.paused,
  //   interviewState.interviewUploadSuccessfull,
  // ]);

  useEffect(() => {
    if (initialVideoRef?.current?.ended && interviewState?.initialVideoPlay) {
      interviewState?.setInitialVideoPlay(false);

      if (interviewState.timerOff) {
        interviewState.startRecordingVideo();
      }
    }
  }, [initialVideoRef?.current?.ended === true]);

  useEffect(() => {
    if (closingVideoRef?.current) {
      if (closingVideoRef?.current?.ended === true) {
        interviewState?.setClosingVideoPlay(false);
        // interviewState?.setIsSynthesiaClosingPlaying(true);
        interviewState.setOpenPopup(true);
      }
      // closingVideoRef.current.onended = (e: any) => {
      // };
    }
  }, [closingVideoRef?.current?.ended === true]);

  return (
    <Container
      disableGutters
      maxWidth={false}
      sx={{
        width: "100%",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-between",
        // width: '100%',
        // height: '100vh',
        // display: 'flex',
        // flexDirection: 'column',
        // alignItems: 'center',
        // justifyContent: 'center',
        // background: 'white',
      }}
    >
      <Box
        sx={{
          background: "rgba(255, 255, 255, 0.3)",
          position: "relative",
          height: "90%",
          width: "92%",
          borderRadius: "20px",
          border: "3px solid #FFFFFF",
          // backdropFilter: 'blur(40px)',
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "start",
          "@media (max-width: 768px)": {
            // backgroundColor: "orange",
          },
        }}
      >
        <Header
          image={
            interviewState?.enterpriseData?.Enterprise_ImageLink ??
            interviewState?.enterpriseData?.Enterprise_ImageLink
          }
          employer={interviewState?.enterpriseData}
        />

        <Modal
          open={leavePage}
          // onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{
            background: "rgba(0, 0, 0, 0.5)",
            backdropFilter: "blur(10px)",
          }}
        >
          <Box sx={style}>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Smiley />
            </Box>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: "20px",
              }}
            >
              Sorry!
            </Typography>

            <Typography sx={{ textAlign: "center", fontSize: "16px" }}>
              Click cancel to continue with interview else click leave to exit
              interview.
            </Typography>
            <Stack
              direction={"row"}
              spacing={3}
              sx={{
                width: "100%",
                mt: "6px",
              }}
            >
              <Button
                variant="contained"
                sx={{
                  width: "100%",
                  color: "#2B7AFC",
                  borderRadius: "4px",
                  backgroundColor: "#D7E5FC",

                  "&:hover": { backgroundColor: "#D7E5FC" },
                }}
                onClick={() => {
                  setLeavePage(false);
                  setMoveBack(true);
                }}
              >
                Leave
              </Button>
              <Button
                variant="contained"
                sx={{
                  width: "100%",
                  background: "#2B7AFC",
                  borderRadius: "4px",
                }}
                onClick={() => setLeavePage(false)}
              >
                Cancel
              </Button>
            </Stack>

            {/* <Stack
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Typography>Your interview will end, if you go back.</Typography>
            <Typography
              id="modal-modal-description"
              sx={{ fontSize: '22px', fontWeight: '300' }}
            >
              Are you sure?
            </Typography>
          </Stack> */}
            {/* <Stack
            direction="row"
            sx={{ justifyContent: 'center', alignItem: 'center' }}
          >
            <Button
              onClick={() => {
                setLeavePage(false);
                setMoveBack(true);
              }}
            >
              Leave
            </Button>
            <Button onClick={() => setLeavePage(false)}>Cancel</Button>
          </Stack> */}
          </Box>
        </Modal>
        {/* Video Component */}
        {!interviewState.interviewStatus.isInterviewFinished &&
        interviewState.synthesiaVideos.closingVideo !== null &&
        interviewState?.isSynthesiaClosingPlaying === false ? (
          <Box
            sx={{
              width: maxWidth1536 ? "896px" : "896px",
              "@media (max-width: 768px)": {
                width: "98%",
              },
            }}
          >
            <Stack direction="column">
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Box
                  sx={{
                    width: maxWidth1536 ? "880px" : "880px",
                    height: maxWidth1536 ? "300px" : "378px",
                    objectFit: "cover",
                    borderRadius: "14px",
                    // "@media (max-width: 768px)": {
                    //   height: "480px",
                    // },
                    "@media (max-width: 768px)": {
                      width: "98%",
                      height: "200px",
                    },
                  }}
                >
                  <PermissionDialog
                    open={interviewState.openPermissionDialog}
                  />
                  <ReactMic
                    strokeColor={"transparent"}
                    record={!!interviewState.stream}
                    onData={interviewState.onAudioDataHandler}
                    onStop={() => console.log("stopping")}
                    echoCancellation={true} // defaults -> false
                    // autoGainControl={true} // defaults -> false
                    noiseSuppression={true} // defaults -> false
                    className={classes.mic}
                  />
                  {/* {console.log("display", interviewState)} */}
                  <Video
                    setTimeState={interviewState.setTimeState}
                    // timer={timer}
                    // setTimer={setTimer}
                    // displayVideo={
                    //   interviewState.interviewStatus.isInterviewFinished ===
                    //     true &&
                    //   interviewState.synthesiaVideos.closingVideo !== null &&
                    //   interviewState?.isSynthesiaClosingPlaying === false
                    //     ? true
                    //     : false
                    // }
                    closingVideoRef={closingVideoRef}
                    initialVideoRef={initialVideoRef}
                  />

                  <canvas
                    ref={interviewState.canvasRef}
                    id="avtarImg"
                    style={{
                      width: interviewState.myVideo.current?.videoWidth,
                      height: interviewState.myVideo.current?.videoHeight,
                      position: "absolute",
                      top: "-10000px",
                      left: "-100000px",
                      display: "none",
                    }}
                  />
                </Box>
              </Box>

              {/* Pagination */}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  padding: maxWidth1536 ? "16px 0" : "16px 0",
                }}
              >
                <Pagination
                  shape="circular"
                  page={interviewState.currentQuestionIndex}
                  count={interviewState.questionsText?.length}
                  boundaryCount={3}
                  // sx={{
                  //   marginInline: -25,
                  // }}
                  size="large"
                  renderItem={(item: any) => {
                    item.selected =
                      item.page === interviewState.currentQuestionIndex + 1;

                    return (item.page <
                      interviewState.currentQuestionIndex + 1 &&
                      item.page > 0) ||
                      interviewState.interviewStatus.isInterviewFinished ? (
                      <PaginationItem
                        {...item}
                        page={
                          <CompletedIcon
                            style={{
                              height: "13px",
                              width: "18px",
                            }}
                          />
                        }
                        className={classNames(
                          classes.completedPaginationItemStyle,

                          (!!interviewState.stream ||
                            !interviewState.isRecordingOn ||
                            interviewState.isInterviewFinished) &&
                            classes.paginationItemStyleDisable
                        )}
                      ></PaginationItem>
                    ) : (
                      <PaginationItem
                        {...item}
                        // onClick={() => changeQuestion(item.page ? item.page - 1 : 0)}
                        className={classNames(
                          classes.paginationItemStyle,

                          (!!interviewState.stream ||
                            !interviewState.isRecordingOn ||
                            interviewState.isInterviewFinished) &&
                            classes.paginationItemStyleDisable
                        )}
                      />
                    );
                  }}
                />
              </Box>
              {!interviewState?.initialVideoPlay && (
                <TextToSpeech
                  timeForEachQuestion={5}
                  isSilenceInQuestion={isSilenceInQuestion}
                  setIsSilenceInQuestion={setIsSilenceInQuestion}
                  closingVideoRef={closingVideoRef}
                />
              )}
            </Stack>
          </Box>
        ) : (
          // <></>
          <FinishedInterview
            timeState={interviewState.timeState}
            // displayVideo={
            //   interviewState.interviewStatus.isInterviewFinished === true &&
            //   interviewState.synthesiaVideos.closingVideo !== null &&
            //   interviewState?.isSynthesiaClosingPlaying === false
            //     ? true
            //     : false
            // }
            // closingVideoRef={closingVideoRef}
          />
        )}

        <PopUpDialog
          open={
            interviewState.interviewUploadSuccessfull === true &&
            // interviewState?.isSynthesiaClosingPlaying === true &&
            interviewState.openPopup
              ? true
              : false
          }
          title={"The interview upload is now complete"}
          content={
            "Make sure you click the button below to exit the interview before closing the browser window."
          }
          action={
            <>
              <Button
                variant="contained"
                onClick={() => {
                  logout();
                  interviewState.logoutState();
                  navigate("/");
                }}
                sx={{
                  width: "200px",
                  background: "#FFFFFF",
                  border: "1px solid #A51ED4",
                  color: "#A51ED4",
                  borderRadius: "6px",
                  fontWeight: "500",
                  fontSize: "18px",
                  // background: `linear-gradient(to right, ${Colors.VividViolet} , ${Colors.JoustBlue})`,
                  ":hover, :focus, :active": {
                    background: "#FFFFFF",
                    border: "1px solid #A51ED4",
                    color: "#A51ED4",
                  },
                }}
              >
                Exit
              </Button>
            </>
          }
        />
      </Box>
      <Box
        sx={{
          fontSize: "14px",
          fontWeight: 400,
          width: "100%",
          textAlign: "center",
          paddingY: "10px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: "15px",

          "@media (max-width: 768px)": {
            marginTop: "10px",
          },
        }}
      >
        <Typography sx={{ paddingTop: "0px !important" }}>
          © Copyright Tironem 2023 | Powered by{" "}
        </Typography>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <img src={Tironem_LOGO} style={{ width: "" }} />
        </Box>
      </Box>
    </Container>
  );
};

export default CandidateInterviewPageNew;

// displayVideo={
//   interviewState.interviewUploadSuccessfull === true &&
//   interviewState.synthesiaVideos.closingVideo !== null
//     ? true
//     : false
// }
