import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { keyframes, styled } from '@mui/material/styles';
import {
  StepConnector,
  stepConnectorClasses,
  StepIconProps,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';

interface StepperProps {
  activeStep: number;
  steps: any[];
}

const smoothAnimationLeftToRight = keyframes`
   from { width: 0; }
  to { width: 100%; }
`;

const smoothAnimationOpacity = keyframes`
   from { opacity: 0.5; }
  to { opacity: 1; }
`;

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 17.5,
  },

  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        'linear-gradient(191.31deg, #A71DD4 8.33%, #8E34D5 95%);',

      // animation: `${smoothAnimationLeftToRight} 0.5s linear forwards`,
    },
    width: '100%',
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        'linear-gradient(191.31deg, #A71DD4 8.33%, #8E34D5 95%);',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 2,
    border: 0,
    backgroundColor: theme.palette.mode === 'dark' ? '#E6E6E6' : '#eaeaf0',
    borderRadius: 0,
    width: '109%',
    left: '-4px',
    position: 'absolute',
  },
}));

const ColorlibStepIconRoot = styled('div')<{
  ownerState: { completed?: boolean; active?: boolean };
}>(({ theme, ownerState }) => ({
  backgroundColor:
    theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#E6E6E6',
  zIndex: 1,
  color: ownerState.active || ownerState.completed ? '#fff' : "#8A8A8A",
  width: 35,
  height: 35,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  transition: 'all 2s ease-in-out',
  ...(ownerState.active && {
    backgroundImage: 'linear-gradient(191.31deg, #A71DD4 8.33%, #8E34D5 95%);',
    // animation: `${smoothAnimationOpacity} 0.5s linear`,
    // transition: `background-image 0.3s linear`,
    // animationFillMode: `forwards`,
    // animationDelay: '0.3s',
    // opacity: 0,
  }),
  ...(ownerState.completed && {
    backgroundImage: 'linear-gradient(191.31deg, #A71DD4 8.33%, #8E34D5 95%);',
  }),
}));

function ColorlibStepIcon(props: StepIconProps) {
  const { active, completed, className } = props;

  const icons: { [index: string]: React.ReactElement } = {
    1: <Typography>1</Typography>,
    2: <Typography>2</Typography>,
    3: <Typography>3</Typography>,
    4: <Typography>4</Typography>,
    5: <Typography>5</Typography>,
    6: <Typography>6</Typography>,
    7: <Typography>7</Typography>,
    8: <Typography>8</Typography>,
  };

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
      sx={{
        '& .Mui-active': {
          animation: `${smoothAnimationOpacity} 0.5s linear`,
          transition: `background-image 0.3s linear`,
          animationFillMode: `forwards`,
          animationDelay: '0.3s',
          opacity: 0,
        },
      }}
    >
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

export default function StepperMui({ activeStep, steps }: StepperProps) {
  const [previous, setPrevious] = useState<number>(0);
  const [isPrevious, setIsPrevious] = useState<boolean>(false);

  useEffect(() => {
    if (activeStep === previous) {
      setIsPrevious(true);
      if (activeStep <= 0) {
        setIsPrevious(false);
      }
    } else {
      setIsPrevious(false);
    }
    if (activeStep > 0) {
      setPrevious(activeStep - 1);
    } else {
      setPrevious(0);
    }
  }, [activeStep]);

  return (
    <Box sx={{ width: '100%' }}>
      <Stepper
        activeStep={activeStep}
        alternativeLabel
        connector={
          <ColorlibConnector
            sx={{
              [`&.${stepConnectorClasses.active}`]: {
                [`& .${stepConnectorClasses.line}`]: {
                  backgroundImage:
                    'linear-gradient(191.31deg, #A71DD4 8.33%, #8E34D5 95%);',
                  animation: isPrevious
                    ? ''
                    : `${smoothAnimationLeftToRight} 0.5s linear forwards`,
                  animationDirection: isPrevious ? '' : 'normal',
                },
              },
            }}
          />
        }
      >
        {steps.map((label, index) => (
          <Step key={`${label}-${index}`}>
            <StepLabel StepIconComponent={ColorlibStepIcon}>
              <Box
                sx={{
                  width: '98px',
                }}
              ></Box>
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
}
