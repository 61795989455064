import React, { useRef, useState } from "react";
import {
  Typography,
  Box,
  Theme,
  IconButton,
  Stack,
  Tooltip,
  Grow,
  Collapse,
  FormControl,
  MenuItem,
  Button,
  useMediaQuery,
} from "@mui/material";
import { Select, makeStyles, styled } from "@material-ui/core";
import logo from "../assets/logo.png";
import VolumeOffOutlinedIcon from "@mui/icons-material/VolumeOffOutlined";
import VolumeUpOutlinedIcon from "@mui/icons-material/VolumeUpOutlined";
import { useEffect } from "react";
import recruiter from "../assets/recruiter.jpg";
import BorderLinearProgressBar from "./BorderLinearProgressBar";
import LoadingAnimation from "./LoadingAnimation";
import { ReactComponent as SettingsIcon } from "../assets/setting.svg";
import { ReactComponent as CameraIcon } from "../assets/svg/Video.svg";
import { ReactComponent as VideoIcon } from "../assets/svg/Video1.svg";
import { ReactComponent as SpeakingOffIcon } from "../assets/svg/SpeakingOff.svg";
import { ReactComponent as AudioIcon } from "../assets/svg/Audio.svg";
import { ReactComponent as NoneBackIcon } from "../assets/svg/Block.svg";
import { withEmotionCache } from "@emotion/react";
import useInterviewContext from "../Context/mainContext";
import { ReactComponent as Handshake } from "../assets/svg/Handshake.svg";
import Slide from "@mui/material/Slide";
import { SelectChangeEvent } from "@mui/material/Select";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import { ReactComponent as SelectDownArrow } from "../assets/svg/selectDownArrow.svg";
import "./Video.css";
import { Colors } from "../theme/colors";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 420,
  // minHeight: '290px',
  bgcolor: "background.paper",
  // outline: `3px solid #3F7DD8`,
  // boxShadow: 24,
  borderRadius: "12px",
  boxShadow: "5px 5px 30px rgba(255, 255, 255, 0.5)",
  py: 5,
  px: 4,
};

const Bold = styled("b")({
  fontWeight: 500,
});
const useStyles = makeStyles((theme: Theme) => ({
  root: {},

  fontClass: {
    fontSize: "16px",
    fontWeight: 500,
  },
  fontClassSmall: {
    fontSize: "12px",
    fontWeight: 400,
  },
  videoContainer: {
    // width: '100%',
    // height: '100%',
    // position: 'relative',
    // border: `3px solid #ffffff`,
    // background: 'rgba(255, 255, 255, 0.3)',
    // backdropFilter: 'blur(40px)',
    // borderRadius: '0.7rem',
    // overflow: 'hidden',
    // boxSizing: 'border-box',
    // '&:-webkit-transform': 'translateZ(0)',
    // '&:-webkit-mask-image':
    //   '-webkit-radial-gradient(circle, white 100%, black 100%)',
    width: "100%",
    height: "100%",
    position: "relative",
    background: "#ffffff",
    border: `3px solid #3F7DD8`,
    borderRadius: "0.7rem",
    overflow: "hidden",
    boxSizing: "border-box",
    "&:-webkit-transform": "translateZ(0)",
    "&:-webkit-mask-image":
      "-webkit-radial-gradient(circle, white 100%, black 100%)",
  },
  video: {
    width: "100%",
    height: "100%",
    position: "absolute",
    // border: '4px solid red',
    // top: 0,
    // left: 0,
    objectFit: "cover",
    // borderRadius: '0.7rem',
  },
  videoCanvas: {
    width: "28%",
    height: "38%",
    position: "absolute",
    // border: '4px solid red',
    bottom: 10,
    right: 10,
    objectFit: "fill",
    borderRadius: "6px",
    // borderRadius: '0.7rem',
  },
  videoQuestions: {
    position: "relative",
    width: "100%",
    height: "100%",
    objectFit: "cover",
    clipPath: "polygon(0 0, 100% 0%, 85% 100%, 0% 100%)",
    // display:'none'
  },
  videoQuestionsNotAvailable: {
    width: "60%",
    height: "100%",
    // objectFit: 'cover',
    background: "#17C08E",
    clipPath: "polygon(0 0, 100% 0%, 85% 100%, 0% 100%)",
  },
  videoQuestionsImg: {
    position: "absolute",
    // display: 'hidden',
    top: 0,
    left: 0,
    width: "60%",
    height: "100%",
    objectFit: "cover",
    clipPath: "polygon(0 0, 100% 0%, 85% 100%, 0% 100%)",
    // zIndex:"-9999",
    // display:'none'
  },
  video11: {
    width: "100%",
    height: "100%",
    position: "absolute",
    // border: '4px solid red',
    // top: 0,
    // left: 0,
    objectFit: "cover",
    // borderRadius: '0.7rem',
  },
  recruiter: {
    height: "100%",
    width: "100%",
  },
  image: {
    height: "100%",
    width: "100%",
  },
  text: {
    color: "black",
  },
  volumeIcon: {
    cursor: "pointer",
    width: "44px",
    height: "44px",
    background: " #000000",
    position: "absolute",
    bottom: 10,
    display: "flex",
    justifyContent: "center",
    borderRadius: "10px",
    opacity: 0.8,
    zIndex: 1,
    "& svg": {
      width: "100%",
      height: "100%",
      padding: "8px",
    },
  },
  candidateInfo: {
    width: "40%",
    position: "absolute",
    // background: ' #000000',
    // left: 10,
    // top: 10,
    right: 10,
    top: 5,
    color: "white",
    borderRadius: "10px",
    padding: "10px",
    opacity: 0.8,
    zIndex: 1,
  },
  timerClass: {
    width: "12%",
    position: "absolute",
    background: " #000000",
    left: 10,
    top: 10,
    color: "white",
    borderRadius: "10px",
    padding: "8px",
    opacity: 0.8,
    zIndex: 1,
    display: "flex",
    justifyContent: "center",
  },
  settingOpen: {
    background: " #000000",
    position: "absolute",
    bottom: 10,
    right: 50,
  },
  colorBox: {
    width: "20px",
    height: "20px",
  },
  settingIcon: {
    position: "absolute",
    bottom: 10,
    left: 10,
  },
  colorBack: {
    height: "44px",
    position: "absolute",
    background: "#FFFFFF",
    bottom: 10,
    right: 110,
    padding: 10,
    display: "flex",
    borderRadius: "10px",
    // opacity: 0.8,
    alignItems: "center",
    color: "#FFFFFF",
  },
  backGroundColorIcon: {
    width: 16,
    height: 16,
    borderRadius: "50%",
    cursor: "pointer",
    zIndex: 1,
  },
  outerBox: {
    width: 20,
    height: 20,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "50%",
    marginLeft: "10px",
    marginRight: "10px",
    cursor: "pointer",
  },
}));
interface VideoProps {
  setTimeState: any;
  // displayVideo?: any;
  closingVideoRef?: any;
  initialVideoRef: any;

  // canvasRef: any;
  // interviewData: {
  //   candidate: any;
  //   enterprise: any;
  //   job: any;
  // };
}
let intervalId: any;

const Video = ({
  setTimeState,
  // displayVideo,
  closingVideoRef,
  initialVideoRef,
}: VideoProps) => {
  const classes = useStyles();
  let interviewState: any = useInterviewContext();
  const [openBackgroundSetting, setOpenBackgroundSetting] = useState(false);
  const [openCameraSettingModal, setOpenCameraSettingModal] = useState(false);
  const [todayDate, setTodayDate] = useState<any>();
  const [colorCode, setColorCode] = useState<any[]>([
    "#724CD6",
    "#FF0099",
    "#24FF00",
    "#4285F4",
    "#34A853",
    "#9CA7F8",
    "#A51ED4",
    "#EA4335",
    "#FFFFFF",
  ]);

  //states
  let myVideo = interviewState.myVideo;
  let newVideo = interviewState.questionVideoRef;
  let finalVideo = interviewState.finalVideo;
  let stream = interviewState.stream;
  let finalStream = interviewState.finalStream;
  let showSelfScreen = interviewState.showSelfScreen;
  let isRecordingOn = interviewState.isRecordingStarted;
  let isQuestionSpeakingGenerated = interviewState.speaking;
  let isInterviewFinished = interviewState.interviewStatus.isInterviewFinished;
  let uploadProgress = interviewState.uploadProgress;
  let isLoading = interviewState.isLoading;
  let setIsLoading = interviewState.setIsLoading;
  let canvasRef = interviewState.canvasOutputRef;
  let interviewData = interviewState.interviewData;
  let videosource = interviewState.videoArray;
  let isAiplaying = interviewState?.questionVideoRef?.current?.paused
    ? interviewState?.questionVideoRef?.current?.paused
    : false;
  const [recruiterAvtar, setRecruiterAvtar] = useState("Recruiter Name");
  const [captureModelImage, setCaptureModelImage] = useState<any>();
  let isQuestionSpeaking = interviewState.isQuestionSpeaking;

  const maxWidth1536 = useMediaQuery("(max-width: 1536px)");
  useEffect(() => {
    const name = "recruiter name";
    const spliteName = name.split(" ");
    const finalName = spliteName.map((item, index) => {
      return item.charAt(0);
    });
    setRecruiterAvtar(finalName.join(""));
  }, []);

  useEffect(() => {
    if (interviewState.synthesiaVideos.closingVideo === null) {
      // interviewState.setIsSynthesiaClosingPlaying(true);
      interviewState.setOpenPopup(true);
      interviewState.setClosingVideoPlay(false);
    }
  }, [interviewState.synthesiaVideos]);

  useEffect(() => {
    if (!finalStream && isInterviewFinished) {
      setIsLoading(false);
    }
  }, [isInterviewFinished]);
  // let clock = useRef<any>();

  let newVideoObject: any = null;
  let newAudioObject: any = null;

  const handleChangeVideo = (event: SelectChangeEvent) => {
    const id = event.target.value;
    const obj = interviewState.videoDevices.find(
      (vd: any) => vd.deviceId === id
    );
    newVideoObject = obj;

    interviewState.setCurrentVideo(obj);
    interviewState.onCameraAndAudioSettingSaveChange(obj);
  };

  // const handleChangeAudio = (event: SelectChangeEvent) => {
  //   const id = event.target.value;
  //   const obj = interviewState.audioDevices.find(
  //     (vd: any) => vd.deviceId === id
  //   );

  //   newAudioObject = obj;

  //   interviewState.setCurrentAudio(obj);
  //   interviewState.onCameraAndAudioSettingSave(obj);
  // };
  let videoLoad: any = document.getElementById("question-video");

  useEffect(() => {
    videoLoad = document.getElementById("question-video");
    if (videoLoad?.readyState > 0) {
      interviewState?.setIsLoadVideo(false);
    }
  }, [videosource, videoLoad?.readyState]);

  useEffect(() => {
    if (!!stream && !isInterviewFinished && interviewState.timerOff) {
      if (videoLoad?.readyState > 0) {
        interviewState?.setIsLoadVideo(false);
      }
    }
  }, [videoLoad?.readyState]);

  useEffect(() => {
    if (interviewState.timer > 0) {
      setTimeout(() => {
        interviewState.setTimer(interviewState.timer - 1);
      }, 1000);
    } else {
      interviewState.setTimerOff(true);
    }
  }, [interviewState.timer]);

  useEffect(() => {
    const startInterviewTimer = () => {
      let time = -1;

      function incrementTime() {
        if (
          !interviewState.interviewStatus.isInterviewFinished &&
          initialVideoRef?.current?.ended !== false
        ) {
          time++;
          interviewState.clockRef.current.innerText =
            // ("0" + Math.trunc(time / 3600)).slice(-2) +
            // " : " +
            ("0" + Math.trunc(time / 60)).slice(-2) +
            " : " +
            ("0" + (time % 60)).slice(-2);
        }

        setTimeState(interviewState.clockRef.current.innerText);
      }

      // incrementTime();
      interviewState.setTotalInterviewTime((prev: any) => ({
        ...prev,
        startTime: new Date(),
      }));

      intervalId = setInterval(incrementTime, 1000);
    };

    const stopInterviewTimer = () => {
      interviewState.setTotalTimeOfInterview(
        interviewState.clockRef.current?.innerText
      );

      interviewState.setTotalInterviewTime((prev: any) => ({
        ...prev,
        endTime: new Date(),
      }));

      clearInterval(intervalId);
      intervalId = null;
    };

    if (interviewState.isTimerOn && !!interviewState.clockRef.current) {
      startInterviewTimer();
    } else {
      stopInterviewTimer();
    }
    return () => {
      clearInterval(intervalId);
    };
  }, [
    interviewState.isTimerOn,
    !!interviewState.clockRef.current,
    initialVideoRef?.current?.ended !== false,
  ]);

  const handleClose = () => {
    setOpenCameraSettingModal(false);
    setOpenBackgroundSetting(false);
  };

  const handleVideoMetadataLoad = (
    event: React.SyntheticEvent<HTMLVideoElement, Event>
  ) => {
    const target = event.target as HTMLVideoElement;
    // interviewState.setVideoDuration(target.duration);
    // console.log('duration', target.duration);
  };

  useEffect(() => {
    const today = new Date();
    const options: any = { day: "2-digit", month: "long", year: "numeric" };

    // Format the date using toLocaleString
    const formattedDate = today.toLocaleString("en-US", options);
    setTodayDate(formattedDate);
  }, []);

  // useEffect(() => {
  //   interviewState?.questionVideoRef?.current?.load();
  // }, [videosource]);

  return (
    <Box className={classes.videoContainer}>
      {isLoading && interviewState.timerOff && (
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Stack
            spacing={2}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <LoadingAnimation />
            {/* <Typography>Wait for moment</Typography> */}
          </Stack>
        </Box>
      )}
      {!interviewState.timerOff && (
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Stack
            spacing={2}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              paddingLeft: 5,
            }}
          >
            <Typography sx={{ fontSize: "24px" }}>
              Your interview will start in <Bold>{interviewState.timer}</Bold>{" "}
              seconds
            </Typography>
            {/* <Typography>Wait for moment</Typography> */}
          </Stack>
        </Box>
      )}
      {/* {!!stream && !isInterviewFinished && (
        <>
          <video
            playsInline
            muted
            ref={myVideo}
            autoPlay
            className={classes.video}
            style={{
              display: "block",
            }}
            onLoadedMetadata={handleVideoMetadataLoad}
          />
        </>
      )}
      <video
        playsInline
        muted
        ref={finalVideo}
        autoPlay
        className={classes.video11}
        style={{
          display: `${!!stream && !isInterviewFinished ? "block" : "none"}`,
        }}
      />
      <canvas
        ref={canvasRef}
        className={classes.video}
        style={{
          // visibility: 'hidden',
          display: `${!!stream && !isInterviewFinished ? "block" : "none"}`,
        }}
      ></canvas> */}
      {!!stream && !isInterviewFinished && (
        <>
          <video
            playsInline
            muted
            ref={myVideo}
            autoPlay
            className={classes.video}
            style={{
              visibility: "hidden",
            }}
            onLoadedMetadata={handleVideoMetadataLoad}
          />
        </>
      )}
      <video
        playsInline
        muted
        ref={finalVideo}
        autoPlay
        className={classes.video11}
        style={{
          display: `${!!stream && !isInterviewFinished ? "block" : "none"}`,
        }}
      />
      {!!stream &&
        !isInterviewFinished &&
        interviewState.timerOff &&
        interviewState?.initialVideoPlay && (
          <div className={classes.videoQuestionsNotAvailable}>
            <video
              ref={initialVideoRef}
              src={
                interviewState.synthesiaVideos.introductionVideo
                  .SynthesiaVideo_BlobUrl
              }
              className={classes.videoQuestions}
              autoPlay
              id="intro-video"
              // onTimeUpdate={() => interviewState.setIsLoadVideo(false)}
            ></video>
          </div>
        )}
      {!!stream &&
        !isInterviewFinished &&
        interviewState.timerOff &&
        !interviewState?.initialVideoPlay && (
          <>
            {(interviewState?.isLoadVideo || videoLoad?.readyState === 0) &&
              interviewState.currentQuestionIndex > 0 && (
                <>
                  {/* {thumbnail && (<>
                <Typography sx={{ color: 'red' }}>
                  <img
                    ref={interviewState.questionVideoRef}
                    src={thumbnail}
                    className={classes.videoQuestionsImg}
                    id="video2"
                    // preload="true"
                    // onTimeUpdate={() => interviewState.setIsLoadVideo(false)}
                  ></img>
                </Typography>
              </>)} */}
                </>
              )}
            {videosource === null ||
            videosource === "" ||
            videosource === undefined ? (
              <>
                <div
                  className={classes.videoQuestionsNotAvailable}
                  style={{
                    textAlign: "center",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {/* No Question Video available */}
                </div>
              </>
            ) : (
              <>
                <div className={classes.videoQuestionsNotAvailable}>
                  {/* {interviewState?.initialVideoPlay ? (
                  <video
                    ref={initialVideoRef}
                    src={
                      interviewState.synthesiaVideos.introductionVideo
                        .SynthesiaVideo_BlobUrl
                    }
                    className={classes.videoQuestions}
                    autoPlay
                    id="intro-video"
                    // onTimeUpdate={() => interviewState.setIsLoadVideo(false)}
                  ></video>
                ) :  */}
                  {interviewState?.closingVideoPlay ? (
                    <video
                      src={
                        interviewState.synthesiaVideos.closingVideo
                          .SynthesiaVideo_BlobUrl
                      }
                      id="closingVideo"
                      autoPlay
                      ref={closingVideoRef}
                      style={{ width: "100%" }}
                    />
                  ) : (
                    <video
                      ref={interviewState.questionVideoRef}
                      src={videosource}
                      className={classes.videoQuestions}
                      autoPlay
                      id="question-video"
                      onTimeUpdate={() => interviewState.setIsLoadVideo(false)}
                    ></video>
                  )}
                </div>
              </>
            )}
          </>
        )}
      <canvas
        ref={canvasRef}
        className={classes.videoCanvas}
        style={{
          // visibility: 'hidden',
          display: `${
            !!stream && !isInterviewFinished && interviewState.timerOff
              ? "block"
              : "none"
          }`,
        }}
      ></canvas>
      {!!stream && !isLoading && interviewState.timerOff && (
        <>
          {/* <Tooltip title="Setting">
            <Box
              className={classes.volumeIcon}
              sx={{ right: 10 }}
              onClick={() => setOpenBackgroundSetting(!openBackgroundSetting)}
            >
              <SettingsIcon />
            </Box>
          </Tooltip> */}
          ​{/* open setting onClick */}
          <Grow
            in={openBackgroundSetting}
            style={{
              position: "absolute",
              right: "0",
              bottom: "0",
              transformOrigin: "0 0 0",
            }}
            {...(openBackgroundSetting ? { timeout: 400 } : { timeout: 400 })}
          >
            <Stack direction="row">
              <Tooltip title="Switch Camera">
                <Box
                  className={classes.volumeIcon}
                  sx={{ right: 110, bottom: "10" }}
                  onClick={() =>
                    setOpenCameraSettingModal(!openCameraSettingModal)
                  }
                >
                  <CameraIcon />
                </Box>
              </Tooltip>

              <Modal
                open={openCameraSettingModal}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{
                  background: "rgba(0, 0, 0, 0.5)",
                  backdropFilter: "blur(10px)",
                }}
              >
                <Box sx={style}>
                  <Box>
                    <IconButton
                      aria-label="close"
                      onClick={handleClose}
                      sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                    <Stack spacing={1}>
                      <Typography
                        color={"primary"}
                        sx={{ fontWeight: "400", fontSize: "15px" }}
                      >
                        Select Camera
                      </Typography>
                      {interviewState.videoDevices.length > 0 && (
                        <FormControl
                          fullWidth
                          sx={{
                            border: "1px solid #E6E6E6",
                            borderRadius: "5px",
                            // position: 'relative',
                          }}
                        >
                          <Select
                            // variant="filled"
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={
                              interviewState.currentVideo?.deviceId ??
                              interviewState.videoDevices[0]?.deviceId
                            }
                            onChange={(event: any) => handleChangeVideo(event)}
                            IconComponent={() => (
                              <Box sx={{ marginRight: "20px" }}>
                                <SelectDownArrow />
                              </Box>
                            )}
                          >
                            {interviewState.videoDevices.map(
                              (videoDevice: any, index: any) => {
                                return (
                                  <MenuItem
                                    value={videoDevice?.deviceId}
                                    key={index}
                                    sx={{}}
                                  >
                                    {videoDevice.label}
                                  </MenuItem>
                                );
                              }
                            )}
                          </Select>
                        </FormControl>
                      )}
                    </Stack>
                    <Stack>
                      <Button
                        variant="contained"
                        sx={{
                          background: "#2B7AFC",
                          borderRadius: "4px",
                          mt: "50px",
                        }}
                        onClick={() => handleClose()}
                      >
                        Save
                      </Button>
                    </Stack>
                  </Box>
                </Box>
              </Modal>
              <Tooltip
                title={
                  interviewState.speakingScreen
                    ? "Off speaking screen"
                    : "On speaking screen"
                }
              >
                <Box
                  className={classes.volumeIcon}
                  sx={{ right: 60, bottom: "10" }}
                  onClick={() => {
                    interviewState.setSpeakingScreen(
                      !interviewState.speakingScreen
                    );
                    setOpenBackgroundSetting(false);
                  }}
                >
                  {interviewState.speakingScreen ? (
                    <VideoIcon />
                  ) : (
                    <SpeakingOffIcon />
                  )}
                </Box>
              </Tooltip>
            </Stack>
          </Grow>
          <Box className={classes.candidateInfo}>
            <Box sx={{ color: Colors.primary, textAlign: "-webkit-right" }}>
              {/* <Box
                sx={{
                  width: { xs: "40px", sm: "40px", md: "60px", lg: "60px" },
                  height: { xs: "40px", sm: "40px", md: "60px", lg: "60px" },
                }}
              >
                <img
                  src={interviewState?.enterpriseData?.Enterprise_ImageLink}
                  alt="Company_logo"
                  style={{ width: "100%", height: "100%" }}
                />
              </Box> */}
              <Typography
                sx={{
                  fontSize: maxWidth1536 ? "20px" : "22px",
                  fontWeight: "500",
                  lineHeight: { xs: 1, sm: 1, md: 1, lg: 1.5 },
                }}
              >
                {interviewData.job.Campaign_Job_Title === "" ||
                interviewData.job.Campaign_Job_Title === null
                  ? "Job Title"
                  : interviewData.job.Campaign_Job_Title}
              </Typography>
              {/* <Typography>at tironem.ai</Typography> */}
            </Box>
            <Box
              sx={{
                textAlign: "end",
                mt: maxWidth1536 ? "5px" : "15px",
                color: "black",
              }}
            >
              <Typography
                sx={{
                  fontSize: { xs: "14px", sm: "14px", md: "18px", lg: "18px" },
                  fontWeight: "400",
                  lineHeight: { xs: 1, sm: 1, md: 1, lg: 1.5 },
                }}
              >
                {interviewData.candidate
                  ? `${interviewData.candidate?.CampaignLine_CandidateFirstName} ${interviewData.candidate?.CampaignLine_CandidateLastName}`
                  : "Candidate Name"}
              </Typography>
              <Typography
                sx={{
                  fontSize: { xs: "14px", sm: "14px", md: "16px", lg: "16px" },
                }}
              >
                {todayDate}
              </Typography>
            </Box>
          </Box>
          {/* <Box className={classes.candidateInfo}>
            <Typography sx={{ fontSize: '16px', fontWeight: '500' }}>
              {interviewData.candidate
                ? `${interviewData.candidate?.CampaignLine_CandidateFirstName} ${interviewData.candidate?.CampaignLine_CandidateLastName}`
                : 'Candidate Name'}
            </Typography>
            <Typography sx={{ fontSize: '12px', fontWeight: '400' }}>
              {interviewData.job.Campaign_Job_Title === '' ||
              interviewData.job.Campaign_Job_Title === null
                ? 'Job Title'
                : interviewData.job.Campaign_Job_Title}
            </Typography>
          </Box> */}
          <Box
            className={classes.timerClass}
            sx={{ width: { xs: "8%", sm: "8%", md: "12%", lg: "12%" } }}
          >
            <Typography
              ref={interviewState.clockRef}
              sx={{
                fontSize: { xs: "14px", sm: "14px", md: "16px", lg: "16px" },
              }}
            >
              {"00 : 00"}
            </Typography>
          </Box>
        </>
      )}
      ​{/* ? Recruiter Info (state: 2.5) */}
      {!!stream &&
        (isQuestionSpeaking || isQuestionSpeakingGenerated) &&
        interviewState.speakingScreen && (
          <Stack
            spacing={"20px"}
            sx={{
              position: "absolute",
              top: "0",
              width: "100%",
              height: "100%",
              backgroundColor: "#77A4E7",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              // opacity: '0.5',
            }}
          >
            <Box
              sx={{
                width: "100px",
                height: "100px",
                borderRadius: "115px",
                overflow: "hidden",
                backgroundColor: "#E1EDFF",
                color: "#724CD6",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  fontSize: "32px",
                  fontWeight: 600,
                  textTransform: "uppercase",
                }}
              >
                {recruiterAvtar}
              </Typography>
            </Box>
            <Stack
              spacing={"10px"}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography color={"white"}>
                {interviewState.recruiterName ?? "Recruiter Name"}
              </Typography>
              <Typography
                color={"white"}
                sx={{
                  fontSize: "11px",
                }}
              >
                The camera is still recording.
              </Typography>
            </Stack>
          </Stack>
        )}
    </Box>
  );
};

export default Video;
