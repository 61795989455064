import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

interface Props {
  open: boolean;
}

const PermissionDialog = ({ open }: Props) => {
  //   const handleClickOpen = () => {
  //     setOpen(true);
  //   };

  //   const handleClose = () => {
  //     setOpen(false);
  //   };

  return (
    <div>
      <Dialog
        open={open}
        // onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {'Please allow permission'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Please turn on your camera and mic to continue with the interview
          </DialogContentText>
        </DialogContent>
        {/* <DialogActions> */}
        {/* <Button onClick={handleClose}>Disagree</Button> */}
        {/* <Button onClick={handleClose} autoFocus>
            Close
          </Button>
        </DialogActions> */}
      </Dialog>
    </div>
  );
};

export default PermissionDialog;
