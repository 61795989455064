import React from 'react';
import { Container, Typography, Box, Stack } from '@mui/material';

type Props = {};

const NotFound = (props: Props) => {
  return (
    <Container
      sx={{
        position: 'relative',
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <Stack>
          <Typography
            variant={'h2'}
            sx={{
              color: '#a00000',
            }}
          >
            404 : Page Not Found
          </Typography>
        </Stack>
      </Box>
    </Container>
  );
};

export default NotFound;
