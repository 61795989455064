import { ComponentsOverrides } from '@mui/material/styles/overrides';
import { Colors } from './colors';

export const TextAreaFieldThemeStyles: {
  styleOverrides: ComponentsOverrides['MuiTextField'];
} = {
  styleOverrides: {
    root: {
      width: '100%',
      '& .MuiInputBase-root': {
        borderRadius: 5,
        fontSize: 16,
        lineHeight: '24px',
        textTransform: 'none',
        padding: 10,
        border: `1px solid ${Colors.dark_grey}`,
        '&:before, :after, :hover:not(.Mui-disabled):before': {
          borderBottom: 0,
        },
        '& .MuiOutlinedInput-notchedOutline': {
          border: 0,
        },
      },
    },
  },
};
