import React, { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Typography,
  Box,
  Theme,
  Stack,
  Button,
  useMediaQuery,
} from "@mui/material";
import { makeStyles } from "@material-ui/core";
import LoadingAnimation from "./LoadingAnimation";
import useInterviewContext from "../Context/mainContext";
import { useAudio } from "../hooks/useAudio";
import "./TextToSpeech.css";

const useStyles = makeStyles((theme: Theme) => ({
  // root: {
  //   display: 'flex',
  //   flexDirection: 'row',
  //   marginTop: 12,
  //   color: 'black',
  //   // alignItems: 'center',
  //   justifyContent: 'space-between',
  //   width: '960px',
  //   minHeight: '165px',
  //   padding: '20px',
  //   boxSizing: 'border-box',
  //   borderRadius: '21px',
  //   background: '#F5F5F5',
  // },

  // draw: {

  // },
  fontSize16SemiBold: {
    fontSize: "16px",
    fontWeight: 500,
    display: "flex",
    alignSelf: "center",
  },
  questionsContainer: {
    display: "flex",
    // flexDirection: 'row',
    // alignItems: 'center',
    flex: 1,
  },
  utilsContainer: {
    display: "flex",
    flexDirection: "row",
  },
  switchButton: {
    width: 30,
    height: 30,
    backgroundColor: "red",
    "&:hover": {
      backgroundColor: "#cf0a0a",
      cursor: "pointer",
    },
  },
  controlButton: {
    width: 30,
    height: 30,
    backgroundColor: "green",
    "&:hover": {
      backgroundColor: "#02660c",
      cursor: "pointer",
    },
  },
}));
interface TextToSpeechProps {
  timeForEachQuestion: number;
  setIsSilenceInQuestion: any;
  isSilenceInQuestion: any;
  closingVideoRef: any;
}
const TextToSpeech = ({
  timeForEachQuestion,
  setIsSilenceInQuestion,
  isSilenceInQuestion,
  closingVideoRef,
}: TextToSpeechProps) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const interviewState = useInterviewContext();
  const progressBar = useRef<any>();
  const numberOfQuestions = interviewState.questionsText?.length;
  let currentQuestion =
    interviewState.currentQuestion ??
    interviewState.questionsText[interviewState.currentQuestionIndex];
  const currentQuestionIndex = interviewState.currentQuestionIndex;
  const stream = interviewState.stream;
  const finalStream = interviewState.finalStream;
  const onToggleScreen = interviewState.onToggleScreen;
  const changeQuestion = interviewState.changeQuestion;
  const silence = interviewState.extendedSilence;
  const isRecordingOn = interviewState.isRecordingStarted;
  const endInterview = interviewState.stopRecording;
  const isQuestionSpeaking = interviewState.speaking;
  const isLoading = interviewState.isLoading;
  const setIsLoading = interviewState.setIsLoading;
  const audioPlay = interviewState.audioPlay;
  // interviewState.questionsAudio[interviewState.currentQuestionIndex];
  const isSplitVideo = interviewState.isSplitVideo;
  const recorder = interviewState.recorder;
  const saveSplitedVideo = interviewState.saveSplitedVideo;

  /*
   * ? Set progress bar timer
   */
  const [progress, setProgress] = React.useState(0);
  const maxWidth1536 = useMediaQuery("(max-width: 1536px)");

  // const { speak, speaking } = useSpeechSynthesis();
  // useEffect(() => {
  //   const timer = setInterval(() => {
  //     // if (isQuestionSpeaking === false) {
  //     if (
  //       !!silence &&
  //       !!stream &&
  //       !interviewState.isLoading &&
  //       interviewState.isRecordingStarted &&
  //       currentQuestion &&
  //       !interviewState.interviewStatus.isInterviewFinished
  //     ) {
  //       setProgress((prevProgress) =>
  //         prevProgress !== 100 ? prevProgress + 1 : 100
  //       );
  //     } else {
  //       setProgress(0);
  //     }
  //     // }
  //   }, timeForEachQuestion * 10);
  //   return () => {
  //     clearInterval(timer);
  //   };
  // }, [currentQuestionIndex, silence]);

  const [isQuestionPlaying, setAudioUrl] = useAudio(audioPlay);

  // useEffect(() => {
  //   if (currentQuestionIndex >= 0) {
  //     (async () => {
  //       // await audio?.play();
  //       if (audioPlay === "" || audioPlay === null || audioPlay === undefined) {
  //         // setTimeout(() => {
  //         //   interviewState.speak({ text: currentQuestion });
  //         // }, 1000);
  //       } else {
  //         // setAudioUrl(audioPlay);
  //       }
  //     })();
  //   }
  // }, [currentQuestionIndex]);

  useEffect(() => {
    interviewState.setIsQuestionSpeaking(isQuestionPlaying);
  }, [isQuestionPlaying]);

  useEffect(() => {
    // if (isQuestionPlaying || isQuestionSpeaking) {

    if (!interviewState.questionVideoRef?.current?.ended) {
      interviewState.setStoreTimeStamp((prev: any) => {
        let questionTimeData = {
          QuestionID:
            interviewState.questionsText[currentQuestionIndex]?.question_id,
          Q_number: currentQuestionIndex,
          CampaignLineID:
            interviewState.interviewData?.candidate?.pk_CampaignLine_ID,
          Question_StartTime: new Date(Date.now()),
          Question_EndTime: "",
          Answer_StartTime: "",
          Answer_EndTime: "",
        };

        if (currentQuestionIndex >= 1) {
          let previosQuestion = prev.map(
            (queData: any, indQuestion: number) => {
              if (queData.Q_number === currentQuestionIndex - 1) {
                return {
                  ...queData,
                  Answer_EndTime: new Date(Date.now()),
                };
              } else {
                return queData;
              }
            }
          );

          return [...previosQuestion, questionTimeData];
        } else {
          return [...prev, questionTimeData];
        }
      });
    }

    if (
      // (!isQuestionPlaying || !isQuestionSpeaking) &&
      (interviewState.questionVideoRef?.current?.ended ||
        interviewState.questionVideoRef?.current?.paused) &&
      interviewState.isRecordingStarted
    ) {
      interviewState.setStoreTimeStamp((prev: any) => {
        // if (prev.length > 0) {
        let previosQuestion = prev.map((queData: any, indQuestion: number) => {
          if (queData.Q_number === currentQuestionIndex) {
            return {
              ...queData,
              Question_EndTime: new Date(Date.now()),

              Answer_StartTime: new Date(Date.now()),
            };
          }

          return queData;
        });

        return [...previosQuestion];
        // } else {
        //   let questionTimeData = {
        //     QuestionID:
        //       interviewState.questionsText[currentQuestionIndex]?.question_id,
        //     Q_number: currentQuestionIndex,
        //     CampaignLineID:
        //       interviewState.interviewData?.candidate?.pk_CampaignLine_ID,
        //     // Question_StartTime: new Date(Date.now()),
        //     Question_EndTime: new Date(Date.now()),
        //     Answer_StartTime: new Date(Date.now()),
        //     Answer_EndTime: "",
        //   };
        //   return [...prev, questionTimeData];
        // }
      });
    }
    // }, [isQuestionPlaying, isQuestionSpeaking]);
  }, [
    // interviewState.isQuestionVideoPlay,
    interviewState.questionVideoRef?.current?.paused,
  ]);

  useEffect(() => {
    if (closingVideoRef?.current?.ended) {
      // endInterview();
      setIsLoading(true);
      interviewState.setClosingVideoPlay(false);
      interviewState.setStream(null);
      interviewState.setInterviewStatus((prev: any) => ({
        ...prev,
        isInterviewFinished: true,
      }));
      // setFinalStream(null);
      interviewState.setRecorder(null);
      interviewState.SetRecorderForEachQuestionRecord(null);

      // stop timer
      interviewState.setIsTimerOn(false);
      // interviewState.stopInterviewTimer();
      interviewState.setIsRecordingStarted(false);
      interviewState.setIsLoading(true);
    }
  }, [
    interviewState?.closingVideoPlay,
    closingVideoRef?.current?.ended === true,
  ]);

  useEffect(() => {
    // if (progress === 100 && isRecordingOn === true) {
    //   if (currentQuestionIndex >= numberOfQuestions - 1) {
    //     if (!interviewState?.closingVideoPlay) {
    //       interviewState.setClosingVideoPlay(true);
    //     } else {
    //       endInterview();
    //       setIsLoading(true);
    //     }
    //   } else {
    //     changeQuestion(currentQuestionIndex + 1);
    //     setProgress(0);
    //     setIsLoading(false);
    //   }
    // }
    let totalLength =
      progressBar.current?.offsetWidth * 2 +
      progressBar.current?.offsetHeight * 2;
    const changeBorder = (progressVal: any) => {
      const input = progressVal > 100 ? 100 : progressVal;
      let borderLen = (input / 100) * totalLength;
      if (borderLen <= progressBar.current?.offsetWidth) {
        let backgroundPos =
          "background-position: " +
          (-48 + borderLen) +
          "px 0px, 44px -48px, 48px 44px, 0px 48px";
        progressBar.current?.setAttribute("style", backgroundPos);
      } else if (
        borderLen <=
        progressBar.current?.offsetWidth + progressBar.current?.offsetHeight
      ) {
        let backgroundPos =
          "background-position: 0px 0px, 44px " +
          (-48 + (borderLen - progressBar.current?.offsetWidth)) +
          "px, 48px 44px, 0px 48px";
        progressBar.current?.setAttribute("style", backgroundPos);
      } else if (
        borderLen <=
        progressBar.current?.offsetWidth * 2 + progressBar.current?.offsetHeight
      ) {
        let backgroundPos =
          "background-position: 0px 0px, 44px 0px, " +
          (48 -
            (borderLen -
              progressBar.current?.offsetWidth -
              progressBar.current?.offsetHeight)) +
          "px 44px, 0px 48px";
        progressBar.current?.setAttribute("style", backgroundPos);
      } else {
        let backgroundPos =
          "background-position: 0px 0px, 44px 0px, 0px 44px, 0px " +
          (48 -
            (borderLen -
              progressBar.current?.offsetWidth * 2 -
              progressBar.current?.offsetHeight)) +
          "px";
        progressBar.current?.setAttribute("style", backgroundPos);
      }
    };

    changeBorder(progress);
  }, [progress]);

  useEffect(() => {
    if (isSilenceInQuestion[currentQuestionIndex]) {
      if (interviewState.noiseArray.length > 5) {
        setIsSilenceInQuestion((prev: any) => {
          let finalArr = [...prev];
          finalArr[currentQuestionIndex] = false;
          return finalArr;
        });
      }
    }
  }, [currentQuestionIndex, interviewState.noiseArray]);

  const handleChangeQuestion = (Index: number) => {
    if (isSplitVideo) {
      saveSplitedVideo();
    }
    if (Index >= numberOfQuestions) {
      var endInterviewTime = new Date(Date.now());

      interviewState.storeTimeStamp[
        interviewState.storeTimeStamp.length - 1
      ].Answer_EndTime = endInterviewTime;

      if (!interviewState?.closingVideoPlay) {
        endInterview();
        interviewState.setClosingVideoPlay(true);
      }
      // else {
      //   interviewState.setStream(null);
      //   interviewState.setInterviewStatus((prev: any) => ({
      //     ...prev,
      //     isInterviewFinished: true,
      //   }));
      //   // setFinalStream(null);
      //   interviewState.setRecorder(null);
      //   interviewState.SetRecorderForEachQuestionRecord(null);

      //   // stop timer
      //   interviewState.setIsTimerOn(false);
      //   interviewState.stopInterviewTimer();
      //   interviewState.setIsRecordingStarted(false);
      //   interviewState.setIsLoading(true);
      // }

      ////////////////////////////////////////
      // endInterview();
      // setIsLoading(true);
      ////////////////////////////////////////

      // interviewState.setStoreTimeStamp();
    } else {
      changeQuestion(Index);
      setProgress(0);
      setIsLoading(false);
    }
  };

  // progress bar
  return (
    <>
      {interviewState.timerOff && (
        <>
          <Box
            sx={{
              overflow: "hidden",
              borderRadius: "16px",
              marginRight: "9px",
            }}
          >
            <Box
              ref={progressBar}
              sx={{
                display: "flex",
                flexDirection: "row",
                margin: "1",
                color: "black",
                justifyContent: "space-between",
                width: maxWidth1536 ? "886px" : "886px",
                minHeight: "165px",
                boxSizing: "border-box",
                background: "#f5f5f5",
                borderRadius: "16px",
                // background: 'rgba(255, 255, 255, 0.3)',
                // backdropFilter: 'blur(40px)',

                "@media (max-width: 768px)": {
                  width: "100%",
                },
              }}
            >
              {!interviewState.interviewStatus.isInterviewFinished && (
                <Box
                  // className={`${
                  //   silence &&
                  //   interviewState.isRecordingStarted &&
                  //   currentQuestion
                  //     ? "draw2"
                  //     : "draw3"
                  // } `}
                  style={{ width: "100%" }}
                >
                  <Box>
                    {currentQuestionIndex !== -1 &&
                      currentQuestionIndex < numberOfQuestions &&
                      !isLoading &&
                      interviewState.timerOff && (
                        <Box
                          // className={classes.draw}
                          sx={{
                            position: "relative",
                            width: "100%",
                            paddingX: "60px",
                            "@media (max-width: 768px)": {
                              width: "100%",
                              display: "flex",
                              padding: "8px",
                              flexDirection: "column",
                            },
                          }}
                        >
                          <Stack spacing={"20px"}>
                            <Typography className={classes.fontSize16SemiBold}>
                              Question {currentQuestionIndex + 1}
                            </Typography>
                            <Typography
                              sx={{
                                color: "#000000",
                                "@media (max-width: 768px)": {
                                  width: "94%",
                                },
                              }}
                            >
                              {currentQuestion}
                            </Typography>
                          </Stack>
                          <Stack
                            direction="row"
                            spacing={50}
                            sx={{
                              width: "100%",
                              marginTop: "40px",
                              color: "#4285F4",
                              fontSize: "14px",
                              fontWeight: "400",

                              "@media (max-width: 768px)": {
                                marginTop: "10px",
                              },
                            }}
                          >
                            {/* <Box
                              sx={{
                                width: "100%",
                                display: "flex",
                                alignItems: "flex-start",
                                justifyContent: "flex-start",
                              }}
                            >
                              {currentQuestionIndex > 0 &&
                                isSilenceInQuestion[currentQuestionIndex] ===
                                  true && (
                                  <Button
                                    variant="text"
                                    fullWidth={false}
                                    onClick={() => {
                                      handleChangeQuestion(
                                        currentQuestionIndex - 1
                                      );
                                    }}
                                    sx={{
                                      m: "0",
                                      p: "0",
                                    }}
                                  >
                                    <Typography
                                      sx={{
                                        fontSize: "14px",
                                      }}
                                    >
                                      Previous Question
                                    </Typography>
                                  </Button>
                                )}
                            </Box> */}
                            <Box
                              sx={{
                                width: "100%",
                                display: "flex",
                                alignItems: "flex-end",
                                justifyContent: "flex-end",

                                "@media (max-width: 768px)": {
                                  width: "100%",
                                },
                              }}
                            >
                              {currentQuestionIndex < numberOfQuestions && (
                                <Button
                                  // disabled={
                                  //   // isQuestionPlaying || isQuestionSpeaking
                                  //   interviewState.isQuestionVideoPlay
                                  // }
                                  variant="text"
                                  fullWidth={false}
                                  sx={{
                                    m: "0",
                                    p: "0",
                                  }}
                                  onClick={() => {
                                    handleChangeQuestion(
                                      currentQuestionIndex + 1
                                    );
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      fontSize: "14px",
                                    }}
                                  >
                                    {currentQuestionIndex + 1 >=
                                    numberOfQuestions
                                      ? "Finish Interview"
                                      : "Next Question"}
                                  </Typography>
                                </Button>
                              )}
                            </Box>
                          </Stack>
                        </Box>
                      )}
                    {isLoading === true && (
                      <Box
                        sx={{
                          width: "100%",
                          // height: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <LoadingAnimation />
                      </Box>
                    )}
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
        </>
      )}
    </>
  );
};
export default TextToSpeech;
