import generalMiddleware, {
  axiosInstanceWithBase,
} from "./middleware/middleware";
import { Response } from "./middleware/format-response";

export const getCandidateDetails = (
  candidate_link = sessionStorage.getItem("candidate_link") || ""
): Promise<Response<string>> => {
  return generalMiddleware.request({
    url: `/CampaignLine/GetByCandidateLink?CampaignLine_CandidateLink=${candidate_link}`,
    method: "GET",
  });
};

export const getCampaignByCandidateId = (campaign_id: any, email: any) => {
  return generalMiddleware.request({
    url: `/CampaignLine/GetByCampaignID?campaign_id=${campaign_id}&useremailaddress=${email}`,
    method: "GET",
  });
};

export const getEnterpriseDetails = (id: number): Promise<Response<string>> =>
  generalMiddleware.request({
    url: `/Enterprise/${id}`,
    method: "GET",
  });

export const getEmployerDetails = (id: number): Promise<Response<string>> =>
  generalMiddleware.request({
    // url: `/Employer/${id}`,
    url: `/Employer/GetEmployerByEnterpirseID?ps=1&pn=1&id=${id}`,
    method: "GET",
  });

export const getJobDetails = (id: number): Promise<Response<string>> =>
  generalMiddleware.request({
    url: `/job/${id}`,
    method: "GET",
  });
export const getCandidateApi = (id: number): Promise<Response<string>> =>
  generalMiddleware.request({
    url: `/Campaign/${id}`,
    method: "GET",
  });

export const questionAudio = (
  candidate_link = sessionStorage.getItem("candidate_link") || ""
): Promise<Response<string>> => {
  return generalMiddleware.request({
    url: `/Question/GetQuestions?CampaignLine_CandidateLink=${candidate_link}`,
    method: "GET",
    // data: data,
  });
};

export const Invitation = (paramsData: any): Promise<Response<string>> =>
  generalMiddleware.request({
    url: `/Message/DontSendEmail`,
    method: "POST",
    data: paramsData,
  });

export const RecruiterData = (
  CampaignLine_ID: number
): Promise<Response<string>> => {
  return generalMiddleware.request({
    url: `/CampaignLine/GetRecruiterAndCampaignLine?CampaignLine_ID=${CampaignLine_ID}`,
    method: "GET",
  });
};
export const getEnterpriseData = (
  enterprise_id: number
): Promise<Response<string>> => {
  return generalMiddleware.request({
    url: `/Enterprise/${enterprise_id}`,
    method: "GET",
  });
};

export const companyInformation = (): Promise<Response<string>> => {
  return generalMiddleware.request({
    url: `/Snippet/Get/Company_Information`,
    method: "GET",
  });
};

export const InterviewInstruciton = (): Promise<Response<string>> => {
  return generalMiddleware.request({
    url: `/Snippet/Get/Interviewer_Wizard_Content_1`,
    method: "GET",
  });
};
export const getSynthesiaVideoById = (id: any): Promise<Response<string>> => {
  return generalMiddleware.request({
    url: `/Question/GetVideoUrl?question_SynthesiaVideoId=${id}`,
    method: "GET",
  });
};
export const getSynthesiaVideos = (data: any): Promise<Response<string>> => {
  return generalMiddleware.request({
    url: `/Synthesia/GetSynthesiaVideoIntegrationList?synthesiaVideoIntegration_FkId=${data.campaign_id}&synthesiaVideoIntegration_FkTable=${data.table_name}&synthesiaVideoIntegration_FieldName=${data.field_name}&fk_Enterprise_ID=${data.enterprise_id}`,
    method: "GET",
  });
};
