import React from "react";

import { Box, Button, Typography } from "@mui/material";
import AuthBackgroundImage from "../../assets/BackGroundImg.png";
import { useNavigate } from "react-router-dom";
import { logout } from "../../utils/ProtectedRoutes";
import Header from "../../components/Header";
import bg_top from "../../assets/bg_top.png";
import bg_bottom from "../../assets/bg_bottom.png";

const NotScheduled = () => {
  const navigate = useNavigate();
  return (
    <>
      <Box
        sx={{
          position: 'relative',
          background: 'white',
          height: '100vh',
          width: '100%',
          // position: 'relative',
          // height: '100vh',
          // width: '100%',
          // // minHeight: '750px',
          // overflow: 'hidden',
          // display: 'flex',
          // alignItems: 'center',
          // justifyContent: 'center',
          // flexDirection: 'column',
          // zIndex: 1,
        }}
      >
        <Box
          sx={{
            overflow: 'hidden',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            // background: 'rgba(255, 255, 255, 0.3)',
            // position: 'relative',
            // height: '90%',
            // width: '92%',
            // borderRadius: '20px',
            // border: '3px solid #FFFFFF',
            // backdropFilter: 'blur(40px)',
          }}
        >
          {/* <Box sx={{ position: 'absolute', top: '0', right: '0' }}>
            <img src={bg_top} />
          </Box>
          <Box sx={{ position: 'absolute', bottom: '0', left: '0' }}>
            <img src={bg_bottom} />
          </Box> */}
          <img
            src={AuthBackgroundImage}
            alt="AuthBackground_Image"
            style={{
              position: 'absolute',
              height: '100%',
              width: '100%',
              zIndex: 0,
            }}
          />
          <Header />
          <Box
            sx={{
              flexDirection: 'column',
              zIndex: 1,
              position: 'absolute',
              width: '100%',
              textAlign: 'center',
              paddingTop: '20%',
            }}
          >
            <Typography
              sx={{
                fontSize: '38px',
                fontWeight: '500',
              }}
            >
              Sorry !! Your Interview has not been scheduled yet
            </Typography>
            <Typography
              sx={{
                fontSize: '34px',
                fontWeight: '400',
                marginTop: '20px',
              }}
            >
              We will get back to you soon !!!!
            </Typography>
            <Box sx={{ mt: '50px' }}>
              <Button
                sx={{
                  background:
                    'linear-gradient(269.39deg, #6559D7 -3.77%, #AA19D5 78.96%)',
                  color: 'white',
                }}
                onClick={() => {
                  logout();
                  navigate('/login');
                }}
              >
                Back to login
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default NotScheduled;
