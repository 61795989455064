import * as React from 'react';
import { styled } from '@mui/material/styles';
import LinearProgress, {
  linearProgressClasses,
} from '@mui/material/LinearProgress';
import { Box } from '@mui/material';
import { colors } from '@mui/material';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    background:
      theme.palette.mode === 'light'
        ? 'linear-gradient(to right, #A71DD4, #7648D6) !important'
        : 'linear-gradient(to right,#A71DD4,#7648D6)',
  },
}));

type Props = {
  progress: number;
  type: 'determinate' | 'indeterminate' | 'buffer' | 'query' | undefined;
};

const BorderLinearProgressBar = ({ progress, type }: Props) => {
  const [isLoadingOn, setIsLoadingOn] = React.useState(true);
  React.useEffect(() => {
    if (progress === undefined || progress <= 0) {
      setIsLoadingOn(true);
    } else {
      setIsLoadingOn(false);
    }
  }, [progress]);

  return (
    <Box
      sx={{
        width: '100%',
      }}
    >
      {isLoadingOn ? (
          <LinearProgress
              sx={{
                height: 10,
                borderRadius: 10,
                backgroundColor: colors.grey['200'],
                "& .MuiLinearProgress-bar": {
                  background: "linear-gradient(to right,#A71DD4,#7648D6)",
                  borderRadius: 10
                }
              }}
          />
      ) : (
        <BorderLinearProgress variant={type} value={progress} />
      )}
    </Box>
  );
};

export default BorderLinearProgressBar;
