import { makeStyles } from '@material-ui/core';
import { Theme, useMediaQuery } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { ReactComponent as HeaderLogo } from '../assets/svg/MainHeaerLogo.svg';
import Tironem_LOGO from '../assets/TIRONEM_LOGO.png';
import LoadingAnimation from './LoadingAnimation';
import { Colors } from '../theme/colors';

const useStyles: any = makeStyles((theme: Theme) => ({
  HeaderMain: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'transparent',
    // margin: '3% 0%',
  },
}));

interface headerProps {
  image?: any;
  isLoadingHeader?: boolean;
  employer?: any;
}

const Header = ({ image, isLoadingHeader, employer }: headerProps) => {
  const classes = useStyles();
  const maxWidth1366 = useMediaQuery('(max-width: 1366px)');
  const maxWidth1536 = useMediaQuery('(max-width: 1536px)');
  const maxWidth936 = useMediaQuery('(max-width: 936px)');
  // const maxWidth = useMediaQuery('(max-width: 1366px)');

  const hasNoLinkRestriction = () => {
    return (
      employer?.Enterprise_ImageLink ===
      'https://tironem.blob.core.windows.net/candidate-image/70e8974a-c97d-409d-99a4-a7566590b7bc.jpg'
    );
  };

  return (
    <>
      {isLoadingHeader ? (
        <>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '150px',
              width: '150px',
              py: '20px',
            }}
          >
            <LoadingAnimation />
          </Box>
        </>
      ) : (
        <>
          <Box
            className={classes.HeaderMain}
            sx={{
              mt: maxWidth1536 ? '2vh' : '3vh',
              mb: '2vh',
            }}
          >
            <Box
              sx={{
                width: image ? '80px' : '100px',
                height: image ? '80px' : 'auto',
                flexDirection: 'row',
              }}
            >
              {/* {image && (
                <img
                  src={image ? image : Tironem_LOGO}
                  style={{ width: "100%", height: image ? "100%" : "" }}
                  alt="Logo"
                />
              )} */}

              {employer?.Enterprise_ImageLink !== undefined &&
                employer?.Enterprise_ImageLink !== '' &&
                !hasNoLinkRestriction() && (
                  <img
                    src={employer?.Enterprise_ImageLink}
                    style={{ width: '100%', height: image ? '100%' : '' }}
                    alt='Logo'
                  />
                )}

              {employer?.Enterprise_ImageLink !== undefined &&
                employer?.Enterprise_ImageLink !== '' &&
                hasNoLinkRestriction() && (
                  <img
                    src={employer?.Enterprise_ImageLink}
                    style={{ width: '100%', height: image ? '100%' : '' }}
                    alt='Logo'
                  />
                )}
              {!employer?.Enterprise_ImageLink &&
                (employer?.Employer_Name || employer?.Enterprise_Name) && (
                  <div
                    style={{
                      backgroundColor: `${Colors.Maximum_blue_purple}`,
                      height: 60,
                      width: 60,
                      borderRadius: 100,
                      marginRight: '6px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      textTransform: 'uppercase',
                      fontSize: '22px',
                      fontWeight: 500,
                      color: Colors.black,
                      margin: 20,
                    }}
                  >
                    {employer?.Enterprise_Initials ??
                      employer?.Employer_Initials ??
                      employer?.Employer_Name?.charAt(0) ??
                      employer?.Enterprise_Name?.charAt(0)}
                  </div>
                )}
            </Box>
            {!employer?.Enterprise_ImageLink && (
              <div
                style={{
                  fontSize: '22px',
                  fontWeight: 500,
                  color: Colors.black,
                }}
              >
                {employer?.Enterprise_Name}
              </div>
            )}
            {/* <HeaderLogo /> */}
          </Box>
        </>
      )}
    </>
  );
};

export default Header;
