import generalMiddleware from './middleware/middleware';
import { Response } from './middleware/format-response';
import { RegisterUser } from './modals/modals';

export const getOnboardingCandidateDetails = (
  candidateLink = ''
): Promise<Response<string>> =>
  generalMiddleware.request({
    url: `/CampaignLine/GetByInterviewLink?CampaignLine_CandidateLink=${candidateLink}`,
    method: 'GET',
  });

export const updateCandidateLine = (
  data: any
): Promise<Response<string>> =>
  generalMiddleware.request({
    url: '/CampaignLine',
    method: 'PUT',
    data: data,
  });
export const createCampaignLine = (
  data: any
): Promise<Response<string>> =>
  generalMiddleware.request({
    url: '/CampaignLine',
    method: 'POST',
    data: data,
  });

export const onboardingCampaignData = (id: any): Promise<Response<string>> =>
  generalMiddleware.request({
    url: `/CampaignLine/${id}`,
    method: 'GET',
  });

  export const getCampaignInterviewData = (
    candidate_link =''
  ): Promise<Response<string>> =>
    generalMiddleware.request({
      url: `/Campaign/GetByInterviewLink?Campaign_Job_Video_Link=${candidate_link}`,
      method: 'GET',
    });
  export const checkInterviewIsGiven = (
    email: string,
    campaignID: ''
  ): Promise<Response<string>> =>
    generalMiddleware.request({
      url: `/CampaignLine/isCandidateExists?email=${email}&campaignId=${campaignID}`,
      method: 'GET',
    });