import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

const ProtectedRoutes = ({ children, redirectTo }: any) => {
  const location = useLocation();
  const token = () => {
    const getToken = localStorage.getItem('token');
    return !!getToken;
  };

  return token() ? (
    children
  ) : (
    <Navigate to={redirectTo || '/login'} state={{ path: location.pathname }} />
  );
};

export const logout = () => {
  const getToken = localStorage.getItem('token');
  if (getToken) {
    // localStorage.removeItem('token');
    localStorage.clear();
    sessionStorage.clear();
  }
};

export default ProtectedRoutes;
