import { Box, Button, Chip, Stack, Typography } from "@mui/material";
import React, { useRef, useState } from "react";
import useInterviewContext from "../../../Context/mainContext";
import CloseIcon from "@mui/icons-material/Close";
import InsertDriveFileTwoToneIcon from "@mui/icons-material/InsertDriveFileTwoTone";
import { BASE_URL } from "../../../utils/config";
import axios from "axios";
import BorderLinearProgressBar from "../../../components/BorderLinearProgressBar";
import DeleteIcon from "@mui/icons-material/Delete";

interface IResumeProps {
  resumeFile?: any;
  setResumeFile: any;
  uploadProgressResume: any;
  setUploadProgressResume: any;
  uploadSuccesfull: any;
  setUploadSuccesfull: any;
}
const UploadResume = ({
  resumeFile,
  setResumeFile,
  uploadProgressResume,
  setUploadProgressResume,
  uploadSuccesfull,
  setUploadSuccesfull,
}: IResumeProps) => {
  const [dragActive, setDragActive] = React.useState(false);

  const uploadResumeFun = async (file: any) => {
    setUploadProgressResume(0);
    setUploadSuccesfull(false);
    const dataObj: any = {
      pk_CandidateResume_ID: 1,
      fk_User_ID: interviewState?.candidateData?.fk_User_ID,
      fk_CampaignLine_ID: interviewState?.candidateData?.pk_CampaignLine_ID,
      CandidateResume_ResumeFileUrl: "",
      CandidateResume_Deleted: 0,
      CandidateResume_AccessLevel: 1,
    };

    const resumeData = new FormData();
    resumeData.append("CandidateResume", JSON.stringify(dataObj));
    resumeData.append("", file);
    const accessToken = localStorage.getItem("token") || "";

    const headers = {
      "Content-Type": "application/octet-stream",
      Authorization: `Bearer ${accessToken}`,
    };
    const postResultUploadResume = await axios.post(
      `${BASE_URL}/CandidateResume/SaveResume`,
      resumeData,
      {
        headers: headers,
        onUploadProgress: (data: any) => {
          setUploadProgressResume((prev: any) => {
            let uploadProgressNumber =
              Math.round(((100 * data.loaded) / data.total) * 80) / 100;

            return uploadProgressNumber > prev ? uploadProgressNumber : prev;
          });
        },
      }
    );

    if (
      postResultUploadResume.status === 200 ||
      postResultUploadResume.status === 201
    ) {
      setUploadProgressResume(100);
      setUploadSuccesfull(true);
      // sendNotification({ msg: 'Interview Uploaded Successfully' });
    } else {
      setUploadSuccesfull(false);
      setResumeFile(null);
    }
  };
  const interviewState = useInterviewContext();
  const inputRef = useRef<any>(null);
  const handleDrag = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };
  const onButtonClick = () => {
    inputRef.current.click();
  };
  const handleDrop = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      setResumeFile(e.dataTransfer.files[0]);
      uploadResumeFun(e.dataTransfer.files[0]);
    }
  };
  const handleChange = (value: any) => {
    value.preventDefault();

    if (value.target.files && value.target.files[0]) {
      setResumeFile(value.target.files[0]);
      uploadResumeFun(value.target.files[0]);
    }
  };
  const removeFile = () => {
    setResumeFile(null);
    inputRef.current.value = "";
    setUploadProgressResume(0);
    setUploadSuccesfull(false);
  };

  return (
    <>
      <Stack
        direction="column"
        sx={{
          display: "flex",
          alignItems: "center",
          px: { xs: 0, sm: 0, md: 4, lg: 4 },
          mx: "20px",
        }}
      >
        <Typography sx={{ fontSize: "20px", fontWeight: "500", mt: "30px" }}>
          Upload Resume (Optional)
        </Typography>

        <Box
          sx={{
            mt: "30px",
            width: "100%",
            height: "180px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              width: "80%",
              display: "flex",
            }}
          >
            <Box
              sx={{
                background: "#f8f9ff",
                width: "100%",
                borderRadius: "10px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "10px",
              }}
            >
              <form
                id="form-file-upload"
                onDragEnter={handleDrag}
                onSubmit={(e) => e.preventDefault()}
                style={{
                  // background: '#f8f9ff',
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: "18px",
                  fontWeight: "500",
                  color: "#babee8",
                  cursor: "pointer",
                  maxWidth: "100%",
                  borderRadius: "10px",
                  textAlign: "center",
                  position: "relative",
                  border: "1px dashed #b0b0b0",
                }}
                onClick={onButtonClick}
              >
                <input
                  ref={inputRef}
                  type="file"
                  id="input-file-upload"
                  accept=".doc,.docx,.pdf,.docs"
                  multiple={false}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  style={{
                    display: "none",
                  }}
                />
                <label
                  id="label-file-upload"
                  htmlFor="input-file-upload"
                  className={dragActive ? "drag-active" : ""}
                >
                  <Box>
                    <Typography sx={{ fontSize: "18px" }}>
                      Drag and Drop Your Resume
                    </Typography>
                    <Button
                      className="upload-button"
                      sx={{
                        cursor: "pointer",
                        padding: "0.55rem",
                        fontSize: "0.80rem",
                        border: "none",
                        backgroundColor: "transparent",
                        color: "#babee8",
                      }}
                    >
                      Click to Upload a Resume
                    </Button>
                  </Box>
                </label>
                {dragActive && (
                  <Box
                    id="drag-file-element"
                    onDragEnter={handleDrag}
                    onDragLeave={handleDrag}
                    onDragOver={handleDrag}
                    onDrop={handleDrop}
                    style={{
                      position: "absolute",
                      width: "100%",
                      height: "100%",
                      borderRadius: "1rem",
                      top: "0px",
                      right: "0px",
                      bottom: "0px",
                      left: "0px",
                    }}
                  ></Box>
                )}
              </form>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            mt: "20px",
            alignItems: "center",
            justifyContent: "center",
            width: "80%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {resumeFile && (
              <>
                {/* <Chip sx={{width:"100%"}}>
                    <Stack direction={'row'} spacing={1}>
                      <Box>
                        <InsertDriveFileTwoToneIcon />
                      </Box>
                      <Typography>{resumeFile?.name}</Typography>
                    <Typography
                      sx={{ cursor: 'pointer' }}
                      onClick={() => {
                        removeFile();
                      }}
                      >
                      <CloseIcon />
                    </Typography>
                      </Stack>
                  </Chip>
                 */}
                <Chip
                  label={<>{resumeFile?.name}</>}
                  onDelete={removeFile}
                  deleteIcon={<DeleteIcon />}
                />
                <Box sx={{ width: "80%", mt: "15px" }}>
                  <BorderLinearProgressBar
                    progress={uploadProgressResume}
                    type="determinate"
                  />
                </Box>
                {uploadProgressResume > 0 && !uploadSuccesfull && (
                  <>
                    <Typography
                      sx={{ fontSize: "14px", mt: "10px", color: "#808380" }}
                    >
                      uploading...
                    </Typography>
                  </>
                )}

                {uploadSuccesfull && (
                  <>
                    <Typography
                      sx={{ fontSize: "14px", mt: "10px", color: "#808380" }}
                    >
                      Resume uploaded Successfully
                    </Typography>
                  </>
                )}
              </>
            )}
          </Box>
          {/* <Box>
            <Button onClick={() => onUploadFile()}>Upload</Button>
          </Box> */}
        </Box>
      </Stack>
    </>
  );
};

export default UploadResume;
