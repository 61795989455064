import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';

interface BaseResponse {
  status: number;
}

export interface SuccessfulResponse<T> extends BaseResponse {
  successful: true;
  data: T;
}

export interface FailedResponse extends BaseResponse {
  successful: false;
  message: string;
}

export type Response<T> = SuccessfulResponse<T> | FailedResponse;

export interface TypedAxiosInstance {
  request<T = any>(config: AxiosRequestConfig): Promise<Response<T>>;
}

export default function formatResponse(
  instance: AxiosInstance
): TypedAxiosInstance {
  instance.interceptors.response.use(
    (resolvedResponse: AxiosResponse<any>) => {
      return {
        data: resolvedResponse?.data,
        status: resolvedResponse.status,
        successful: true,
      };
    },
    (error) => {
      const { response } = error;

      return {
        status: response?.status || NaN,
        successful: false,
        message:
          response?.data?.response || response?.data?.error_description || null,
      };
    }
  );
  return instance;
}
