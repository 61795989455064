import { Box, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { getOnboardingCandidateDetails } from "../../api/onboarding";
import LoadingAnimation from "../../components/LoadingAnimation";
import useInterviewContext from "../../Context/mainContext";
import useToaster from "../../utils/Toaster";
import { useNavigate } from "react-router-dom";
import TIRONEM_LOGO from "../../assets/TIRONEM_LOGO.png";
import AuthBackgroundImage from "../../assets/BackGroundImg.png";
import bg_top from "../../assets/bg_top.png";
import bg_bottom from "../../assets/bg_bottom.png";
import { BASE_URL } from "../../utils/config";
import { getCampaignByCandidateId } from "../../api/questionAudio";

const InviteLinkPage = () => {
  const { id, eId } = useParams();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isValid, setIsValid] = useState(false);
  const [countdown, setCountdown] = useState(5);
  let interviewState: any = useInterviewContext();
  const [, sendNotification] = useToaster();
  const navigate = useNavigate();

  const base_url = window.location.origin;
  useEffect(() => {
    if (id && eId) {
      const tokenId = localStorage.getItem("token");
      if (tokenId) {
        localStorage.clear();
      }
      sessionStorage.setItem("candidate_link", "");
      sessionStorage.setItem("campaignInfoCode", id);
      sessionStorage.setItem("entInfoCode", eId);
      setTimeout(() => {
        return navigate("/signup");
      }, 8000);
    }
  }, [id, eId]);

  useEffect(() => {
    if (countdown > 0) {
      setTimeout(() => {
        setCountdown(countdown - 1);
      }, 1000);
    }
    // else {
    //   interviewState.setTimerOff(true);
    // }
  }, [countdown]);

  return (
    <>
      <Box
        sx={{
          position: "relative",
          background: "white",
          height: "100vh",
          width: "100vw",
          minHeight: "750px",
          overflow: "hidden",
          // display: "flex",
          // justifyContent: "center",
          // alignItems: "center"
        }}
      >
        {/* <Box
          sx={{
            background: 'rgba(255, 255, 255, 0.3)',
            position: 'relative',
            height: '90%',
            width: '92%',
            borderRadius: '20px',
            border: '3px solid #FFFFFF',
            backdropFilter: 'blur(40px)',
          }}
        >
          <Box sx={{ position: 'absolute', top: '0', right: '0' }}>
            <img src={bg_top} />
          </Box>
          <Box sx={{ position: 'absolute', bottom: '0', left: '0' }}>
            <img src={bg_bottom} />
          </Box> */}
        <img
          src={AuthBackgroundImage}
          alt="AuthBackground_Image"
          style={{
            position: "absolute",
            height: "100%",
            width: "100%",
          }}
        />
        {/* header */}
        <div
          style={{
            marginTop: "65px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <img
            src={TIRONEM_LOGO}
            alt="TIRONEM_logo"
            style={{
              width: "216px",
              height: "49px",
            }}
          />
        </div>{" "}
        <Box></Box>
        {!isLoading && (
          <Box
            sx={{
              height: "72vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Box>
              <LoadingAnimation />
            </Box>
            {isValid && (
              <>
                <Typography
                  sx={{
                    fontSize: "1.4rem",
                    fontWeight: "400",
                    marginTop: "10px",
                  }}
                >
                  Interview Link is either expired or not valid!
                </Typography>
                <Typography
                  sx={{
                    fontSize: "1.4rem",
                    fontWeight: "400",
                    marginTop: "10px",
                  }}
                >
                  You will be redirect to the login page in {countdown} seconds
                </Typography>
              </>
            )}
          </Box>
        )}
        {/* </Box> */}
      </Box>
    </>
  );
};
export default InviteLinkPage;
