import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { RecordRTCPromisesHandler, WebAssemblyRecorder } from "recordrtc";
import { useSpeechSynthesis } from "react-speech-kit";
import { saveAs } from "file-saver";
import {
  createInterviewVideoBlob,
  saveEachQuestionAnswer,
  storeTimeStampApi,
  submitInterview,
} from "../api/saveVideoBlob";
import axios from "axios";
import { BASE_URL } from "../utils/config";
// initialize of main context
const MainContext = createContext<any>(null);

let intervalId: any;

type Props = {
  children: any;
};

// provider of MainContext
export const InterviewProvider = ({ children }: Props) => {
  const [recorder, setRecorder] = useState<
    RecordRTCPromisesHandler | null | any
  >();
  const [recorderForEachQuestionRecord, SetRecorderForEachQuestionRecord] =
    useState<RecordRTCPromisesHandler | null>();
  const [stream, setStream] = useState<MediaStream | null>();
  const [finalStream, setFinalStream] = useState<MediaStream | null>();
  // set boolean if finalStream start
  const [interviewStatus, setInterviewStatus] = useState<{
    isInterviewStarted: boolean;
    isInterviewFinished: boolean;
  }>({
    isInterviewStarted: false,
    isInterviewFinished: false,
  });

  const [videoBlob, setVideoBlob] = useState<Blob>();

  const [currentQuestion, setCurrentQuestion] = useState<string>();
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState<number>(-1);
  // states to manage the silence and debounce functionality
  const [noiseArray, setNoiseArray] = useState<number[]>([]);
  const [extendedSilence, setExtendedSilence] = useState<boolean>(false);
  const [extendedSilenceArray, setExtendedSilenceArray] = useState<number[]>(
    []
  );

  const [thumbnailImg, setThumbnailImg] = useState<File>();

  const [showSelfScreen, setShowSelfScreen] = useState<boolean>(true);

  //// check whether recording is started ? ////
  const [isRecordingStarted, setIsRecordingStarted] = useState<boolean>(false);

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [isTimerOn, setIsTimerOn] = useState<boolean>(false);

  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const [audioPlay, setAudioPlay] = useState<Blob>();
  const [isCameraAndMicSettingOpen, setIsCameraAndMicSettingOpen] =
    useState<boolean>(false);
  const [recruiterName, setRecruiterName] = useState<string>();
  const [timerOff, setTimerOff] = useState<boolean>(false);

  const [totalInterviewTime, setTotalInterviewTime] = useState<{
    startTime: Date;
    endTime: Date;
  }>({
    startTime: new Date(),
    endTime: new Date(),
  });

  const [isInitState, setIsInitState] = useState<boolean>(true);
  const [audioDevices, setAudioDevices] = useState<any[]>([]);
  const [currentAudio, setCurrentAudio] = useState<any>();
  const [videoDevices, setVideoDevices] = useState<any[]>([]);
  const [currentVideo, setCurrentVideo] = useState<any>();
  const [isSplitVideo, setIsSplitVideo] = useState<boolean>(false);
  const [blobInterval, setBlobInterval] = useState<any[]>([]);
  const [interviewUploadSuccessfull, setInterviewUploadSuccessfull] =
    useState<boolean>(false);

  // for store time stamp of questions and answers
  const [storeTimeStamp, setStoreTimeStamp] = useState<any>([]);
  const [interviewData, setInterviewData] = useState<any>({
    candidate: {},
    enterprise: {},
    job: {},
  });
  const [onboardingCandidateData, setOnboardingCandidateData] = useState<any>(
    {}
  );
  const [candidateData, setCandidateData] = useState<any>();
  const [enterpriseData, setEnterpriseData] = useState<any>();
  const [candidateLinkId, setCandidateLinkId] = useState<any>(null);
  // const [onboardingCampaignData, setOnboardingCampaignData] =
  //   useState<any>(null);
  const [jobData, setJobData] = useState<any>({
    jobTitle: "",
    jobDescription: "",
  });

  const [openPermissionDialog, setOpenPermissionDialog] =
    useState<boolean>(false);
  const [isVideoBackgroundEnabled, setIsVideoBackgroundEnabled] =
    useState<Boolean | null>(false);
  const [videoBackgroundConfig, setVideoBackgroundConfig] = useState({
    isImgBackground: false,
    videoBackgroundColor: "#ffffff",
  });
  const myVideo = useRef<any>();
  const finalVideo = useRef<any>();

  // const classes = useStyles();
  const [isQuestionSpeaking, setIsQuestionSpeaking] = useState<any>(false);
  const { speak, speaking } = useSpeechSynthesis();

  const [questionsText, setQuestionsText] = useState<any[]>([]);

  const [questionsAudio, setQuestionsAudio] = useState<any[]>([]);
  const [tempCurrentVideo, setTempCurrentVideo] = useState<any>();
  const [tempCurrentAudio, setTempCurrentAudio] = useState<any>();
  const [speakingScreen, setSpeakingScreen] = useState<any>(false);
  const [timer, setTimer] = useState<number>(3);
  const [timeState, setTimeState] = useState<any>("");
  const [activeStreams, setActiveStreams] = useState<MediaStream[]>([]);
  const [lastQuestionApiResponse, setLastQuestionApiResponse] =
    useState<boolean>(false);
  const [interviewAudioResponse, setInterviewAudioResponse] =
    useState<boolean>(false);
  const [videoArray, setVideoArray] = useState();
  const [synthesiaVideoLinkArray, setSynthesiaVideoLinkArray] = useState<any[]>(
    []
  );
  const [isLoadVideo, setIsLoadVideo] = useState<boolean>(true);
  const [synthesiaVideos, setSynthesiaVideos] = useState<any>({
    engageVideo: null,
    introductionVideo: null,
    closingVideo: null,
  });

  const [initialVideoPlay, setInitialVideoPlay] = useState<boolean>(true);
  const [closingVideoPlay, setClosingVideoPlay] = useState<boolean>(false);
  const [openPopup, setOpenPopup] = useState<boolean>(false);
  const [isSynthesiaClosingPlaying, setIsSynthesiaClosingPlaying] =
    useState<boolean>(false);

  // const abc: any = [
  //   'https://tironem.blob.core.windows.net/interviewvideos/4c37be0d-e174-4193-a1af-e96bb91db330.webm',
  //   'https://tironem.blob.core.windows.net/interviewvideos/1aed6ea6-3ad4-43fa-b199-9846d97468e0.webm',
  //   'https://tironem.blob.core.windows.net/interviewvideos/4c37be0d-e174-4193-a1af-e96bb91db330.webm',
  //   'https://tironem.blob.core.windows.net/interviewvideos/1aed6ea6-3ad4-43fa-b199-9846d97468e0.webm',
  //   'https://tironem.blob.core.windows.net/interviewvideos/4c37be0d-e174-4193-a1af-e96bb91db330.webm',
  // ];
  const questionVideoRef = useRef<any>();

  const [isQuestionVideoPlay, setIsQuestionVideoPlay] =
    useState<boolean>(false);

  useEffect(() => {
    if (questionVideoRef?.current) {
      if (!questionVideoRef?.current?.ended) {
        setIsQuestionVideoPlay(true);
      } else {
        setIsQuestionVideoPlay(false);
      }
      // if (questionVideoRef?.current?.paused) {
      //   startAudioRecorder();
      // }
    }
  }, [questionVideoRef?.current?.paused]);

  useEffect(() => {
    if (questionVideoRef?.current?.ended) {
      startAudioRecorder();
    }
    // else {
    //   stopAudioRecorder();
    // }
  }, [questionVideoRef?.current?.ended]);

  const startAudioRecorder = async () => {
    recorderForEachQuestionRecord?.startRecording();
    // if (response) {
    //   console.log("started 2", recorderForEachQuestionRecord);
    // SetRecorderForEachQuestionRecord(recorderForEachQuestionRecord);
    // }
  };

  // const stopAudioRecorder = async () => {
  //   console.log("stopped", recorderForEachQuestionRecord);
  //   const response = await recorderForEachQuestionRecord?.stopRecording();
  //   if (response) {
  //     console.log("stopped 2");
  //     SetRecorderForEachQuestionRecord(null);
  //   }
  // };

  const canvasRef = useRef<HTMLCanvasElement | null>(null);

  const canvasOutputRef = useRef<any>();

  const canvasCtx: any = canvasOutputRef.current?.getContext("2d");

  const logoutState = () => {
    setInterviewStatus({
      isInterviewStarted: false,
      isInterviewFinished: false,
    });
    // setVideoBackgroundConfig({
    //   videoBackgroundColor: '#ffffff',
    // });

    setIsInitState(true);
    setInterviewUploadSuccessfull(false);
    setIsVideoBackgroundEnabled(false);
    setCurrentQuestionIndex(-1);
    setCurrentQuestion(undefined);
  };

  // create audio blob of each question-answer

  useEffect(() => {
    if (interviewAudioResponse && lastQuestionApiResponse) {
      setUploadProgress(100);
      // const EndInterview = submitInterview(
      //   interviewData?.candidate?.pk_CampaignLine_ID
      // );
      setInterviewUploadSuccessfull(true);
    }
  }, [interviewAudioResponse, lastQuestionApiResponse]);

  // Function to trim silence from the start of an AudioBuffer
  function trimSilence(audioBuffer) {
    const threshold = 0.01; // Adjust this threshold based on your audio characteristics
    const channelData = audioBuffer.getChannelData(0); // Assuming mono audio

    // Find the first non-silent sample
    let startIndex = 0;
    for (let i = 0; i < channelData.length; i++) {
      if (Math.abs(channelData[i]) > threshold) {
        startIndex = i;
        break;
      }
    }

    // Calculate the new duration after trimming
    const newDuration =
      audioBuffer.duration - startIndex / audioBuffer.sampleRate;

    // Create a new AudioBuffer with the trimmed data
    const audioContext = new (window.AudioContext ||
      window.webkitAudioContext)();
    const trimmedBuffer = audioContext.createBuffer(
      1, // Number of channels (assuming mono audio)
      Math.ceil(newDuration * audioBuffer.sampleRate), // New buffer length
      audioBuffer.sampleRate
    );

    // Copy the trimmed data to the new buffer
    const newData = trimmedBuffer.getChannelData(0);
    for (let j = 0; j < newData.length; j++) {
      newData[j] = channelData[j + startIndex];
    }

    return trimmedBuffer;
  }

  const storeEachQuestionsBlob = async (flag: boolean) => {
    if (!isQuestionSpeaking && recorderForEachQuestionRecord) {
      recorderForEachQuestionRecord?.stopRecording();
    }
    setTimeout(async () => {
      if (!isQuestionSpeaking) {
        let blob = await recorderForEachQuestionRecord?.getBlob();

        const saveAudioFormData = new FormData();

        let isBlobGet = false;
        let audioBlob: Blob;
        const audioCtx = new AudioContext();
        const fileReader = new FileReader();

        // initialize the formData
        let audioDataObj = {
          QuestionResponse_QuestionText: currentQuestion,
          fk_CampaignLine_ID: interviewData?.candidate?.pk_CampaignLine_ID,
          fk_Question_ID: questionsText[currentQuestionIndex]?.question_id,
          QuestionResponse_AccessLevel:
            questionsText[currentQuestionIndex]?.Question_AccessLevel,
          QuestionResponse_Status:
            questionsText[currentQuestionIndex]?.Question_Status,
        };

        saveAudioFormData.append(
          "QuestionResponse",
          JSON.stringify(audioDataObj)
        );

        if (blob) {
          fileReader.readAsArrayBuffer(blob);
          fileReader.onloadend = async () => {
            const audioData = (await fileReader.result) as ArrayBuffer;
            await audioCtx.decodeAudioData(audioData, async (audioBuffer) => {
              const trimmedAudioBuffer = trimSilence(audioBuffer);
              const wavData = convertToWAV(trimmedAudioBuffer);
              audioBlob = new Blob([wavData], { type: "audio/wav" });
              saveAudioFormData.append("Audio", audioBlob);
              // var blobURL = window.URL.createObjectURL(audioBlob);
              // var audio0 = new Audio(blobURL);
              // audio0.play();
              // console.log("audioBlob", audioBlob);
              isBlobGet = true;
              // saveAs(audioBlob, `Audio-${Date.now()}.wav`);

              const response: any = await saveEachQuestionAnswer(
                saveAudioFormData
              );
              // console.log('storeTimeStamp',storeTimeStamp)
              const storeDataFiltered = [
                ...storeTimeStamp
                  .reduce((map, obj) => map.set(obj.Q_number, obj), new Map())
                  .values(),
              ];
              if (response.status === 201 || response.status === 200) {
                isBlobGet = true;
                if (flag === true) {
                  await storeDataFiltered.map(
                    (allItems: any, index: number) => delete allItems.Q_number
                  );

                  // if (questionResponseTime) {
                  const responseTimeStamp = await storeTimeStampApi([
                    ...new Map(
                      storeDataFiltered.map((item) => [
                        item["QuestionID"],
                        item,
                      ])
                    ).values(),
                  ]);
                  setLastQuestionApiResponse(true);
                  if (
                    responseTimeStamp.status === 200 ||
                    responseTimeStamp.status === 201
                  ) {
                    setTimeout(async () => {
                      // setLastQuestionApiResponse(true);
                      // const EndInterview = await submitInterview(
                      //   interviewData?.candidate?.pk_CampaignLine_ID
                      // );
                    }, 50000);
                  }
                  await recorderForEachQuestionRecord?.startRecording();
                  SetRecorderForEachQuestionRecord(
                    recorderForEachQuestionRecord
                  );
                  // }
                }
              }
            });
          };
        }

        SetRecorderForEachQuestionRecord(recorderForEachQuestionRecord);
      }
    }, 500);
  };

  const changeQuestion = async (index: any): void => {
    setAudioPlay(questionsAudio[index]);

    setCurrentQuestion(questionsText[index].question_test);
    setCurrentQuestionIndex(index);
    setNoiseArray([]);
    setVideoArray(synthesiaVideoLinkArray[index]);
    storeEachQuestionsBlob(false);
    setIsLoadVideo(true);

    // speak({ text: QUESTIONS[index] });
    // const audioContext = new AudioContext();
    // let audio = new Audio(questionsAudio[index])
    // const audioSrc = audioContext.createMediaElementSource(audio);
    // const audioStream = audioSrc.mediaElement;

    // // Create a new media stream with the audio track
    // if(audioStream !== null || audioStream !== undefined) {
    //   // const mediaStream = new MediaStream([audioStream.getAudioTracks()[0]]);
    //   finalStream.addTrack(audioStream.getAudioTracks()[0]);  // Add the audio track to the original stream
    // }
  };

  const getAvtarImageFromInterviewVideo = async () => {
    let file;
    canvasRef.current
      ?.getContext("2d")
      ?.drawImage(
        myVideo.current,
        0,
        0,
        canvasRef.current?.width,
        canvasRef.current?.height
      );

    canvasRef.current?.toBlob(async (blob: any) => {
      file = new File([blob], "test.png", { type: "image/png" });
      setThumbnailImg(file);
    }, "image/png");
  };

  // To call APIS

  function stopMediaTracks(stream: any) {
    stream?.getTracks().forEach((track: any) => {
      track.stop();
    });
  }
  function stopMediaVideoTracks() {
    activeStreams.forEach((streamItem) => {
      streamItem?.getTracks().forEach((track) => {
        // if (track.kind == 'video') {
        track.stop();
        // }
      });
    });
  }

  function getAudioVideoConfigConstraints1(obj: any) {
    let currentAudioNew = null;
    let currentVideoNew = null;
    const videoConstraints: any = {};
    const audioConstraints: any = {};
    if (obj.kind === "videoinput") {
      currentVideoNew = obj;
      videoConstraints.deviceId = { exact: currentVideoNew.deviceId };
      if (currentAudio) {
        audioConstraints.deviceId = { exact: currentAudio.deviceId };
      } else {
        audioConstraints.deviceId = "environment";
      }
    }
    if (obj.kind === "audioinput") {
      currentAudioNew = obj;
      audioConstraints.deviceId = { exact: currentAudioNew.deviceId };
      if (currentVideo) {
        videoConstraints.deviceId = { exact: currentVideo.deviceId };
      } else {
        videoConstraints.facingMode = "user";
      }
    }
    if (obj === "assigned") {
      if (currentVideo == null || currentVideo == undefined) {
        videoConstraints.facingMode = "user";
      } else {
        videoConstraints.deviceId = { exact: currentVideo.deviceId };
      }

      if (currentAudio == null || currentAudio == undefined) {
        audioConstraints.deviceId = "environment";
      } else {
        audioConstraints.deviceId = { exact: currentAudio.deviceId };
      }
    }

    return {
      video: videoConstraints,
      audio: audioConstraints,
    };
  }
  function getAudioVideoConfigConstraintsChange(obj: any) {
    let currentAudioNew = null;
    let currentVideoNew = null;
    const videoConstraints: any = {};
    const audioConstraints: any = {};

    const audioVideoData: any = JSON?.parse(
      localStorage.getItem("Video_Audio") || ""
    );

    if (obj.kind === "videoinput") {
      currentVideoNew = obj;
      videoConstraints.deviceId = { exact: currentVideoNew.deviceId };
      audioConstraints.deviceId = { exact: audioVideoData?.audio?.deviceId };
    }

    if (obj === "assigned") {
      if (currentVideo == null || currentVideo == undefined) {
        videoConstraints.facingMode = "user";
      } else {
        videoConstraints.deviceId = { exact: currentVideo.deviceId };
      }

      // if (currentAudio == null || currentAudio == undefined) {
      //   audioConstraints.deviceId = 'environment';
      // } else {
      //   audioConstraints.deviceId = { exact: currentAudio.deviceId };
      // }
    }

    const constraints = {
      video: videoConstraints,
      audio: audioConstraints,
    };
    const audio_video_constraints = {
      audio: currentAudio,
      video: obj,
    };
    localStorage.setItem(
      "Video_Audio",
      JSON.stringify(audio_video_constraints)
    );
    return constraints;
  }

  function getAudioVideoConfigConstraints() {
    const videoConstraints: any = {};
    const audioConstraints: any = {};

    const audioVideoData: any =
      JSON?.parse(localStorage?.getItem("Video_Audio")) || "";

    // let selectedAudio = null
    // let selectedVideo = null
    if (audioVideoData.video === null || audioVideoData.video === undefined) {
      videoConstraints.facingMode = "user";
    } else {
      videoConstraints.deviceId = { exact: audioVideoData.video.deviceId };
    }

    if (audioVideoData.audio == null) {
      audioConstraints.deviceId = "environment";
    } else {
      audioConstraints.deviceId = { exact: audioVideoData.audio.deviceId };
    }

    return {
      video: videoConstraints,
      audio: audioConstraints,
    };
  }

  const onCameraAndAudioSettingSaveChange = (obj: any) => {
    let constraints = getAudioVideoConfigConstraintsChange(obj);

    navigator.mediaDevices
      .getUserMedia(constraints)
      .then((stream) => {
        setStream(stream);
        setActiveStreams((prev) => [...prev, stream]);
        setVideoBlob(undefined);
        myVideo.current.srcObject = stream;
        // setIsLoading(false);
        return navigator.mediaDevices.enumerateDevices();
      })
      .then((devices) => {
        setAudioDevices([]);
        setVideoDevices([]);
        devices.forEach((device) => {
          if (device.kind == "audioinput" && device.label)
            setAudioDevices((prev: any) => [...prev, device]);

          if (device.kind == "videoinput" && device.label)
            setVideoDevices((prev: any) => [...prev, device]);
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const onCameraAndAudioSettingSave = (obj: any) => {
    if (stream === null || stream === undefined) {
    } else {
      stopMediaTracks(stream);
    }
    let constraints = getAudioVideoConfigConstraints1(obj);
    navigator.mediaDevices
      .getUserMedia(constraints)
      .then((stream) => {
        setStream(stream);
        setActiveStreams((prev) => [...prev, stream]);
        setVideoBlob(undefined);
        myVideo.current.srcObject = stream;
        // setIsLoading(false);
        return navigator.mediaDevices.enumerateDevices();
      })
      .then((devices) => {
        setAudioDevices([]);
        setVideoDevices([]);
        devices.forEach((device) => {
          if (device.kind == "audioinput" && device.label)
            setAudioDevices((prev: any) => [...prev, device]);

          if (device.kind == "videoinput" && device.label)
            setVideoDevices((prev: any) => [...prev, device]);
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  // ref for timer

  let clockRef = useRef<any>();

  const startInterviewTimer = () => {
    const clock: any = document.getElementById("time");
    let time = -1;

    function incrementTime() {
      time++;
      clock.textContent =
        ("0" + Math.trunc(time / 3600)).slice(-2) +
        " : " +
        ("0" + Math.trunc(time / 60)).slice(-2) +
        " : " +
        ("0" + (time % 60)).slice(-2);
    }

    incrementTime();
    setTotalInterviewTime({
      ...totalInterviewTime,
      startTime: new Date(),
    });

    intervalId = setInterval(incrementTime, 1000);
  };

  const stopInterviewTimer = () => {
    const clock: any = document.getElementById("time");

    // clock.textContent = '00:00:00'
    setTotalInterviewTime({ ...totalInterviewTime, endTime: new Date() });

    clearInterval(intervalId);
    intervalId = null;
  };

  // Start recording function. Get the stream, pass it to the recorder (rtc). //
  // START RECORDING FUNCTION /////
  const startRecordingVideo = async () => {
    if (!!stream) {
      // get thumnbnail of video
      setTimeout(() => {
        getAvtarImageFromInterviewVideo();
      }, 10000);
    }

    setAudioPlay(questionsAudio[0]);
    setVideoArray(synthesiaVideoLinkArray[0]);
    setCurrentQuestion(questionsText[0]?.question_test);
    setCurrentQuestionIndex(0);
    if (typeof stream?.getTracks == "function") {
      stopMediaTracks(stream);
      setStream(null);
      // setIsLoading(true);
    }

    try {
      // turning on user camera and getting the stream //
      let constraints1: any = getAudioVideoConfigConstraints();

      // pass the stream to the recorder, using recordRTC //

      const streamL = await navigator.mediaDevices.getUserMedia(constraints1);
      // const stream2 = await navigator.mediaDevices.getUserMedia(constraints1);
      let audioTrack = streamL.getAudioTracks()[0];
      // finalStream?.addTrack(audioTrack);
      streamL?.addTrack(audioTrack);
      const canvasStream = canvasOutputRef.current?.captureStream();
      canvasStream?.addTrack(audioTrack);
      // pass the stream to the recorder, using recordRTC //
      const recorder = new RecordRTCPromisesHandler(
        // finalStream ||
        canvasStream,
        {
          type: "video",
          // mimeType: 'video/webm',
          frameRate: 30,
          disableLogs: true,
          mimeType: "video/webm;codecs=h264",
          videoBitsPerSecond: 1000000,
        }
      );
      // const recorder = new RecordRTCPromisesHandler(
      //   // finalStream ||
      //   streamL,
      //   {
      //     type: 'video',
      //     mimeType: 'video/webm;codecs=h264',
      //     videoBitsPerSecond: 1000000,
      //     // disableLogs: true,
      //   }
      // );

      const recorderForEachQuestionRecord = new RecordRTCPromisesHandler(
        // finalStream || stream ||
        streamL,
        {
          type: "video",
          mimeType: "video/mp4",
          // disableLogs: true,
        }
      );

      //start timer
      // setIsTimerOn(true);
      // setIsLoading(false);
      setIsRecordingStarted(true);

      // start the recording //
      await recorder?.startRecording();
      // await recorderForEachQuestionRecord.startRecording();

      setRecorder(recorder);
      SetRecorderForEachQuestionRecord(recorderForEachQuestionRecord);
      setStream(streamL);
      setActiveStreams((prev) => [...prev, streamL]);
      setFinalStream(finalStream);

      setVideoBlob(undefined);
      myVideo.current.srcObject = streamL;
      // finalVideo.current.srcObject = streamL;

      setIsInitState(false);
    } catch (err) {
      console.error(err);
    }
  };

  const saveSplitedVideo = async () => {
    if (isSplitVideo && questionsText?.length - 1 !== currentQuestionIndex) {
      await recorder?.stopRecording();
      let blob = await recorder?.getBlob();

      const tempBlob = [...blobInterval];
      tempBlob.push(blob);
      setBlobInterval(tempBlob);
      // // the blob is the final recording, to be downloaded or sent //
      await recorder?.startRecording();
      setRecorder(recorder);
      setStream(stream);
      if (stream) {
        setActiveStreams((prev) => [...prev, stream]);
      }

      // setVideoBlob(null);
      myVideo.current.srcObject = stream;
    }
  };

  function convertToWAV(audioBuffer: AudioBuffer) {
    const channelData = audioBuffer.getChannelData(0);
    const buffer = new ArrayBuffer(44 + channelData.length * 2);
    const view = new DataView(buffer);

    /* WAV header */
    writeString(view, 0, "RIFF");
    view.setUint32(4, 32 + channelData.length * 2, true);
    writeString(view, 8, "WAVE");
    writeString(view, 12, "fmt ");
    view.setUint32(16, 16, true);
    view.setUint16(20, 1, true);
    view.setUint16(22, 1, true);
    view.setUint32(24, audioBuffer.sampleRate, true);
    view.setUint32(28, audioBuffer.sampleRate * 2, true);
    view.setUint16(32, 2, true);
    view.setUint16(34, 16, true);
    writeString(view, 36, "data");
    view.setUint32(40, channelData.length * 2, true);

    /* Write PCM data */
    const floatTo16BitPCM = (
      output: DataView,
      offset: number,
      input: Float32Array
    ) => {
      for (let i = 0; i < input.length; i++, offset += 2) {
        const s = Math.max(-1, Math.min(1, input[i]));
        output.setInt16(offset, s < 0 ? s * 0x8000 : s * 0x7fff, true);
      }
    };
    floatTo16BitPCM(view, 44, channelData);

    return buffer;
  }

  function writeString(view: DataView, offset: number, string: string): void {
    for (let i = 0; i < string.length; i++) {
      view.setUint8(offset + i, string.charCodeAt(i));
    }
  }

  const stopRecording = async () => {
    // for last questions audio fetch blob and call api
    if (recorderForEachQuestionRecord) {
      // storeLastQuestionsBlob();
      storeEachQuestionsBlob(true);
    }

    if (recorder) {
      setTimeout(async () => {
        await recorder.stopRecording();
        recorderForEachQuestionRecord?.stopRecording();

        let blob: Blob = await recorder.getBlob();

        stream?.getTracks().forEach((track: any) => {
          track.stop();
          track.enabled = false;
        });
        finalStream?.getTracks().forEach((track: any) => {
          track.stop();
          track.enabled = false;
        });
        stopMediaVideoTracks();

        myVideo.current.srcObject = null;

        // finalVideo.current.srcObject = null;
        canvasOutputRef.current = null;
        // stopMediaTracks(stream);
        // stopMediaTracks(finalStream);

        // the blob is the final recording, to be downloaded or sent //
        setVideoBlob(blob);
        // setStream(null);
        // setInterviewStatus((prev: any) => ({
        //   ...prev,
        //   isInterviewFinished: true,
        // }));
        // // setFinalStream(null);
        // setRecorder(null);
        // SetRecorderForEachQuestionRecord(null);

        // // stop timer
        // setIsTimerOn(false);
        stopInterviewTimer();
        // setIsRecordingStarted(false);

        downloadVideo(blob);

        /////////////////////////
      }, 3000);
    }
  };

  const submitData = async () => {
    if (videoBlob) {
      const saveVideoFormData = new FormData();
      const saveAudioFormData = new FormData();

      saveVideoFormData.append("", videoBlob);

      let audioBlob: Blob | null = null;
      const audioCtx = new AudioContext();
      const fileReader: any = new FileReader();
      fileReader.readAsArrayBuffer(videoBlob);
      fileReader.onloadend = () => {
        const audioData = fileReader.result as ArrayBuffer;
        audioCtx.decodeAudioData(audioData, (audioBuffer) => {
          const wavData = convertToWAV(audioBuffer);
          audioBlob = new Blob([wavData], { type: "audio/wav" });
          saveAudioFormData.append("", audioBlob);
          // saveAs(audioBlob, `Audio-${Date.now()}.wav`);
        });
      };

      const [hours, minutes, seconds] = timeState.split(":").map(Number);
      const totalSeconds = hours * 3600 + minutes * 60 + seconds;

      await (async () => {
        const tempData = { ...interviewData.candidate };
        const candidate_link = sessionStorage.getItem("candidate_link") || "";

        const date = new Date(Date.now());
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        const formattedDate = `${day}/${month}/${year}`;
        const InterviewDataSpeech = {
          fk_Enterprise_ID: tempData.fk_Enterprise_ID,
          fk_EnterpriseCostCentre_ID: 5,
          fk_User_ID: tempData.fk_User_ID,
          fk_Campaign_ID: tempData.fk_Campaign_ID,
          fk_CampaignLine_ID: tempData.pk_CampaignLine_ID,
          CampaignLine_CandidateLink: candidate_link,
          InterviewDataSpeech_Score: "",
          InterviewDataSpeech_Optional_1: "",
          InterviewDataSpeech_Optional_2: "",
          InterviewDataSpeech_Optional_3: "",
          InterviewDataSpeech_Optional_4: "",
          InterviewDataSpeech_Optional_5: "",
          InterviewDataSpeech_DataString: "",
          InterviewDataSpeech_Deleted: 0,
          InterviewDataSpeech_Status: 1,
          InterviewDataSpeech_AccessLevel: 1,
          InterviewDataSpeech_AudioUrl: "",
          InterviewDataSpeech_TimeStamp: date.toUTCString(),
        };

        saveAudioFormData.append(
          "InterviewDataSpeech",
          JSON.stringify(InterviewDataSpeech)
        );

        const data = {
          fk_Enterprise_ID: tempData.fk_Enterprise_ID,
          fk_EnterpriseCostCentre_ID: 5,
          fk_User_ID: tempData.fk_User_ID,
          fk_Campaign_ID: tempData.fk_Campaign_ID,
          fk_CampaignLine_ID: tempData.pk_CampaignLine_ID,
          InterviewVideo_CandidateLink: "",
          InterviewVideo_TimeStamp: totalSeconds.toString(),
          InterviewVideo_DataString: "",
          InterviewVideo_Deleted: 0,
          InterviewVideo_Status: 0,
          InterviewVideo_AccessLevel: 1,
        };
        // ! must create interview video db entry
        const response: any = await createInterviewVideoBlob(data);

        // ! save
        if (thumbnailImg) {
          const saveThumbnail = new FormData();
          saveThumbnail.append("", thumbnailImg);

          await axios
            .post(
              `${BASE_URL}/InterviewVideo/SaveVideoThumbnail?pk_CampaignLine_ID=${interviewData?.candidate?.pk_CampaignLine_ID}`,
              saveThumbnail,
              {
                headers: {
                  "Content-Type": "application/octet-stream",
                  Authorization: `Bearer ${
                    localStorage.getItem("token") || ""
                  }`,
                },
              }
            )
            .then((response) => {})
            .catch((err) => {
              console.log(err);
            });
        }

        if (response.status === 201) {
          const accessToken = localStorage.getItem("token") || "";

          const headers = {
            "Content-Type": "application/octet-stream",
            Authorization: `Bearer ${accessToken}`,
          };

          const postResult = await axios.post(
            `${BASE_URL}/InterviewVideo/SaveVideo?pk_CampaignLine_ID=${tempData?.pk_CampaignLine_ID}`,
            saveVideoFormData,
            {
              headers: headers,
              onUploadProgress: (data: any) => {
                setUploadProgress(
                  Math.round(((100 * data.loaded) / data.total) * 30) / 100
                );
              },
            }
          );

          const postResultOfAudioUpload = await axios.post(
            `${BASE_URL}/InterviewDataSpeech/SaveAudio`,
            saveAudioFormData,
            {
              headers: headers,
              onUploadProgress: (data: any) => {
                setUploadProgress((prev) => {
                  let uploadProgressNumber =
                    Math.round(((100 * data.loaded) / data.total) * 90) / 100;

                  return uploadProgressNumber > prev
                    ? uploadProgressNumber
                    : prev;
                });
              },
            }
          );

          if (
            postResult.status === 200 &&
            (postResultOfAudioUpload.status === 200 ||
              postResultOfAudioUpload.status === 201)
          ) {
            setInterviewAudioResponse(true);
            // sendNotification({ msg: 'Interview Uploaded Successfully' });
          } else {
            setUploadProgress(0);
            // sendNotification({
            //   msg: 'Interview Uploading Failed!',
            //   variant: 'error',
            // });
          }
        }
      })();
      localStorage.removeItem("step_active");
      localStorage.removeItem("Video_Audio");
    }
  };

  useEffect(() => {
    // if (interviewStatus.isInterviewFinished && isSynthesiaClosingPlaying) {
    if (interviewStatus.isInterviewFinished) {
      submitData();
    }
  }, [interviewStatus.isInterviewFinished, isSynthesiaClosingPlaying]);

  const downloadVideo = (blob: any) => {
    const mp4File = new File([blob], "demo.mp4", { type: "video/mp4" });
    // saveAs(mp4File, `Video-finalVideo.mp4`);
  };

  const onAudioDataHandler = (data: { size: number }) => {
    if (data.size > 1100) {
      setNoiseArray((prevState: any[]) => [...prevState, data.size]);
    }
  };

  const onToggleScreen = () => {
    setShowSelfScreen((prevState: any) => !prevState);
  };

  const [totalTimeOfInterview, setTotalTimeOfInterview] =
    useState("00 : 00 : 00");

  return (
    <MainContext.Provider
      value={{
        myVideo,
        finalVideo,
        stream,
        showSelfScreen,
        isRecordingStarted,
        setIsRecordingStarted,
        speaking,
        recorder,
        setRecorder,
        interviewStatus,
        setInterviewStatus,
        uploadProgress,
        isLoading,
        setIsLoading,
        isCameraAndMicSettingOpen,
        setIsCameraAndMicSettingOpen,
        canvasOutputRef,
        startRecordingVideo,
        stopRecording,
        changeQuestion,
        videoBlob,
        currentQuestionIndex,
        questionsText,
        startInterviewTimer,
        currentQuestion,
        onToggleScreen,
        extendedSilence,
        audioPlay,
        isSplitVideo,
        saveSplitedVideo,
        setQuestionsText,
        setQuestionsAudio,
        interviewData,
        setInterviewData,
        isTimerOn,
        setIsTimerOn,
        audioDevices,
        currentAudio,
        currentVideo,
        setCurrentAudio,
        setCurrentVideo,
        setExtendedSilence,
        onCameraAndAudioSettingSave,
        noiseArray,
        isInitState,
        setStream,
        setVideoBlob,
        setOpenPermissionDialog,
        setAudioDevices,
        videoDevices,
        setVideoDevices,
        canvasCtx,
        videoBackgroundConfig,
        setFinalStream,
        setExtendedSilenceArray,
        setVideoBackgroundConfig,
        openPermissionDialog,
        convertToWAV,
        isVideoBackgroundEnabled,
        setIsVideoBackgroundEnabled,
        setTotalInterviewTime,
        clockRef,
        isQuestionSpeaking,
        setIsQuestionSpeaking,
        totalTimeOfInterview,
        setTotalTimeOfInterview,
        onAudioDataHandler,
        canvasRef,
        jobData,
        setJobData,
        recruiterName,
        setRecruiterName,
        interviewUploadSuccessfull,
        setInterviewUploadSuccessfull,
        tempCurrentVideo,
        setTempCurrentVideo,
        tempCurrentAudio,
        setTempCurrentAudio,
        stopMediaTracks,
        getAudioVideoConfigConstraints,
        storeTimeStamp,
        setStoreTimeStamp,
        recorderForEachQuestionRecord,
        SetRecorderForEachQuestionRecord,
        logoutState,
        setNoiseArray,
        timerOff,
        setTimerOff,
        speak,
        speakingScreen,
        setSpeakingScreen,
        setIsInitState,
        timer,
        setTimer,
        setCurrentQuestionIndex,
        setOnboardingCandidateData,
        // setOnboardingCampaignData,
        onboardingCandidateData,
        setCandidateLinkId,
        candidateLinkId,
        candidateData,
        setCandidateData,
        onCameraAndAudioSettingSaveChange,
        timeState,
        setTimeState,
        setEnterpriseData,
        enterpriseData,
        questionVideoRef,
        videoArray,
        isQuestionVideoPlay,
        isLoadVideo,
        setIsLoadVideo,
        synthesiaVideoLinkArray,
        setSynthesiaVideoLinkArray,
        synthesiaVideos,
        setSynthesiaVideos,
        closingVideoPlay,
        initialVideoPlay,
        setClosingVideoPlay,
        setInitialVideoPlay,
        openPopup,
        setOpenPopup,
        isSynthesiaClosingPlaying,
        setIsSynthesiaClosingPlaying,
      }}
    >
      {children}
    </MainContext.Provider>
  );
};

const useInterviewContext = () => {
  return useContext(MainContext);
};

export default useInterviewContext;
