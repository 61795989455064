import React, { useEffect, useState } from "react";

// theme colors
import { Colors } from "../../theme/colors";

// image
import AuthBackgroundImage from "../../assets/BackGroundImg.png";
import TIRONEM_LOGO from "../../assets/TIRONEM_LOGO.png";
import {
  Typography,
  TextField,
  Button,
  Stack,
  CircularProgress,
  Box,
  useMediaQuery,
} from "@mui/material";
import CssTextField from "../../utils/CssTextField";
import { ReactComponent as GoogleIcon } from "../../assets/svg/google.svg";
import { ReactComponent as FacebookIcon } from "../../assets/svg/facebook.svg";

// Router
import { useNavigate, Link, useParams, useLocation } from "react-router-dom";
import GoogleLogin from "react-google-login";
import { BASE_URL, FACEBOOK_APP_ID, GOOGLE_APP_ID } from "../../utils/config";
import useToaster from "../../utils/Toaster";
import { loginUser, registerUser } from "../../api/auth";
import { useLogin } from "react-facebook";
import LoadingAnimation from "../../components/LoadingAnimation";
import useInterviewContext from "../../Context/mainContext";
import axios from "axios";
import bg_top from "../../assets/bg_top.png";
import bg_bottom from "../../assets/bg_bottom.png";
import { getCampaignInterviewData } from "../../api/onboarding";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";

const Index = () => {
  let interviewState: any = useInterviewContext();
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [passwordConfirm, setPasswordConfirm] = useState<string>("");
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [msg, sendNotification] = useToaster();
  const [isLoading1, setIsLoading1] = useState<boolean>(false);
  const [campaignData, setCampaignData] = useState<any>();
  const [candidateLink, setCandidateLink] = useState<any>();
  const navigate = useNavigate();
  const maxWidth1536 = useMediaQuery("(max-width: 1536px)");

  const location = useLocation();

  const { login, status, isLoading, error } = useLogin();

  const handleKeypress = (e: any) => {
    //it triggers by pressing the enter key
    var code = e.keyCode || e.which;
    if (code === 13) {
      // onSubmit();
    }
  };

  useEffect(() => {
    if (interviewState.onboardingCandidateData) {
      setEmail(
        interviewState.onboardingCandidateData.CampaignLine_CandidateEmail
      );
    }
  }, [interviewState]);
  useEffect(() => {
    const getCandidateLink: any = sessionStorage.getItem("candidate_link");
    setCandidateLink(getCandidateLink);
  }, []);

  useEffect(() => {
    const tokenId = localStorage.getItem("token");
    if (tokenId) {
      localStorage.clear();
    }
  }, []);
  useEffect(() => {
    const candidate_link: any = sessionStorage.getItem("candidate_link");
    if (
      Object.keys(interviewState.onboardingCandidateData).length === 0 &&
      candidate_link?.includes("/campaign/")
    ) {
      getCampaignInterviewData(candidate_link)
        .then((response: any) => {
          setCampaignData(response?.data);
        })
        .catch((error) => {});
    }
  }, []);

  const onSubmit = async () => {
    setIsLoading1(true);
    let message: string;
    if (firstName.trim() === "") {
      message = "Please enter a firstname";
      sendNotification({ msg: message, variant: "error" });
      setIsLoading1(false);
      return;
    }
    if (lastName.trim() === "") {
      message = "Please enter a lastname";
      sendNotification({ msg: message, variant: "error" });
      setIsLoading1(false);
      return;
    }
    if (phoneNumber.trim() === "") {
      message = "Please enter a phone number";
      setIsLoading1(false);
      sendNotification({ msg: message, variant: "error" });
      return;
    }
    if (email.trim() === "") {
      message = "Please enter a email";
      setIsLoading1(false);
      sendNotification({ msg: message, variant: "error" });
      return;
    }
    if (password.trim() === "") {
      message = "Please enter a password";
      setIsLoading1(false);
      sendNotification({ msg: message, variant: "error" });
      return;
    }
    if (passwordConfirm.trim() === "") {
      message = "Please enter a confirm password";
      setIsLoading1(false);
      sendNotification({ msg: message, variant: "error" });
      return;
    }
    if (/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email) === true) {
      if (password === passwordConfirm) {
        if (
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/.test(
            password
          ) === true
        ) {
          {
            const signUpData = {
              FirstName: firstName,
              LastName: lastName,
              Email: email,
              Password: password,
              ConfirmPassword: passwordConfirm,
              User_PhoneNumber: phoneNumber,
            };

            // let response: any;

            registerUser(signUpData)
              .then((response) => {
                if (response?.status === 200) {
                  if (
                    interviewState.onboardingCandidateData &&
                    interviewState.onboardingCandidateData
                      .CampaignLine_CandidateEmail
                  ) {
                    const loginData = {
                      email: signUpData.Email,
                      password: signUpData.Password,
                    };
                    localStorage.clear();

                    loginUser(loginData).then((responseLogin: any) => {
                      const storeToken = responseLogin?.data?.access_token;
                      const headers = {
                        "Access-Control-Allow-Origin": "*",
                        Authorization: `Bearer ${storeToken}`,
                      };
                      localStorage.setItem("step_active", "0");
                      let candidate_link =
                        // localStorage.getItem('candidate_link') || '';
                        sessionStorage.getItem("candidate_link") || "";
                      axios
                        .get(
                          `${BASE_URL}/CampaignLine/GetByCandidateLink?CampaignLine_CandidateLink=${candidate_link}`,
                          { headers: headers }
                        )
                        .then((responseCandidateData: any) => {
                          if (
                            responseCandidateData?.status === 200 ||
                            responseCandidateData?.successful
                          ) {
                            interviewState.setCandidateData(
                              responseCandidateData.data
                            );

                            let onBoardingData = {
                              ...responseCandidateData.data,
                              CampaignLine_CandidateFirstName:
                                signUpData.FirstName,
                              CampaignLine_CandidateLastName:
                                signUpData.LastName,
                              CampaignLine_CandidateEmail: signUpData.Email,
                            };

                            axios
                              .put(`${BASE_URL}/CampaignLine`, onBoardingData, {
                                headers: headers,
                              })
                              .then((responseOnBoarding) => {
                                if (
                                  responseOnBoarding?.status === 200 ||
                                  responseOnBoarding?.status === 201
                                ) {
                                  axios
                                    .get(
                                      `${BASE_URL}/User/${responseCandidateData.data.fk_User_ID}`,
                                      { headers: headers }
                                    )
                                    .then((userResponse: any) => {
                                      if (
                                        userResponse?.status === 200 ||
                                        userResponse?.successful
                                      ) {
                                        const userDataobj = {
                                          ...userResponse.data,
                                          User_FirstName: signUpData.FirstName,
                                          User_SecondName: signUpData.LastName,
                                        };
                                        axios
                                          .put(
                                            `${BASE_URL}/User`,
                                            userDataobj,
                                            {
                                              headers: headers,
                                            }
                                          )
                                          .then((userResponse) => {})
                                          .catch((error) => console.log(error));
                                      }
                                    })
                                    .catch((error) => {
                                      console.log(error);
                                    });

                                  let message = "Registration successful.";
                                  sendNotification({
                                    msg: message,
                                    variant: "success",
                                  });
                                  setIsLoading1(false);
                                  navigate("/login");
                                } else {
                                  let message = "Registration failed.";
                                  sendNotification({
                                    msg: message,
                                    variant: "error",
                                  });
                                  setIsLoading1(false);
                                }
                              })
                              .catch((err) => {
                                console.log(err, "error");
                                setIsLoading1(false);
                              });
                          }
                        })
                        .catch((error) => {
                          console.log(error);
                          setIsLoading1(false);
                        });
                    });
                  } else {
                    let message = "Registration successful.";
                    sendNotification({ msg: message, variant: "success" });
                    setIsLoading1(false);
                    navigate("/login");
                  }
                } else {
                  let message = "Registration failed.";
                  sendNotification({ msg: message, variant: "error" });
                  setIsLoading1(false);
                }
              })
              .catch((err: any) => {
                const a: any = err.response.data.ModelState;

                const b: any = Object.values(a);

                sendNotification({
                  msg: b[0][b[0].length - 1] || "Registration failed.",
                  variant: "error",
                });
                setIsLoading1(false);
              });
          }
        } else {
          message =
            "Password must contain at least one small and capital letter , special characters  and numbers.";
          sendNotification({ msg: message, variant: "error" });
          setIsLoading1(false);
        }
      } else {
        message = "password not matched";
        sendNotification({ msg: message, variant: "error" });
        setIsLoading1(false);
      }
    } else {
      message = "email format is not valid";
      sendNotification({ msg: message, variant: "error" });
      setIsLoading1(false);
    }
  };

  // const onSubmitNew = async () => {
  //   setIsLoading1(true);
  //   let message: string;

  //   if (/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email) === true) {
  //     if (password === passwordConfirm) {
  //       if (
  //         /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/.test(
  //           password
  //         ) === true
  //       ) {
  //         {
  //           const signUpData = {
  //             FirstName: firstName,
  //             LastName: lastName,
  //             Email: email,
  //             Password: password,
  //             ConfirmPassword: passwordConfirm,
  //           };

  //           // let response: any;

  //           checkInterviewIsGiven(email, campaignData?.pk_Campaign_ID)
  //             .then((checkCampaign: any) => {
  //               if (checkCampaign?.data == false) {
  //                 registerUser(signUpData).then((response:any) => {
  //                   if (response?.status === 200) {
  //                     {
  //                       const loginData = {
  //                         email: signUpData.Email,
  //                         password: signUpData.Password,
  //                       };
  //                       localStorage.clear();

  //                       loginUser(loginData).then((responseLogin: any) => {
  //                         const storeToken = responseLogin?.data?.access_token;
  //                         const headers = {
  //                           'Access-Control-Allow-Origin': '*',
  //                           Authorization: `Bearer ${storeToken}`,
  //                         }
  //                         const body = {
  //                           pk_CampaignLine_ID: 0,
  //                           CampaignLine_Record_GUID:
  //                             '831479ae-4732-4ffa-a312-4aaab4519de0',
  //                           fk_Enterprise_ID: campaignData.fk_Enterprise_ID,
  //                           sSession: 'sample string 4',
  //                           fk_User_ID: response?.data?.pk_User_ID,
  //                           fk_Job_ID: campaignData.fk_Job_ID,
  //                           fk_Campaign_ID: campaignData.pk_Campaign_ID,
  //                           CampaignLine_CandidateFirstName: firstName,
  //                           CampaignLine_CandidateLastName: lastName,
  //                           CampaignLine_CandidateEmail: email,
  //                           CampaignLine_CandidatePhoneNumber: '',
  //                           CampaignLine_CandidateLink: candidateLink,
  //                           CampaignLine_CandidateLinkExpiry:
  //                             '2023-07-06T10:22:06.0893562+00:00',
  //                           CampaignLine_CandidateLinkSent:
  //                             '2023-07-06T10:22:06.0893562+00:00',
  //                           CampaignLine_EngagmentStatus: 15,
  //                           CampaignLine_ShortListStatus: 16,
  //                           CampaignLine_AlgorithimRating: 17,
  //                           CampaignLine_FaceRating: 18,
  //                           CampaignLine_VoiceRating: 19,
  //                           CampaignLine_VideoRating: 20,
  //                           CampaignLine_RecruiterRating: 21,
  //                           CampaignLine_DateOfCreation:
  //                             '2023-07-06T10:22:06.0893562+00:00',
  //                           CampaignLine_DateOfLastUpdate:
  //                             '2023-07-06T10:22:06.0893562+00:00',
  //                           CampaignLine_Deleted: 24,
  //                           CampaignLine_Status: 25,
  //                           CampaignLine_AccessLevel: 26,
  //                           CampaignLine_Initials: '',
  //                           CampaignLine_Colour: '',
  //                           CampaignLine_BackgroundColour: '',
  //                           CampaignLine_ImageLink: '',
  //                           Campaign_JobTitle: '',
  //                         };
  //                         axios
  //                           .post(
  //                             `${BASE_URL}/CampaignLine`,body,
  //                             { headers: headers }
  //                           )
  //                           .then((responseCampaignLine: any) => {
  //                             let message = responseCampaignLine.data;
  //                             sendNotification({
  //                               msg: message,
  //                               variant: 'success',
  //                             });
  //                             navigate('/login')
  //                             setIsLoading1(false);
  //                           })
  //                           .catch((CampaignLineError) => {
  //                             setIsLoading1(false);
  //                             let message = 'Registration failed.';
  //                             sendNotification({
  //                               msg: message,
  //                               variant: 'error',
  //                             });
  //                           });
  //                       })
  //                     }
  //                   } else {
  //                     let message = 'Registration failed.';
  //                     sendNotification({ msg: message, variant: 'error' });
  //                     setIsLoading1(false);
  //                   }
  //                 });
  //               } else {
  //                 sendNotification({
  //                   msg: 'You have already given interview to this campaign',
  //                   variant: 'error',
  //                 });
  //                 setIsLoading1(false);
  //               }
  //             })
  //             .catch((error) => {
  //               console.log('error', error);
  //             });
  //         }
  //       } else {
  //         message =
  //           'Password must contain at least one small and capital letter , special characters  and numbers.';
  //         sendNotification({ msg: message, variant: 'error' });
  //         setIsLoading1(false);
  //       }
  //     } else {
  //       message = 'password not matched';
  //       sendNotification({ msg: message, variant: 'error' });
  //       setIsLoading1(false);
  //     }
  //   } else {
  //     message = 'email format is not valid';
  //     sendNotification({ msg: message, variant: 'error' });
  //     setIsLoading1(false);
  //   }
  // };

  const responseFacebook = (response: any) => {
    console.log(response);
  };

  const responseGoogle = (response: any) => {
    console.log(response);
  };

  const handleLoginfacebook = async () => {
    try {
      const response = await login({
        scope: "email",
      });

      console.log(response.status);
    } catch (error: any) {
      console.log(error.message);
    }
  };

  return (
    <>
      <div
        style={{
          position: "relative",
          background: "white",
          height: "100vh",
          width: "100%",
          // minHeight: '750px',
          overflow: "hidden",
        }}
      >
        <img
          src={AuthBackgroundImage}
          alt="AuthBackground_Image"
          style={{
            position: "absolute",
            height: "100%",
            width: "100%",
          }}
        />
        <div
          style={{
            position: "relative",
            zIndex: 10,
          }}
        >
          {/* header */}
          <div
            style={{
              // marginTop: '65px',
              marginTop: maxWidth1536 ? "34px" : "65px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            {/* <img
              src={TIRONEM_LOGO}
              alt="TIRONEM_logo"
              style={{
                width: "216px",
                height: "49px",
              }}
            /> */}
          </div>

          {/* body content */}
          <div
            style={{
              // marginTop: '78px',
              marginTop: maxWidth1536 ? "30px" : "78px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography
              color={Colors.black}
              sx={{ fontSize: "26px", fontWeight: "600" }}
            >
              Create an account
            </Typography>
            <Typography
              variant={"h5"}
              color={Colors.Phlox}
              sx={{
                marginTop: maxWidth1536 ? "6px" : "10px",
                fontWeight: 500,
                fontSize: "20px",
              }}
            >
              Let’s get started with your hiring process
            </Typography>
            <div
              style={{
                marginTop: maxWidth1536 ? "22px" : "40px",
                width: "100%",
                maxWidth: "414px",
              }}
            >
              <CssTextField
                id="outlined-basic"
                variant="outlined"
                value={firstName}
                placeholder="Enter first name"
                onChange={(e: any) => {
                  setFirstName(e.target.value);
                }}
              />
            </div>
            <div
              style={{
                marginTop: "10px",
                width: "100%",
                maxWidth: "414px",
              }}
            >
              <CssTextField
                id="outlined-basic"
                variant="outlined"
                value={lastName}
                placeholder="Enter last name"
                onChange={(e: any) => setLastName(e.target.value)}
              />
            </div>
            <div
              style={{
                marginTop: "10px",
                width: "100%",
                maxWidth: "414px",
              }}
            >
              {/* <CssTextField
                id="outlined-basic"
                variant="outlined"
                value={lastName}
                placeholder="Enter last name"
                onChange={(e: any) => setLastName(e.target.value)}
              /> */}
              <PhoneInput
                international
                limitMaxLength
                style={{
                  height: "50px",
                  padding: "0px 10px",
                  color: Colors.Maximum_blue_purple,
                  border: `1px solid ${Colors.lavender_blue}`,
                  borderRadius: "10px",
                  input: {
                    color: "red",
                  },
                  "&:focusWithin": {
                    borderColor: Colors.slate_blue,
                    color: Colors.slate_blue,
                  },
                }}
                defaultCountry={"AU"}
                countryCallingCodeEditable={false}
                value={phoneNumber}
                onChange={(e: any) => setPhoneNumber(e)}
              />
            </div>
            <div
              style={{
                marginTop: "10px",
                width: "100%",
                maxWidth: "414px",
              }}
            >
              <CssTextField
                id="outlined-basic"
                variant="outlined"
                disabled={
                  interviewState.onboardingCandidateData &&
                  interviewState.onboardingCandidateData
                    .CampaignLine_CandidateEmail
                    ? true
                    : false
                }
                value={email}
                placeholder="Enter your email"
                onChange={(e: any) => setEmail(e.target.value)}
              />
            </div>
            <div
              style={{
                marginTop: "10px",
                width: "100%",
                maxWidth: "414px",
              }}
            >
              <CssTextField
                id="outlined-basic"
                type="password"
                value={password}
                variant="outlined"
                placeholder="Enter password"
                onChange={(e: any) => setPassword(e.target.value)}
              />
            </div>
            <div
              style={{
                marginTop: "10px",
                width: "100%",
                maxWidth: "414px",
              }}
            >
              <CssTextField
                id="outlined-basic"
                type="password"
                value={passwordConfirm}
                variant="outlined"
                placeholder="Confirm password"
                onKeyPress={(e: any) => handleKeypress(e)}
                disabled={isLoading1}
                onChange={(e: any) => setPasswordConfirm(e.target.value)}
              />
            </div>
            <div
              style={{
                marginTop: "10px",
                width: "100%",
                maxWidth: "414px",
              }}
            >
              <Button
                variant={"contained"}
                color={"primary"}
                sx={{
                  width: "100%",
                  fontSize: 20,
                  height: 50,
                  background:
                    "linear-gradient(269.39deg, #6559D7 -3.77%, #AA19D5 78.96%)",
                }}
                onClick={() => onSubmit()}
              >
                {isLoading1 ? (
                  <>
                    <Box
                      sx={{
                        position: "absolute",
                        cursor: "pointer",
                      }}
                      // aria-controls={isOpenedJobType ? 'account-menu' : undefined}
                      aria-haspopup="true"
                      // aria-expanded={isOpenedJobType ? 'true' : undefined}
                    >
                      <CircularProgress
                        style={{
                          width: "30px",
                          height: "30px",
                          color: "white",
                        }}
                      />
                    </Box>
                  </>
                ) : (
                  "Sign Up"
                )}
              </Button>
            </div>
            <div
              style={{
                marginTop: maxWidth1536 ? "14px" : "20px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography
                fontSize="12px"
                color={Colors.spanish_gray}
                sx={{
                  fontWeight: 500,
                  marginRight: "3px",
                  fontSize: "16px",
                }}
              >
                Already have an account?
              </Typography>
              <Typography
                fontSize="12px"
                component={Link}
                to="/login"
                color={Colors.vivid_mulberry}
                sx={{
                  fontWeight: 600,
                  fontSize: "16px",
                  textDecoration: "none",
                  cursor: "pointer",

                  "&:hover": {
                    textDecoration: "underline",
                  },
                }}
              >
                Log in
              </Typography>
            </div>
            {/* <div
              style={{
                padding: maxWidth1536 ? '10px 0' : '24px 0',
                textAlign: 'center',
              }}
            >
              <Typography
                fontSize="16px"
                variant="h6"
                color={Colors.spanish_gray}
              >
                Or
              </Typography>
            </div>
            <Stack
              spacing={2}
              direction="row"
              sx={{ width: '100%', maxWidth: '414px' }}
            >
              <GoogleLogin
                clientId={GOOGLE_APP_ID} //CLIENTID NOT CREATED YET
                render={(renderProps) => (
                  <Button
                    size="large"
                    variant={'contained'}
                    color={'primary'}
                    sx={{
                      width: '100%',
                      height: '5%',
                      padding: '10px 0',
                      borderRadius: '10px',
                      background: '#ffffff',
                      color: '#464646',
                      border: '1px solid #E5E5E5',
                      boxShadow: '2px 2px 10px rgba(0, 0, 0, 0.1)',
                      fontSize: '16px',
                      ':hover, :focus, :active': {
                        background: `#ffffff`,
                        boxShadow: '2px 2px 10px rgba(0, 0, 0, 0.1)',
                      },
                    }}
                    startIcon={<GoogleIcon />}
                    onClick={renderProps.onClick}
                    disabled={renderProps.disabled}
                  >
                    Google
                  </Button>
                )}
                buttonText="Login"
                onSuccess={responseGoogle}
                onFailure={responseGoogle}
                cookiePolicy={'single_host_origin'}
              />
              <Button
                size="large"
                variant={'contained'}
                color={'primary'}
                sx={{
                  width: '100%',
                  height: '5%',
                  padding: '10px 0',
                  borderRadius: '10px',
                  background: '#ffffff',
                  color: '#464646',
                  // border: '1px solid #E5E5E5',
                  fontSize: '16px',
                  boxShadow: '2px 2px 10px rgba(0, 0, 0, 0.1)',
                  ':hover, :focus, :active': {
                    background: `#ffffff`,
                    boxShadow: '2px 2px 10px rgba(0, 0, 0, 0.1)',
                  },
                }}
                startIcon={<FacebookIcon />}
                onClick={handleLoginfacebook}
                disabled={isLoading}
              >
                Facebook
              </Button>
            </Stack>
            <Stack
              spacing={2}
              direction="row"
              sx={{
                width: '100%',
                maxWidth: '414px',
                color: '#989797',
                fontSize: '16px',
                marginTop: maxWidth1536 ? '10px' : '30px',
                fontWeight: '400',
              }}
            >
              <Typography sx={{ width: '100%' }}>Privacy Policy</Typography>
              <Typography sx={{ width: '100%', textAlign: 'end' }}>
                Terms of services
              </Typography>
            </Stack> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Index;
