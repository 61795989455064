import generalMiddleware, {
  axiosInstanceWithBase,
} from "./middleware/middleware";
import { Response } from "./middleware/format-response";
import { RegisterUser, LoginUser, LoginResponse } from "./modals/modals";
import axios from "axios";

import { BASE_URL } from "../utils/config";

export const registerUser = (data: RegisterUser): Promise<Response<string>> =>
  axios.request({
    url: `${BASE_URL}/Account/Register`,
    method: "POST",
    data: data,
    headers: {
      "Content-Type": "application/json",
    },
  });

export const registerNewUser = (
  data: RegisterUser
): Promise<Response<string>> =>
  axios.request({
    url: `${BASE_URL}/user`,
    method: "POST",
    data: data,
    headers: {
      "Content-Type": "application/json",
    },
  });

export const loginUser = (data: LoginUser): Promise<Response<any>> => {
  const { email, password } = data;
  const requestData = `username=${email}&password=${password}&grant_type=password`;

  // return axios.post(`${BASE_URL_PREFIX}/token`, requestData, {
  //   headers: {
  //     // 'Content-Type': 'text/plain',
  //     'Access-Control-Allow-Origin': '*',
  //     'Content-Type': 'text/plain',
  //   },
  // });

  return axiosInstanceWithBase.request({
    url: "/token",
    method: "POST",
    data: requestData,
    headers: {
      "Content-Type": "text/plain",
    },
  });
};

export const googleLogin = (): Promise<Response<string>> =>
  generalMiddleware.request({
    url: "/Account/ExternalLogin?provider=Google&response_type=token&client_id=self&redirect_uri=http%3A%2F%2Flocalhost%3A63412%2F&state=bbkxvlFj0yfhKjb5K86mtV_7AYCDoBt4jzczF1dQ6Q41",
    method: "POST",
  });

export const facebookLogin = (): Promise<Response<string>> =>
  generalMiddleware.request({
    url: "/Account/ExternalLogin?provider=Facebook&response_type=token&client_id=self&redirect_uri=http%3A%2F%2Flocalhost%3A63412%2F&state=bbkxvlFj0yfhKjb5K86mtV_7AYCDoBt4jzczF1dQ6Q41",
    method: "POST",
  });
