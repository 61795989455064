import React from "react";
import { TextField, Input } from "@mui/material";
import { Colors } from "../theme/colors";

type Props = {};

const CssTextField = (props: any) => {
  return (
    <div>
      <Input
        {...props}
        inputProps={{
          style: {
            padding: 5,
          },
        }}
        sx={{
          color: Colors.Maximum_blue_purple,
          border: `1px solid ${Colors.lavender_blue}`,
          borderRadius: '10px',
          '&:focus-within': {
            borderColor: Colors.slate_blue,
            color: Colors.slate_blue,

            // background: Colors.bg_white,
            // backdropFilter: "blur(40px)",
            // color: Colors.tx_color,
            // height: "43px",
            // // border: `1px solid ${Colors.lavender_blue}`,
            // border: `2px solid ${Colors.white}`,
            // "&:focus-within": {
            //   border: `1px solid ${Colors.tx_color}`,
            //   color: Colors.tx_color,
          },
        }}
      />
    </div>
  );
};

export default CssTextField;
