import * as React from 'react';
import ReactDOM from 'react-dom';
import './index.css';

import CandidateApp from './CandidateApp';
import { InterviewProvider } from './Context/mainContext';

ReactDOM.render(
  <div>
    <InterviewProvider>
      <CandidateApp />
    </InterviewProvider>
  </div>,
  document.querySelector('#root')
);

// useContext to link state, particularly the auth state
// Cool graphics from modern ui react
// Landing page/login

// A react app needs routes, global state and to communicate with apis such as databases
