import React from "react";
import {
  BrowserRouter,
  Route,
  Routes,
  Link,
  NavLink,
  Navigate,
  useNavigate,
} from "react-router-dom";
import { mainTheme } from "./theme/Theme";
import ProtectedRoutes from "./utils/ProtectedRoutes";
import Login from "./screens/Login";
import Registration from "./screens/Registration";
// import CandidateInterviewPage from './screens/candidate-portal/CandidateInterviewPage';
import { createTheme, ThemeProvider } from "@mui/material/styles";
import NotFound from "./screens/candidate-portal/NotFound";
import ModalWindow from "./screens/candidate-portal/ModalWindow";
import { SnackbarProvider } from "notistack";
import CandidateInterview from "./screens/candidate-portal";
import useInterviewContext, { InterviewProvider } from "./Context/mainContext";
import { FacebookProvider } from "react-facebook";
import { FACEBOOK_APP_ID } from "./utils/config";
import InterviewLinkPage from "./screens/Registration/InterviewLinkPage";
import NotScheduled from "./screens/candidate-portal/NotScheduled";
import Image from "./assets/bg.png";
import DropOutScreen from "./screens/DropOutScreen/DropOutScreen";
import CampaignInterviewLinkPage from "./screens/Registration/CampaignInterviewLinkPage";
import { getOnboardingCandidateDetails } from "./api/onboarding";
import InviteLinkPage from "./screens/Registration/InviteLinkPage";

const CandidateApp = () => {
  const interviewState = useInterviewContext();
  const base_url = window.location.origin;

  React.useEffect(() => {
    const getCandidateLink: any = sessionStorage.getItem("candidate_link");

    if (getCandidateLink) {
      const getCompaignLineId = getCandidateLink.split("/");

      if (
        getCompaignLineId.length > 0 &&
        getCompaignLineId[getCompaignLineId.length - 2] == "interview"
      ) {
        interviewState.setCandidateLinkId(
          getCompaignLineId[getCompaignLineId.length - 1]
        );
        // let candidate_link = `https://thankful-pond-0c6b13600.2.azurestaticapps.net/interview/${
        //   getCompaignLineId[getCompaignLineId.length - 1]
        // }`;

        // let candidate_link = `https://interview.tironem.ai/interview/${
        //   getCompaignLineId[getCompaignLineId.length - 1]
        // }`;

        let candidate_link = `${base_url}/interview/${
          getCompaignLineId[getCompaignLineId.length - 1]
        }`;

        getOnboardingCandidateDetails(candidate_link).then((response: any) => {
          if (response?.status === 200 || response?.successful) {
            interviewState.setOnboardingCandidateData(response.data);
          }
        });
      }
    }
  }, []);
  return (
    <BrowserRouter>
      <FacebookProvider appId={FACEBOOK_APP_ID}>
        <SnackbarProvider maxSnack={3}>
          <ThemeProvider theme={mainTheme}>
            <div
              style={
                {
                  // backgroundImage: `url(${Image})`,
                  // backgroundRepeat: "no-repeat",
                  // backgroundSize: "cover",
                  // backdropFilter: "blur(250px)"
                }
              }
            >
              <Routes>
                <Route path="/" element={<Navigate to={"/login"} />} />
                <Route path="/login" element={<Login />} />

                <Route path={"/signup"} element={<Registration />} />
                <Route
                  path={"/interview/:id"}
                  element={<InterviewLinkPage />}
                />
                <Route path={"/invite/:id/:eId"} element={<InviteLinkPage />} />
                <Route
                  path={"/interview/campaign/:id"}
                  element={<CampaignInterviewLinkPage />}
                />
                <Route path={"/notstarted"} element={<NotScheduled />} />
                <Route path={"*"} element={<NotFound />} />
                {/* Protected Routes */}

                <Route
                  path="/candidate"
                  element={
                    <ProtectedRoutes>
                      <CandidateInterview />
                    </ProtectedRoutes>
                  }
                />
                {/* <Route
                    path="/pre-interview"
                    element={
                      <ProtectedRoutes>
                        <DropOutScreen />
                      </ProtectedRoutes>
                    }
                  /> */}
                <Route
                  path={"/modal"}
                  element={
                    <ProtectedRoutes>
                      <ModalWindow />
                    </ProtectedRoutes>
                  }
                />
              </Routes>
            </div>
          </ThemeProvider>
        </SnackbarProvider>
      </FacebookProvider>
    </BrowserRouter>
  );
};

export default CandidateApp;
