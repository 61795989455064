import React, { useState, useEffect } from 'react';

export const useAudio = (url: any) => {
  const [audio] = useState<HTMLAudioElement>(new Audio(url));
  const [playing, setPlaying] = useState<boolean>(false);

  const toggleAudio: (type: boolean) => void = (type: boolean) =>
    setPlaying(type);

  const setUrl = async (url: string) => {
    audio.pause();
    audio.src = url;
    audio.currentTime = 0;
    await audio.play();
    setPlaying(true);
  };

  useEffect(() => {
    (async () => {
      !playing && audio.pause();
    })();
  }, [playing]);
  useEffect(() => {
    (() => {
      audio.src = url;
      audio.currentTime = 0;
      playing && audio.pause();
    })();
  }, []);

  useEffect(() => {
    audio.addEventListener('ended', () => setPlaying(false));
    return () => {
      audio.removeEventListener('ended', () => setPlaying(false));
    };
  }, []);

  const finalValue: [boolean, (url: string) => void] = [playing, setUrl];

  return finalValue;
};
